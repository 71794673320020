import { DashboardFilterRequestParams } from "../../features/dashboard/helpers/DashboardHelpers";
import axiosInstance from "../axiosInstanceConfig";

const getExportCaptureDataByDate = async (
  startDate: string,
  endDate: string,
  userTimezone: string,
  teamID: string,
  dashboardFilters: DashboardFilterRequestParams | null
) => {
  const params = new URLSearchParams();
  params.append("fromDate", startDate);
  params.append("toDate", endDate);
  params.append("userTimezone", userTimezone);
  params.append("teamId", teamID);

  // Stitch any active metric filters onto this request. Except TeamId. We already have TeamId at home.
  if (dashboardFilters) {
    for (const [key, value] of Object.entries(dashboardFilters)) {
      if (key !== "teamId" && value !== null) {
        params.append(key, value);
      }
    }
  }

  const response = await axiosInstance.get<{ Url: string }>(
    `capture/ExportCaptureDataByDate?${params.toString()}`
  );

  // Note that the URL received automatically stitches onto your base URL, so if you're running locally, this won't work.
  // Directly opens the response into a new window.
  window.open(response.data.Url, "_blank");
  return response.data;
};

export default getExportCaptureDataByDate;
