import React from "react";
import { CalendarMonth } from "@mui/icons-material";
import FilterHeaderValue from "../FIlterHeaderValue";
import DashboardBoxContainer from "../DashboardBoxContainer";
import { useDateFilterStore } from "../../../../stores/metrics/DateRangeFilterStore";

import dayjs from "dayjs";
import { urStorePalette } from "../../../../themes/urStoreTheme";

interface DateFilterProps {
  handleFilterClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const DateFilter = (props: DateFilterProps) => {
  const { startDate, endDate } = useDateFilterStore();

  return (
    <DashboardBoxContainer
      onClick={props.handleFilterClick}
      position="start"
      active={props.active}
    >
      <CalendarMonth
        sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}
      />

      {/* If only startDate is  selected*/}
      {dayjs(startDate).isValid() && !endDate && (
        <FilterHeaderValue
          value={`From ${dayjs(startDate).format("MMM D, YYYY")}`}
          active={props.active}
        />
      )}

      {/* If only endDate is  selected*/}
      {dayjs(endDate).isValid() && !startDate && (
        <FilterHeaderValue
          value={`Until ${dayjs(endDate).format("MMM D, YYYY")}`}
          active={props.active}
        />
      )}

      {/* If both startDate and endDate are selected*/}
      {dayjs(startDate).isValid() && dayjs(endDate).isValid() && (
        <FilterHeaderValue
          value={`${dayjs(startDate).format("MMM D, YYYY")} - ${dayjs(
            endDate
          ).format("MMM D, YYYY")}`}
          active={props.active}
        />
      )}

      {/* If no date is selected*/}
      {!startDate && !endDate && (
        <FilterHeaderValue value={"All time"} active={props.active} />
      )}
    </DashboardBoxContainer>
  );
};

export default DateFilter;
