import axiosInstance from "../axiosInstanceConfig";

interface AdminMakeProductRequestData {
  UPC: string;
}

const postAdminMakeProduct = async (data: AdminMakeProductRequestData) => {
  // Doesn't return a flat string of the updated ID, returns a nested {id: string} one for some reason.
  const response = await axiosInstance.post<{ id: string }>(
    `products/AdminMakeProduct`,
    data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error making the product. Please contact support."
    );
  }
};

export default postAdminMakeProduct;
