import { StoreData } from "../../features/stores/types/StoreResponseData";
import axiosInstance from "../axiosInstanceConfig";

const getStore = async (storeId: string) => {
  const params = new URLSearchParams();
  params.append("StoreId", storeId);

  const response = await axiosInstance.get<StoreData>(
    `store/GetStore?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the store. Please contact support."
    );
  }
};

export default getStore;
