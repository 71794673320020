import axiosInstance from "../axiosInstanceConfig";

export interface GetAdminMissingImagesResponse {
  UPC: string,
  Name: string
}

const getAdminMissingPlanogramImages = async (planogramID: string) => {

  const params = new URLSearchParams();
  params.append("planogramId", planogramID);

  const response = await axiosInstance.get<GetAdminMissingImagesResponse[]>(
    `planogram/AdminMissingImagesForPlanogram?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of missing images. Please contact support."
    );
  }
  
};

export default getAdminMissingPlanogramImages;
