import { Typography } from "@mui/material";
import React from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";

interface FilterHeaderValueProps {
  value: string | number | null;
  active: boolean;
}

const FilterHeaderValue = (props: FilterHeaderValueProps) => {
  return (
    <Typography
      style={{
        transition: "all 0.2s ease-in-out",
        fontSize: "0.875rem",
        fontWeight: 600,
        color: props.active ? urStorePalette.greys.light : "royalblue",
      }}
    >
      {props.value}{" "}
      {typeof props.value === "number"
        ? props.value > 1
          ? "Bays"
          : "Bay"
        : ""}
    </Typography>
  );
};

export default FilterHeaderValue;
