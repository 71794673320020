import { create } from "zustand";
import { persist } from "zustand/middleware";
import getUserTeams from "../../api/teams/getUserTeams";

type TeamStoreState = {
  teams: UrStoreTeam[];
  selectedTeam: string;
  roleOnTeam: string;
  loading: boolean;
  error: string | null;
};

type TeamStoreActions = {
  setSelectedTeam: (_newTeam: string) => void;
  setTeams: (_teams: any[]) => void;
  fetchTeams: (_autoSelectTeam: boolean) => void;
};

export interface UrStoreTeam {
  TeamId: string;
  Name: string;
  Role: string;
  JoinedTeam: Date;
  TeamCreatedAt: Date;
  TeamLastModified: Date;
}

export const useTeamStore = create<TeamStoreState & TeamStoreActions>()(
  persist(
    (set, getState) => ({
      teams: [],
      selectedTeam: "",
      roleOnTeam: "",
      loading: false,
      error: null,
      fetchTeams: (_autoSelectTeam) => {
        // Turn Loading On and reset error
        set({ loading: true, error: null });

        // Get ref to existing team.
        const { selectedTeam } = getState();

        getUserTeams()
          .then((data) => {
            if (data) {
              // Set Team Data
              set({ teams: data as UrStoreTeam[] });

              // Set Selected team if we already have one, otherwise set to first available.
              if (data.length > 0 && _autoSelectTeam) {
                set({
                  selectedTeam:
                    selectedTeam !== "" ? selectedTeam : data[0].TeamId,
                  roleOnTeam:
                    selectedTeam !== ""
                      ? data.find((t: UrStoreTeam) => t.TeamId === selectedTeam)
                          ?.Role
                      : data[0].Role,
                });
              }

              set({ error: null });

            }
          })
          .catch((error) => {
            // Set error state
            set({ error: error.message || "An error occurred while fetching teams" });
          })
          .finally(() => {
            // Turn Loading Off
            set({ loading: false });
          });
      },
      setTeams: (_teams) => set(() => ({ teams: _teams })),
      setSelectedTeam: (_team) =>
        set(() => {
          const { teams } = getState();

          return {
            selectedTeam: _team,
            roleOnTeam:
              teams.find((t: UrStoreTeam) => t.TeamId === _team)?.Role ??
              "BAD/MISSING ROLE!",
          };
        }),
    }),
    {
      name: "team",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['loading'].includes(key))
        ),
    }
  )
);
