import axiosInstance from "../axiosInstanceConfig";

const exportCaptureDataByCaptureId = async (captureId: string) => {

  const params = new URLSearchParams();
  params.append("captureId", captureId);

  const response = await axiosInstance.get<string>(
    `capture/ExportCaptureDataByCaptureId?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error exporting the capture data. Please contact support."
    );
  }
  
};

export default exportCaptureDataByCaptureId;
