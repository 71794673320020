import { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Field,
  Form,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from "formik";
import getCategories, {
  PlanogramCategory,
} from "../../../api/categories/getCategories";
import { australianStates } from "../../planograms/utils/strings";
import { PlanogramFormData } from "./AdminPlanogramEditFormikWrapper";

interface AdminPlanogramEditFormProp {
  values: PlanogramFormData;
  touched: FormikTouched<PlanogramFormData>;
  errors: FormikErrors<PlanogramFormData>;
  isEditMode: boolean;
  onDisabledChanged: (disabled: boolean) => void;
  displayHelperText: boolean;
}

const AdminPlanogramEditForm: React.FC<AdminPlanogramEditFormProp> = ({
  values,
  errors,
  touched,
  isEditMode,
  onDisabledChanged,
  displayHelperText,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<PlanogramCategory[]>([]);

  const { isValid, dirty } = useFormikContext<PlanogramFormData>();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    onDisabledChanged(!isValid || !dirty);
  }, [isValid, dirty]);

  // Functions
  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load planogram Categories.",
      });
    }
  };

  return (
    <Form id="planogram-form">
      <Grid sx={{ minWidth: "400px", mt: 1 }} container spacing={4}>
        <Grid item xs={12}>
          <Field
            disabled={!isEditMode}
            required
            size="small"
            id="Name"
            name="Name"
            label="Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.Name}
            error={touched.Name && Boolean(errors.Name)}
            helperText={
              (touched.Name && errors.Name) ??
              (displayHelperText &&
                "Name is how Planograms are identified to reporting managers.")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            disabled={!isEditMode}
            size="small"
            id="DisplayName"
            name="DisplayName"
            label="Display Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.DisplayName}
            error={touched.DisplayName && Boolean(errors.DisplayName)}
            helperText={
              (touched.DisplayName && errors.DisplayName) ??
              (displayHelperText &&
                "Display Name is how Planograms will be displayed to mobile app users.")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required disabled={!isEditMode} size="small">
            <InputLabel id="category-select-label">Category</InputLabel>
            <Field
              fullWidth
              as={Select}
              id="PlanogramCategoryId"
              name="PlanogramCategoryId"
              label="Category"
              value={values.PlanogramCategoryId}
            >
              {categories.map((category) => (
                <MenuItem key={category.Id} value={category.Id}>
                  {category.Name}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText
              error={
                touched.PlanogramCategoryId &&
                Boolean(errors.PlanogramCategoryId)
              }
            >
              {touched.PlanogramCategoryId && errors.PlanogramCategoryId}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Field
            disabled={!isEditMode}
            size="small"
            id="LayoutName"
            name="LayoutName"
            label="Layout Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.LayoutName}
            error={touched.LayoutName && Boolean(errors.LayoutName)}
            helperText={touched.LayoutName && errors.LayoutName}
          />
        </Grid>
        <Grid item xs={9}>
          <FormControl disabled={!isEditMode} size="small" fullWidth>
            <InputLabel id="demo-simple-select-label-2">State</InputLabel>
            <Field
              as={Select}
              id="State"
              name="State"
              label="State"
              value={values.State || ""}
            >
              <MenuItem key={"empty-state-selection"} value={""}>
                <em>None</em>
              </MenuItem>
              {australianStates.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="Size"
            name="Size"
            label="Size"
            value={values.Size}
            error={touched.Size && Boolean(errors.Size)}
            helperText={touched.Size && errors.Size}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={!isEditMode}
            size="small"
            id="FixtureType"
            name="FixtureType"
            label="Fixture Type"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.FixtureType}
            error={touched.FixtureType && Boolean(errors.FixtureType)}
            helperText={touched.FixtureType && errors.FixtureType}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={!isEditMode}
            size="small"
            id="FixtureName"
            name="FixtureName"
            label="Fixture Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.FixtureName}
            error={touched.FixtureName && Boolean(errors.FixtureName)}
            helperText={touched.FixtureName && errors.FixtureName}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="FixtureHeight"
            name="FixtureHeight"
            label="Fixture Height"
            value={values.FixtureHeight}
            error={touched.FixtureHeight && Boolean(errors.FixtureHeight)}
            helperText={touched.FixtureHeight && errors.FixtureHeight}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="FixtureDepth"
            name="FixtureDepth"
            label="Fixture Depth"
            value={values.FixtureDepth}
            error={touched.FixtureDepth && Boolean(errors.FixtureDepth)}
            helperText={touched.FixtureDepth && errors.FixtureDepth}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth disabled={!isEditMode} size="small">
            <InputLabel id="category-select-label">Direction</InputLabel>
            <Field
              fullWidth
              as={Select}
              id="RightToLeft"
              name="RightToLeft"
              label="Direction"
              value={values.RightToLeft}
            >
              <MenuItem key={"empty-direction-selection"} value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem key="LeftToRight" value={"0"}>
                Left to Right
              </MenuItem>
              <MenuItem key="RightToLeft" value={"1"}>
                Right to Left
              </MenuItem>
            </Field>
          </FormControl>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AdminPlanogramEditForm;
