import axiosInstance from "../axiosInstanceConfig";

export interface PlanogramAssignment {
  PlanogramAssignmentId: string;
  PlanogramRevisionId: string;
  StoreId: string;
  PlanogramName: string;
  PlanogramDisplayName: string | null;
  RevisionName: string | null
  Notes: string;
  Direction: boolean;
  Deactivated: boolean;
  CreatedAt: string;
  LastModified: string;
}
const getMyPlanograms = async (teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.get<PlanogramAssignment[]>(
    `planogram/MyPlanograms?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the planogram list. Please contact support."
    );
  }
};

export default getMyPlanograms;
