import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { ProductImg } from "../../../products/components/ProductImg";
import { Close } from "@mui/icons-material";
import { GetLabelBySideNumber } from "../RevisionSideUtils";

interface ViewImagesModalProps {
  open: boolean;
  onClose: () => void;
  productId: string;
  sides: number[];
  revisionId: string;
}

const ViewImagesModal = (props: ViewImagesModalProps) => {
  const PRODUCT_IMAGE_HEIGHT = 110;

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <DialogTitle>View Revision Images</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Stack
          direction="column"
          gap={2}
          sx={{
            marginTop: -3,
            marginBottom: 4,
          }}
        >
          <Typography variant="body1">
            <b>Product ID</b>: {props.productId}
          </Typography>
          <Typography>
            <b>Number of sides:</b> {props.sides.length}
          </Typography>
          <Typography>
            <b>Revision ID:</b> {props.revisionId}
          </Typography>
        </Stack>

        <Stack
          display="flex"
          gap={3}
          justifyContent="space-evenly"
          direction="row"
        >
          {props.sides
            .sort((a, b) => a - b)
            .map((sideNumber, index) => (
              <Stack
                gap={3}
                key={index + sideNumber + props.productId}
                direction="column"
              >
                <Box
                  sx={{
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "2px solid #ccc",
                    borderRadius: 3,
                  }}
                >
                  <Typography variant="caption">
                    {`${GetLabelBySideNumber(sideNumber)} (Side ${sideNumber})`}
                  </Typography>
                  <ProductImg
                    key={index + props.productId}
                    productId={props.productId}
                    revisionId={props.revisionId}
                    clickable
                    sideNumber={sideNumber}
                    defaultSize={PRODUCT_IMAGE_HEIGHT}
                    growSize={PRODUCT_IMAGE_HEIGHT * 1}
                  />
                </Box>
              </Stack>
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ViewImagesModal;
