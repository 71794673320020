import { useEffect } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Popover,
  Typography,
  Chip,
} from "@mui/material";
import { useLocationFilterStore } from "../../../../stores/metrics/LocationFilterStore";
import { useStoreListStore } from "../../../../stores/StoreListStore";
import { useMasterFilterStore } from "../../../../stores/metrics/MasterFilterStore";
import { FilterInputStyle } from "../../helpers/DashboardHelpers";
import { australianStates } from "../../../planograms/utils/strings";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { StoreData } from "../../../stores/types/StoreResponseData";
import MultiTag from "./MultiTag";

interface LocationFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const LocationFilterModal = (props: LocationFilterModalProps) => {
  // Hooks
  const {
    country,
    state,
    setLocationCountry,
    setLocationState,
    setLocationStore,
  } = useLocationFilterStore();

  const stores = useStoreListStore((state) => state.stores);

  // State
  const open = Boolean(props.anchorEl);
  const id = open ? "dashboard-filter" : undefined;

  // Master
  const { syncedFilters, setSyncFilters } = useMasterFilterStore();

  // Constants
  const countries = [
    { value: "Australia", label: "Australia" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  const handleStateChange = (values: { label: string; value: string }[]) => {
    // If only 1 state, set name directly. Otherwise, set as number of filtered states.
    // This only affects the labelling on the trigger control.
    if (!values || values.length === 0) {
      setLocationState("");
    }

    if (values?.length === 1) {
      setLocationState(values[0].label);
    }

    if (values?.length !== undefined && values.length > 1) {
      setLocationState(`${values.length} States`);
    }
  };

  const handleStoreChange = (value: StoreData[] | null) => {
    // If only 1 store, set name directly. Otherwise, set as number of stores.
    // This function just controls the labelling on the dashboard filter.
    if (!value || value.length === 0) {
      setLocationStore({
        label: "",
        value: "",
      });
    }

    if (value?.length === 1) {
      setLocationStore({
        label: value[0]?.Name || "",
        value: value[0]?.StoreId || "",
      });
    }

    if (value?.length !== undefined && value.length > 1) {
      setLocationStore({
        label: `${value?.length} Stores`,
        value: "MULTI",
      });
    }
  };

  useEffect(() => {
    setLocationCountry(syncedFilters?.country ?? "");

    // If syncedFilter states are exactly 1, use that state name. Otherwise, show # of states.
    if (syncedFilters?.state?.length === 1) {
      setLocationState(syncedFilters.state[0]);
    } else {
      if (syncedFilters?.state?.length) {
        setLocationState(`${syncedFilters?.state?.length} States`);
      } else {
        setLocationState("");
      }
    }

    // If syncedfilter storeIds are exactly 1, lookup that store name.
    if (syncedFilters?.storeId?.length === 1) {
      const store = stores.find(
        (store) => store.StoreId === syncedFilters?.storeId?.[0]
      );

      if (store) {
        setLocationStore({ label: store.Name, value: store.StoreId });
      } else {
        setLocationStore({ label: "", value: "" });
      }
    }

    // If syncedFilters has multiple Store IDs, collapse the label to just indicate the # of stores.
    if (
      Array.isArray(syncedFilters?.storeId) &&
      syncedFilters!.storeId.length > 1
    ) {
      setLocationStore({
        label: `${syncedFilters?.storeId?.length} Stores`,
        value: "MULTI",
      });
    }
  }, [syncedFilters]);

  return (
    <div>
      <Popover
        sx={{ mt: 2 }}
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid gap={4} container sx={{ p: 4, width: "360px" }}>
          {/* Location */}
          <Grid item xs={12}>
            <Typography variant="body1">Location</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={countries.filter((c) =>
                  stores.map((s) => s.Country).includes(c.value)
                )}
                getOptionLabel={(option) => option.label}
                value={
                  countries.find((c) => c.value === syncedFilters?.country) ||
                  null
                }
                onChange={(event, value) => {
                  setLocationCountry(value?.value || "");
                  setSyncFilters(["country"], value?.value || "");
                  setLocationStore({ label: "", value: "" });
                  setLocationState("");
                  setSyncFilters(["state", "storeId"], null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...FilterInputStyle,
                        backgroundColor: params.disabled
                          ? urStorePalette.greys.light
                          : urStorePalette.greys.lightest,
                      },
                    }}
                    required
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
              />
            </FormControl>
          </Grid>

          {/* States */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disabled={!country || country === "New Zealand"}
                options={australianStates.filter((state) =>
                  stores.map((store) => store.State).includes(state.value)
                )}
                getOptionLabel={(option) => option.label}
                value={australianStates.filter((state) =>
                  syncedFilters?.state?.includes(state.value)
                )}
                onChange={(event, value) => {
                  // Handle updating state.
                  handleStateChange(value);
                  setSyncFilters(
                    ["state"],
                    value.map((v) => v.value)
                  );

                  // Reset Store Selections as the list may change. No, I'm not doing a bunch of extra checks here.
                  setLocationStore({ label: "", value: "" });
                  setSyncFilters(["storeId"], null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...FilterInputStyle,
                        backgroundColor: params.disabled
                          ? urStorePalette.greys.light
                          : urStorePalette.greys.lightest,
                      },
                    }}
                    required
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderTags={(tagValues, getTagProps) =>
                  tagValues.map((option, index) => (
                    // Key is provided by the getTagProps. ESLint thinks it isn't.
                    // eslint-disable-next-line react/jsx-key
                    <MultiTag
                      {...getTagProps({ index })}
                      label={option.label}
                    />
                  ))
                }
              />
            </FormControl>
          </Grid>

          {/* Store */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  country === "Australia"
                    ? !syncedFilters?.state || syncedFilters.state.length === 0
                    : country !== "New Zealand"
                }
                options={stores.filter((store) => {
                  return country === "New Zealand"
                    ? store.Country === "New Zealand"
                    : syncedFilters?.state?.includes(store.State) &&
                        country === "Australia";
                })}
                multiple
                getOptionLabel={(option) => option.Name}
                value={stores.filter((store) =>
                  syncedFilters?.storeId?.includes(store.StoreId)
                )}
                onChange={(event, value) => {
                  handleStoreChange(value);
                  setSyncFilters(
                    ["storeId"],
                    value?.map((s) => s.StoreId)
                  );
                }}
                disableCloseOnSelect
                renderTags={(tagValues, getTagProps) =>
                  tagValues.map((option, index) => (
                    // Key is provided by the getTagProps. ESLint thinks it isn't.
                    // eslint-disable-next-line react/jsx-key
                    <MultiTag {...getTagProps({ index })} label={option.Name} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Store"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...FilterInputStyle,
                        backgroundColor: params.disabled
                          ? urStorePalette.greys.light
                          : urStorePalette.greys.lightest,
                      },
                    }}
                    required
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.StoreId === value.StoreId
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default LocationFilterModal;
