import axiosInstance from "../axiosInstanceConfig";

export interface GetAdminLowResImagesResponse {
  continuationToken: string;
  items: LowResItem[];
}

export interface LowResItem {
  productId: string;
  fileSize: number;
  productUPC: string;
}

const getAdminFindLowResImages = async (continuationToken: string | null) => {
  const params = new URLSearchParams();
  if (continuationToken) {
    params.append("ContinuationToken", continuationToken);
  }

  const response = await axiosInstance.post<GetAdminLowResImagesResponse>(
    `products/AdminFindLowResImages?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of low res images. Please contact support."
    );
  }
};

export default getAdminFindLowResImages;
