import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import postToggleUserActivation from '../../../api/teams/postToggleUserActivation';
import { LoadingButton } from '@mui/lab';
import { TeamMember } from '../../../stores/teams/TeamMemberStore';

interface ToggleUserActivationModalProps {
  open: boolean;
  onClose: () => void;
  user: TeamMember | null;
  selectedTeam: string;
  reloadTeamMembers: () => void;
}

export const ToggleUserActivationModal = (props:ToggleUserActivationModalProps) => {

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isRequestSuccessful, setIsRequestSuccessful] = useState<
    boolean | null
  >(null);

  const handleSubmit = async () => {
    setLoading(true);
    const requestData = {
      TeamId: props.selectedTeam,
      UserId: props.user?.UserId || "",
      Active: !props.user?.Active
    };

    try {
      const data = await postToggleUserActivation(requestData);
      if (data) {
        setLoading(false);
        setIsRequestSuccessful(true);
        props.reloadTeamMembers();
        props.onClose();
      }
    } catch (error: any) {
      setErrorMessage(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  const closeSuccessSnackbar = () => {
    setIsRequestSuccessful(null);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isRequestSuccessful !== null && isRequestSuccessful === true}
        autoHideDuration={3000}
        onClose={closeSuccessSnackbar}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Success
        </Alert>
      </Snackbar>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
          {props.user && props.user.Active ? "Deactivate " : "Activate "}
          User
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant='body1'>
            Are you sure you want to {props.user && props.user.Active ? "deactivate" : "activate"} <b>{props.user && props.user.Email}</b>?
          </Typography>
          {errorMessage && (
            <Alert sx={{ mt: 2 }} severity="error">
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant='contained'
            onClick={() => {
              handleSubmit();
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};