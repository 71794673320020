
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import { ProductComparisonTrainingButtonsTypes } from '../../../../models/planograms/ProductComparisonTypes';


export const ProductComparisonTrainingButtons: ProductComparisonTrainingButtonsTypes[] =
  [
    {
      id: 1,
      label: "Remove Image",
      value: 'removeImage',
      icon: RemoveCircleOutlineOutlinedIcon
    },
  ];
