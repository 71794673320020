import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Map, useMap } from "react-mapkit";

export interface Coordinate {
  latitude: number;
  longitude: number;
}

const StoreLocation = ({
  longitude,
  latitude,
}: {
  longitude: number;
  latitude: number;
}) => {
  const { map, mapkit, mapProps } = useMap();

  useEffect(() => {
    if (map && mapkit && latitude && longitude) {
      try {
        const center = new mapkit.Coordinate(latitude, longitude);
        const span = new mapkit.CoordinateSpan(14, 14);
        const region = new mapkit.CoordinateRegion(center, span);
        const marker = new mapkit.MarkerAnnotation(center);
        map.setRegionAnimated(region);
        map.addAnnotation(marker);
      } catch (error) {
        console.error("Failed to set region to the map");
      }
    }
  }, [map, mapkit, longitude, latitude]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {mapkit && <Map {...mapProps}></Map>}
    </Box>
  );
};

export default StoreLocation;
