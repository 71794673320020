import axiosInstance from "../axiosInstanceConfig";

export interface AdminAssignPlanogramToStoreRequestData {
  PlanogramRevisionId: string;
  StoreId: string;
  Notes: string;
  Direction: boolean;
  Deactivated: boolean;
}

const postAdminAssignPlanogramToStore = async (
  data: AdminAssignPlanogramToStoreRequestData | AdminAssignPlanogramToStoreRequestData[]
) => {
  let transformedData = data;

  if(!Array.isArray(data)){
    transformedData = [data];
  }


  const response = await axiosInstance.post(
    `planogram/AdminAssignPlanogramToStore`,
    transformedData
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error assigning the planogram to the store. Please contact support."
    );
  }
};

export default postAdminAssignPlanogramToStore;
