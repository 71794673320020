import axiosInstance from "../axiosInstanceConfig";

const getMapKitToken = async () => {
  const response = await axiosInstance.get(
    `dashboard/GetMapKitToken`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error getting the map kit token. Please contact support.");
  }
};

export default getMapKitToken;
