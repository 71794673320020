import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { useEffect, useState } from "react";
import postStartTrainingBundleExport from "../../../../api/ml/postStartTrainingBundleExport";
import { CloseOutlined } from "@mui/icons-material";
import getCategories, {
  PlanogramCategory,
} from "../../../../api/categories/getCategories";
import { useSnackbar } from "notistack";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface MLArchiveUtilityProps {
  modal?: boolean;
  modalOpen?: boolean;
  modalOnClose?: () => void;
}

const UTIL_DESCRIPTION =
  "Begins generating a new export for the given product category. Please allow some time for the export to become available on the Exports page.";

export const RequestMLArchiveUtility = (props: MLArchiveUtilityProps) => {
  // Util
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<PlanogramCategory[]>([]);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "year")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [dateError, setDateError] = useState<boolean>(false);
  const [includeDateFilter, setIncludeDateFilter] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    fetchCategories();
  }, []);

  // Functions
  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load planogram Categories.",
      });
    }
  };

  const handleSubmitRequest = async () => {
    if (startDate && endDate && startDate.isAfter(endDate)) {
      setDateError(true);
      return;
    }

    try {
      setRequestPending(true);
      if (includeDateFilter) {
        await postStartTrainingBundleExport(category, startDate!, endDate!);
      } else {
        await postStartTrainingBundleExport(category);
      }

      enqueueSnackbar("Success", {
        variant: "success",
        cta: "Request accepted. Your archive will be ready shortly.",
      });

      // Close snack automatically on succesful request.
      props.modalOnClose && props.modalOnClose();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Export request failed. Please contact support.",
      });
    } finally {
      setRequestPending(false);
    }
  };

  const handleStartDateChange = (value: Dayjs | null) => {
    setStartDate(value);
    if (value && endDate && value.isAfter(endDate)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const handleEndDateChange = (value: Dayjs | null) => {
    setEndDate(value);
    if (startDate && value && startDate.isAfter(value)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  return (
    <>
      {/* Archive Utility */}
      {props.modal ? (
        <Dialog open={Boolean(props.modalOpen)}>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              Request ML Export
              <IconButton onClick={props.modalOnClose}>
                <CloseOutlined />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap={4}>
              <Typography color={urStorePalette.greys.darkest} variant="body2">
                {UTIL_DESCRIPTION}
              </Typography>
              {/* Category */}
              <TextField
                fullWidth
                value={category}
                label="Category"
                onChange={(e) => setCategory(e.target.value)}
                size="small"
                select
              >
                {categories.map((category) => (
                  <MenuItem key={category.Id} value={category.Name}>
                    {category.Name}
                  </MenuItem>
                ))}
              </TextField>
              {/* Include Date Restriction */}
              <Stack direction="row" alignItems={"center"}>
                <Checkbox
                  checked={includeDateFilter}
                  onChange={(e) => setIncludeDateFilter(e.target.checked)}
                />
                <Typography variant="subtitle2">Use Date Range</Typography>
              </Stack>

              {/* Start/End Dates */}
              <Stack direction="row" gap={4} sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "50%" }}
                    disableFuture
                    disabled={requestPending || !includeDateFilter}
                    format="DD/MM/YYYY"
                    onChange={handleStartDateChange}
                    value={startDate ? startDate : null}
                    label="Start Date"
                    slotProps={{ textField: { size: "small" } }}
                  />
                  <DatePicker
                    sx={{ width: "50%" }}
                    disableFuture
                    disabled={requestPending || !includeDateFilter}
                    format="DD/MM/YYYY"
                    onChange={handleEndDateChange}
                    value={endDate ? endDate : null}
                    label="End Date"
                    slotProps={{
                      textField: {
                        size: "small",
                        helperText: dateError
                          ? "End date must be after start date"
                          : "",
                        error: dateError,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.modalOnClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              loading={requestPending}
              disabled={
                category === "" ||
                !startDate?.isValid() ||
                !endDate?.isValid() ||
                dateError
              }
              onClick={handleSubmitRequest}
            >
              Request
            </LoadingButton>
          </DialogActions>
        </Dialog>
      ) : (
        <Card>
          <CardContent>
            <Stack gap={2}>
              <Typography fontWeight={500} variant="body1">
                Export ML Data
              </Typography>
              <Typography
                fontStyle={"italic"}
                color={urStorePalette.greys.dark}
                variant="caption"
              >
                {UTIL_DESCRIPTION}
              </Typography>
            </Stack>
            <CardActions sx={{ mt: 2, px: 0, justifyContent: "space-between" }}>
              <TextField
                fullWidth
                label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                size="small"
                select
              >
                {categories.map((category) => (
                  <MenuItem key={category.Id} value={category.Name}>
                    {category.Name}
                  </MenuItem>
                ))}
              </TextField>
              <LoadingButton
                variant="contained"
                loading={requestPending}
                disabled={category === ""}
                onClick={handleSubmitRequest}
              >
                Request
              </LoadingButton>
            </CardActions>
          </CardContent>
        </Card>
      )}
    </>
  );
};
