import { StoreData } from "../../features/stores/types/StoreResponseData";
import axiosInstance from "../axiosInstanceConfig";

const getMyStores = async (teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.get<StoreData[]>(
    `store/MyStores?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the stores. Please contact support."
    );
  }
};

export default getMyStores;
