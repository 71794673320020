import { CaptureProductNoInput } from "../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../axiosInstanceConfig";

export interface MLExport {
  Id: string;
  Category: string;
  CreatedAt: Date;
  DownloadURL: string | null;
}

const getExports = async () => {
  try {
    const response = await axiosInstance.get<MLExport[]>(
      `trainingimage/ListTrainingBundles`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "There was an error getting the  list of exports. Please contact support."
    );
  }
};

export default getExports;
