import { create } from "zustand";
import { StoreData } from "../features/stores/types/StoreResponseData";

type StoreListStore = {
  stores: StoreData[];
  setStoreList: (_storesListData: StoreData[]) => void;
};

export const useStoreListStore = create<StoreListStore>((set) => ({
  stores: [],
  setStoreList: (storesListData: StoreData[]) =>
    set(() => ({ stores: storesListData })),
}));
