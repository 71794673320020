import axiosInstance from "../axiosInstanceConfig";

const postAdminDeleteCapture = async (
  captureId: string,
) => {
  const params = new URLSearchParams();
  params.append("captureId", captureId);

  const response = await axiosInstance.delete(
    `capture/AdminDeleteCapture?${params.toString()}`
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error(
      "There was an error deleting the capture. Please contact support."
    );
  }
};

export default postAdminDeleteCapture;
