import React from "react";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import ClassIcon from "@mui/icons-material/Class";
import FilterHeaderValue from "../../FIlterHeaderValue";
import DashboardBoxContainer from "../../DashboardBoxContainer";
import { East } from "@mui/icons-material";
import { urStorePalette } from "../../../../../themes/urStoreTheme";

interface CategoryFilterProps {
  handleFilterClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const CategoryFilter = (props: CategoryFilterProps) => {
  const { category, subcategory, manufacturer, brand } =
    usePlanogramFilterStore();

  return (
    <DashboardBoxContainer
      onClick={(e) => props.handleFilterClick(e)}
      active={props.active}
    >
      <ClassIcon
        sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}
      />
      <FilterHeaderValue
        value={category !== "" ? category : "All Products"}
        active={props.active}
      />

      {subcategory && (
        <>
          <East color="disabled" sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}/>{" "}
          <FilterHeaderValue value={subcategory} active={props.active} />
        </>
      )}

      {manufacturer && (
        <>
          <East color="disabled" sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}/>{" "}
          <FilterHeaderValue value={manufacturer} active={props.active} />
        </>
      )}

      {brand && (
        <>
          <East color="disabled" sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}/>{" "}
          <FilterHeaderValue value={brand} active={props.active} />
        </>
      )}
    </DashboardBoxContainer>
  );
};

export default CategoryFilter;
