import { UrStoreTeam } from "../../stores/teams/TeamStore";
import axiosInstance from "../axiosInstanceConfig";

const getUserTeams = async () => {
  const response = await axiosInstance.get<UrStoreTeam[]>(`user/myteams`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of teams. Please contact support."
    );
  }
};

export default getUserTeams;
