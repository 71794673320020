import axiosInstance from "../axiosInstanceConfig";

/**
 * @deprecated This endpoint is unmaintained and may no longer function correctly, and does not have typing.
 * @returns A list of ML Models used by URStore.
 */
const getAdminListMLModels = async () => {
  const response = await axiosInstance.get(`asset/AdminListMLModels`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of ML models. Please contact support."
    );
  }
};

export default getAdminListMLModels;
