import { Box, CssBaseline, Typography } from "@mui/material";

function ProfileIndex(props: any) {
  const teamID = props.selectedTeam || null;
  return (
    <Box style={{margin: 10}}>
      <CssBaseline/>
      <Typography variant="h5">Profile</Typography>
    </Box>
  );
}

export default ProfileIndex;