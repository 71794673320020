import { Navigate, useLocation } from "react-router-dom";

export function RequireTeam(props: any) {
    let location = useLocation();
  
    if (props.team && props.team.length > 0) {
      return props.children;
    }
  
    // Redirect them to the /welcome page, as the user could be already logged in.
    return (
      <Navigate to="/welcome" state={{ from: location }} replace />
    );
  }