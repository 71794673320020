import axiosInstance from "../axiosInstanceConfig";

type StoreCodeUpdateRequest = {
  TeamId: string,
  StoreId: string,
  Code: string
}

const updateStoreCode = async (data: StoreCodeUpdateRequest) => {
  const response = await axiosInstance.post<string>(`store/UpdateStoreCode`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error updating the store. Please contact support.");
  }
};

export default updateStoreCode;
