import { PlanogramRevision } from "../../models/planograms/CaptureTypes";
import axiosInstance from "../axiosInstanceConfig";

const getPlanogramRevisionById = async (captureId: string, teamId: string) => {

  const params = new URLSearchParams();
  params.append("id", captureId);
  params.append("teamId", teamId);

  const response = await axiosInstance.get<PlanogramRevision>(
    `planogram/GetPlanogramRevisionById?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the planogram revision. Please contact support."
    );
  }
};

export default getPlanogramRevisionById;
