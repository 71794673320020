import axiosInstance from "../axiosInstanceConfig";

// Returns all products included on planograms for a given team.
const postUpdateProductSKUCode = async (
  teamId: string,
  code: string | null,
  productId: string
) => {
  const postBody = {
    TeamId: teamId,
    Code: code,
    ProductId: productId,
  };

  const response = await axiosInstance.post<string | any>(
    `products/UpdateSKUCode`,
    postBody
  );

  return response.data;
};

export default postUpdateProductSKUCode;
