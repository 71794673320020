import {
  GroupSharp,
  RefreshRounded,
  UnfoldMoreRounded,
} from "@mui/icons-material";
import {
  MenuItem,
  Divider,
  Button,
  ListItemIcon,
  Typography,
  Menu,
} from "@mui/material";
import { urStorePalette } from "../themes/urStoreTheme";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTeamStore } from "../stores/teams/TeamStore";
import { LoadingButton } from "@mui/lab";

const TeamSelector = () => {
  // Util
  const navigate = useNavigate();

  // State
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [teamSelectOpen, setTeamSelectOpen] = useState<boolean>(false);

  // Store
  const {
    selectedTeam,
    teams,
    setSelectedTeam,
    loading,
    fetchTeams,
    roleOnTeam,
  } = useTeamStore();

  // Effects
  useEffect(() => {
    // Fetches Teams on each reload, or when the selected team changes.
    // If you do not currently have a selectedTeam, one will be automatically picked. Otherwise, your current selection remains in-tact.
    if (!loading && selectedTeam === "") {
      fetchTeams(true);
    }
  }, [selectedTeam]);

  // Function
  function getChipLabel(): React.ReactNode {
    // If loaded but no teams, show no team label.
    if (!loading && teams.length === 0) {
      return "No Teams";
    }

    // If all good, return the active team name.
    return (
      <>
        {teams.find((t: any) => t.TeamId === selectedTeam)?.Name}
        <Typography
          sx={{
            backgroundColor: "var(--brand-tertiary)",
            color: urStorePalette.greys.light,
            borderRadius: 6,
            px: 2,
            ml: 1,
            mr: -1,
            py: 0.36,
          }}
          variant="caption"
        >
          {roleOnTeam}
        </Typography>
      </>
    );
  }

  // Final out
  return (
    <>
      {/* Visible Chip */}
      <LoadingButton
        endIcon={<UnfoldMoreRounded />}
        onClick={(e) => {
          setTeamSelectOpen(true);
          setAnchor(e.currentTarget);
        }}
        disabled={loading || teams.length === 0}
        loading={loading}
      >
        {getChipLabel()}
      </LoadingButton>

      {/* Menu Ref */}
      <Menu
        open={teamSelectOpen}
        anchorEl={anchor}
        onClose={() => {
          setAnchor(null);
          setTeamSelectOpen(false);
        }}
      >
        {/* List of Teams */}
        {teams.map((team: any) => (
          <MenuItem
            key={team.TeamId}
            value={team.TeamId}
            onClick={() => {
              setSelectedTeam(team.TeamId);
              setTeamSelectOpen(false);
              setAnchor(null);
            }}
          >
            {team.Name}
          </MenuItem>
        ))}

        <Divider />

        {/* Hardcoded Menu Item to redir to My Teams page. */}
        <MenuItem style={{ padding: 0, margin: 0 }}>
          <Button
            onClick={() => {
              setTeamSelectOpen(false);
              setAnchor(null);
              navigate("../my-teams");
            }}
            style={{ width: "100%", height: "100%" }}
            sx={{ justifyContent: "normal" }}
          >
            <ListItemIcon>
              <GroupSharp />
            </ListItemIcon>
            <Typography>My Teams</Typography>
          </Button>
        </MenuItem>
        {/* Button to force reloading teams without causing nasty side-effects. */}
        <MenuItem style={{ padding: 0, margin: 0 }}>
          <Button
            onClick={() => fetchTeams(false)}
            style={{ width: "100%", height: "100%" }}
            sx={{ justifyContent: "normal" }}
          >
            <ListItemIcon>
              <RefreshRounded />
            </ListItemIcon>
            <Typography>Refresh Teams</Typography>
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(TeamSelector);
