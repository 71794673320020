import { Stack, Typography, Box, LinearProgress } from "@mui/material";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { useState, useEffect } from "react";

export interface MetricStackProps {
  /** Value should be expressed as a percentage (0~100), non-scalar. */
  value: string;
  color: string;
  /** Text that will display below the metric fill bar. */
  helperText: string;
}

export const MetricStack = (props: MetricStackProps) => {
  // Mask display value so we can show the value "rolling" visually up and down.
  const [displayValue, setDisplayValue] = useState(Number(props.value));

  // This effect controls the origin and target value of the "rolling" animation.
  // Yeah, this is a bit extra 💅
  useEffect(() => {
    // NaN Failsafe. Can occur very briefly on firstload.
    if (isNaN(Number(props.value))) {
      setDisplayValue(0);
      return;
    }

    const targetValue = Number(props.value);
    const increment = targetValue > displayValue ? 1 : -1;

    if (displayValue !== targetValue) {
      const interval = setInterval(() => {
        setDisplayValue((prevValue) => {
          const newValue = prevValue + increment;
          if (
            (increment > 0 && newValue >= targetValue) ||
            (increment < 0 && newValue <= targetValue)
          ) {
            clearInterval(interval);
            return targetValue;
          }
          return newValue;
        });
      }, 10);
      return () => clearInterval(interval);
    }
  }, [props.value, displayValue]);

  return (
    <Stack sx={{ mt: -6 }} gap={2}>
      {/* Value */}
      <Typography variant="h3" fontWeight={600} sx={{ color: props.color }}>
        {displayValue}
        <span style={{ fontSize: 28 }}>%</span>
      </Typography>

      {/* LinearProg */}
      <Box sx={{ width: "100%", color: props.color }}>
        <LinearProgress
          color="inherit"
          value={Number(props.value)}
          variant="determinate"
        />
      </Box>

      {/* Helper */}
      <Typography
        sx={{ color: urStorePalette.greys.dark, fontStyle: "italic" }}
        variant="caption"
      >
        {isNaN(Number(props.value)) ? "Loading..." : props.helperText}
      </Typography>
    </Stack>
  );
};
