import { TableCell, TableRow, Typography } from "@mui/material";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import styles from "../../pages/Dashboard.module.scss";

type MetricsRowProps = {
  primaryText: string;
  secondaryText: string;
  secondaryTextColor: string;
  displayIcon: boolean;
  onRowClick?: () => void;
};

const MetricsRow = (props: MetricsRowProps) => {
  let {
    primaryText,
    secondaryText,
    secondaryTextColor = urStorePalette.greys.darkest,
    displayIcon,
  } = props;

  function toggleIcon(): JSX.Element | null {
    if (displayIcon) {
      return (
        <TableCell width="10%">
          <ArchiveBoxIcon className={styles.dashboard__icon} />
        </TableCell>
      );
    } else {
      return null;
    }
  }

  function primaryTextWidth(): string {
    if (displayIcon) {
      return "80%";
    } else {
      return "80%";
    }
  }

  return (
    <TableRow>
      {toggleIcon()}
      <TableCell width={primaryTextWidth()} onClick={props.onRowClick}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            color: props.onRowClick ? "royalblue" : "default",
            ":hover": {
              fontWeight: props.onRowClick ? 600 : 500,
              cursor: props.onRowClick ? "pointer" : "text",
            },
          }}
        >
          {primaryText}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontWeight: 500, float: "right" }}
          variant="body1"
          color={secondaryTextColor}
        >
          {secondaryText}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default MetricsRow;
