import { create } from "zustand";
import { persist } from "zustand/middleware";

import { LayoutStoreState, LayoutStoreActions, SubRoute, NavItem } from "./LayoutStoreTypes";

const useLayoutStore = create<LayoutStoreState & LayoutStoreActions>()(
  persist(
    (set) => ({
      routeMetadata: null,
      subRoute: null,
      drawerOpen: true,
      setRouteMetadata: (_newMetadata: NavItem | null) =>
        set({ routeMetadata: _newMetadata }),
      setSubRoute: (_newSubRoute: SubRoute | null) => set({ subRoute: _newSubRoute }),
      setDrawerOpen: (_drawerOpen) => set({ drawerOpen: _drawerOpen }),
    }),
    {
      name: "layout",
    }
  )
);

export default useLayoutStore;
