import { Avatar } from "@mui/material";
import { useState, useRef, useEffect } from "react";

const LazyLoadAvatar = ({ src, alt }: { src: string; alt: string }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setLoaded(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef]);

  return (
    // @ts-ignore legacy ref type not "officially" support by MUI. TS has a big whinge but this is 100% safe.
    <Avatar
      sx={{ mr: 2 }}
      ref={imgRef}
      variant="rounded"
      src={loaded ? src : undefined}
      alt={alt}
    />
  );
};

export default LazyLoadAvatar;
