import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { ChangePassword } from "../contexts/AuthContext";

interface ChangePasswordDialogProps {
  open: boolean;

  handleClose: () => void;
}

export const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  // Form Data State
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Re-initialise when open state changes.
  useEffect(() => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [props.open]);

  // Generic change handler, make sure the id of your inputs match the name of the field in state.
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Handle outgoing request to update pword.
  const handleSubmit = () => {
    ChangePassword(
      formData.currentPassword,
      formData.newPassword,
      (changedSuccessfully: boolean) => {
        changedSuccessfully && props.handleClose();
      }
    );
  };

  // Render out.
  return (
    <Dialog open={props.open} maxWidth="xs">
      <DialogTitle> Update Password</DialogTitle>
      <DialogContent>
        <Stack spacing={2} width={280}>
          {/* Blurb */}
          <DialogContentText>
            Passwords should include:
            <ul>
              <li>
                Minimum of <b>8 characters</b>.
              </li>
              <li>
                At least <b>1 letter</b>.
              </li>
              <li>
                At least <b>1 number</b>.
              </li>
            </ul>
          </DialogContentText>

          {/* current pass */}
          <TextField
            fullWidth
            variant="standard"
            label="Current Password"
            autoFocus
            id="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            type="password"
            required
          />

          {/* new pass */}
          <TextField
            fullWidth
            variant="standard"
            label="New Password"
            autoFocus
            id="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            type="password"
            required
          />

          {/* confirm pass */}
          <TextField
            fullWidth
            variant="standard"
            label="Confirm Password"
            autoFocus
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            type="password"
            required
            helperText={
              formData.confirmPassword !== formData.newPassword &&
              "Passwords do not match."
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            // Disable if any required fields arent present.
            formData.newPassword === "" ||
            formData.currentPassword === "" ||
            formData.confirmPassword === "" ||
            formData.confirmPassword !== formData.newPassword
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
