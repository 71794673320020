import { create } from "zustand";
import { persist } from "zustand/middleware";

type SessionStoreState = {
  user: {
    // TODO: token and email are not fully utilised throughout the app yet and should not be relied upon.
    email: string | null;
    token: string;
    roles: string[];
  } | null;
  showCaptureTooltip: boolean;
};

type SessionStoreActions = {
  setUser: (_user: any) => void;
  setShowCaptureTooltip: (_newState: boolean) => void;
};

export const useSessionStore = create<
  SessionStoreState & SessionStoreActions
>()(
  persist(
    (set) => ({
      user: null,
      showCaptureTooltip: false,
      setUser: (_user) => set(() => ({ user: _user })),
      setShowCaptureTooltip: (_newState) =>
        set(() => ({ showCaptureTooltip: _newState })),
    }),
    {
      name: "session",
    }
  )
);
