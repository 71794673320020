import { format } from 'date-fns';

/*
  Functions in here relate to AgGridWrapper.
  Things like standardised display formats, etc.
*/

// Display format for a date, also performs null checks and handles bad / missing dates.
export const formatDisplayDate = (date: Date | null) => {
  return date && format(date, 'dd/MM/yyyy, p');
};