import { BrokenImageRounded, CloseOutlined } from "@mui/icons-material";
import { Box, Skeleton } from "@mui/material";
import { useState } from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";

interface ProductImageProps {
  productId: string;
  revisionId: string | null;
  clickable?: boolean;
  sideNumber?: number;

  defaultSize: number;
  growSize: number; // Set growsize === defaultSize for basically no grow, cheeky!
}

export const ProductImg = (props: ProductImageProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [invalidImage, setInvalidImage] = useState<boolean>(false);

  return (
    <Box
      key={props.productId}
      sx={{
        height: props.defaultSize,
        width: props.defaultSize,
        m: 1.5,
        transition: "0.2s ease-in-out",
        ":hover": invalidImage
          ? {}
          : { height: props.growSize, width: props.growSize },
      }}
    >
      {/* 
        Skeleton & img are both always mounted.
        They just "swap" their display: none status on load. 
      */}
      <Skeleton
        sx={loaded ? { display: "none" } : { height: "100%", width: "100%" }}
        variant="rounded"
      />
      {invalidImage && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BrokenImageRounded
            sx={{
              color: urStorePalette.greys.default,
              height: "50%",
              width: "50%",
            }}
          />
        </Box>
      )}
      <img
        id={props.productId}
        alt={""}
        src={
          props.revisionId === null
            ? `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}training/${props.productId}`
            : `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${props.productId}/${props.revisionId}${props.sideNumber? "-" + props.sideNumber : ''}`
        }
        onError={() => {
          setLoaded(true);
          setInvalidImage(true);
        }}
        onLoad={() => setLoaded(true)}
        style={
          loaded && !invalidImage
            ? {
                width: "100%",
                height: "100%",
                borderRadius: "6px",
                objectFit: "contain",
                cursor: props.clickable ? "pointer" : "default",
              }
            : { display: "none" }
        }
        // If clickable is on, clicking the img opens it in a new tab.
        {...(props.clickable && {
          onClick: () =>
            window
              .open(
                props.revisionId === null
                  ? `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}training/${props.productId}`
                  : `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${props.productId}/${props.revisionId}${props.sideNumber? "-" + props.sideNumber : ''}`,
                "_blank"
              )
              ?.focus(),
        })}
      />
    </Box>
  );
};
