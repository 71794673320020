import { create } from "zustand";
import getTeamMembers from "../../api/teams/getTeamMembers";

export type TeamMember = {
  Active: boolean;
  Email: string;
  Fullname: string | null;
  JoinedTeam: string;
  Role: string;
  TeamLastModified: string
  UserId: string
};

type TeamMemberStore = {
  teamMembers: TeamMember[];
  setTeamMembers: (teamMembers: TeamMember[]) => void;
  fetchTeamMembers: (selectedTeam: string) => Promise<void>;
};

const useTeamMemberStore = create<TeamMemberStore>((set) => ({
  teamMembers: [],
  setTeamMembers: (teamMembers) => set({ teamMembers }),
  fetchTeamMembers: async (selectedTeam: string) => {
    try {
      const data  = await getTeamMembers(selectedTeam);
      if (data) set({ teamMembers: data });
    } catch (error: any) {
      console.log(error.message);
    }
  }
}));

export default useTeamMemberStore;
