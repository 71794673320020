import React from "react";
import { PinDrop, East, Business } from "@mui/icons-material";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { useLocationFilterStore } from "../../../../stores/metrics/LocationFilterStore";
import FilterHeaderValue from "../FIlterHeaderValue";
import DashboardBoxContainer from "../DashboardBoxContainer";

interface LocationFilterProps {
  handleFilterClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const LocationFilter = (props: LocationFilterProps) => {
  const country = useLocationFilterStore((state) => state.country);
  const state = useLocationFilterStore((state) => state.state);
  const store = useLocationFilterStore((state) => state.store);

  return (
    <DashboardBoxContainer
      onClick={(e) => props.handleFilterClick(e)}
      active={props.active}
    >
      <PinDrop
        sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}
      />
      <FilterHeaderValue
        value={country !== "" ? country : "Global"}
        active={props.active}
      />
      {country && country === "Australia" ? (
        <East
          color="disabled"
          sx={{
            color: props.active
              ? urStorePalette.greys.light
              : urStorePalette.greys.default,
          }}
        />
      ) : null}

      <FilterHeaderValue value={state} active={props.active} />
      {store.label ? (
        <>
          <East
            color="disabled"
            sx={{
              color: props.active
                ? urStorePalette.greys.light
                : urStorePalette.greys.default,
            }}
          />
          <Business
            color="disabled"
            sx={{
              color: props.active
                ? urStorePalette.greys.light
                : urStorePalette.greys.default,
            }}
          />
          <FilterHeaderValue value={store.label} active={props.active} />
        </>
      ) : null}
    </DashboardBoxContainer>
  );
};

export default LocationFilter;
