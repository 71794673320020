import { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import AdminPlanogramEditForm from "./AdminPlanogramEditForm";
import { AdminPlanoResponse } from "../../../api/planograms/getAdminPlanogramById";

export interface PlanogramFormData {
  Name: string;
  PlanogramCategoryId: string | undefined;
  DisplayName?: string | undefined;
  LayoutName?: string | undefined;
  State?: string | undefined;
  Size?: string | undefined; // Defined as string to avoid a react uncontrolled input error
  FixtureType?: string | undefined;
  FixtureName?: string | undefined;
  FixtureHeight?: string | undefined; // Defined as string to avoid a react uncontrolled input error
  FixtureDepth?: string | undefined; // Defined as string to avoid a react uncontrolled input error
  RightToLeft: string | undefined; // Defined as string - "0": LeftToRight, "1": RightToLeft
}

export const convertPlanogramResponseToFormData = (
  planogramResponse: AdminPlanoResponse | null
) => {
  if (!planogramResponse) return undefined;

  const formData: PlanogramFormData = {
    Name: planogramResponse.Name,
    PlanogramCategoryId: planogramResponse.PlanogramCategoryId ?? "",
    DisplayName: planogramResponse.DisplayName ?? "",
    LayoutName: planogramResponse.LayoutName ?? "",
    State: planogramResponse.State ?? "",
    Size: planogramResponse.Size?.toString() ?? undefined,
    FixtureType: planogramResponse.FixtureType ?? "",
    FixtureName: planogramResponse.FixtureName ?? "",
    FixtureDepth: planogramResponse.FixtureDepth?.toString() ?? undefined,
    FixtureHeight: planogramResponse.FixtureHeight?.toString() ?? undefined,
    RightToLeft:
      planogramResponse.RightToLeft === null
        ? ""
        : planogramResponse.RightToLeft
        ? "1"
        : "0",
  };
  return formData;
};

const formDefaultValues: PlanogramFormData = {
  Name: "",
  PlanogramCategoryId: "",
  DisplayName: "",
  LayoutName: "",
  State: "",
  Size: "",
  FixtureName: "",
  FixtureType: "",
  FixtureHeight: "",
  FixtureDepth: "",
  RightToLeft: "",
};

const formValidationSchema = Yup.object().shape({
  Name: Yup.string()
    .required("Planogram Name is required")
    .max(128, "Exceed character limit"),
  PlanogramCategoryId: Yup.string().required("Planogram Category is required."),
  DisplayName: Yup.string().nullable().max(128, "Exceed character limit"),
  LayoutName: Yup.string().nullable().max(128, "Exceed character limit"),
  State: Yup.string().nullable(),
  Size: Yup.number().nullable().min(1),
  FixtureName: Yup.string().nullable().max(128, "Exceed character limit"),
  FixtureType: Yup.string().nullable().max(128, "Exceed character limit"),
  FixtureHeight: Yup.number().nullable().min(1),
  FixtureDepth: Yup.number().nullable().min(1),
});

interface AdminPlanogramEditFormProp {
  initialValues: PlanogramFormData | undefined;
  isEditMode: boolean;
  handleSubmit: (
    values: PlanogramFormData,
    formikHelper: FormikHelpers<PlanogramFormData>
  ) => void;
  onDisabledChanged: (disabled: boolean) => void;
  displayHelperText: boolean;
  refreshForm?: boolean;
  setRefreshForm?: (refresh: boolean) => void;
}

const AdminPlanogramEditFormikWrapper: React.FC<AdminPlanogramEditFormProp> = ({
  initialValues,
  isEditMode,
  handleSubmit,
  onDisabledChanged,
  displayHelperText,
  refreshForm,
  setRefreshForm,
}) => {
  const [initialFormValues, setInitialFormValues] = useState<
    PlanogramFormData | undefined
  >();

  useEffect(() => {
    initialiseFormValue();
  }, [initialValues]);

  useEffect(() => {
    // Reset initial values to discard the changes
    if (refreshForm) {
      setInitialFormValues(undefined);
    }
  }, [refreshForm]);

  useEffect(() => {
    if (initialFormValues === undefined && refreshForm) {
      setRefreshForm && setRefreshForm(false);
      initialiseFormValue();
    }
  }, [initialFormValues]);

  const initialiseFormValue = () => {
    if (initialValues) {
      setInitialFormValues(initialValues);
    } else {
      setInitialFormValues(formDefaultValues);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues ?? formDefaultValues}
      validationSchema={formValidationSchema}
      onSubmit={(values, formikHelper) => handleSubmit(values, formikHelper)}
    >
      {({ values, errors, touched }) => (
        <AdminPlanogramEditForm
          values={values}
          errors={errors}
          touched={touched}
          isEditMode={isEditMode}
          onDisabledChanged={onDisabledChanged}
          displayHelperText={displayHelperText}
        />
      )}
    </Formik>
  );
};

export default AdminPlanogramEditFormikWrapper;
