import axiosInstance from "../axiosInstanceConfig";

export interface PlanogramCategory {
  Id: string;
  Name: string;
  CreatedAt: Date;
  LastModified: Date;
}

const getCategories = async () => {
  const response = await axiosInstance.get<PlanogramCategory[]>(
    `planogram/ListPlanogramCategories`
  );

  return response.data;
};

export default getCategories;
