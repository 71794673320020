import { Box } from "@mui/material";
import React from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";

interface DashboardBoxContainerProps {
  children: React.ReactNode;
  position?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const DashboardBoxContainer = (props: DashboardBoxContainerProps) => {
  return (
    <Box
      onClick={(e) => (props.onClick ? props.onClick(e) : null)}
      gap={2}
      sx={{
        transition: 'all 0.2s ease-in-out',
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        alignContent: props.position ? props.position : "start",
        backgroundColor: props.active
          ? "royalblue"
          : urStorePalette.greys.light,
        borderRadius: "8px",
        py: 1,
        px: 1.5,
      }}
    >
      {props.children}
    </Box>
  );
};

export default DashboardBoxContainer;
