import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import postAdminCreatePlanogram, { convertFormDataToCreateRequestData } from "../../../api/planograms/postAdminCreatePlanogram";
import AdminPlanogramEditFormikWrapper, { PlanogramFormData } from "./AdminPlanogramEditFormikWrapper";

interface AdminCreatePlanogramDialogProp {
  open: boolean;
  onClose: (newId: string | undefined) => void;
}

const AdminCreatePlanogramDialog: React.FC<AdminCreatePlanogramDialogProp> = ({
  open,
  onClose,
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [submittingPlanogram, setSubmittingPlanogram] =
    useState<boolean>(false);

  const handleCancelPressed = () => {
    onClose(undefined);
  };

  const handleSubmit = async (values: PlanogramFormData) => {
    try {
      setSubmittingPlanogram(true);
      const request = convertFormDataToCreateRequestData(values);
      const newId = await postAdminCreatePlanogram(request);
      onClose(newId);
    } catch (err: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to add a new planogram.",
      });
      console.log(err.message);
    } finally {
      setSubmittingPlanogram(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Create new Planogram</DialogTitle>
      <DialogContent>
        <AdminPlanogramEditFormikWrapper
          initialValues={undefined}
          isEditMode={true}
          handleSubmit={handleSubmit}
          onDisabledChanged={(disabled) => setIsSubmitDisabled(disabled)}
          displayHelperText={false}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            handleCancelPressed();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={submittingPlanogram}
          variant="contained"
          color="primary"
          disabled={isSubmitDisabled}
          type="submit"
          form="planogram-form"
        >
          Create Planogram
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdminCreatePlanogramDialog;
