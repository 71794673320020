import axiosInstance from "../axiosInstanceConfig";

export interface AdminPlanoResponse {
  PlanogramId: string;
  Name: string;
  DisplayName: string | null;
  PlanogramCategoryId: string | null;
  LayoutName: string | null;
  State: string | null;
  Size: number | null;
  FixtureName: string | null;
  FixtureType: string | null;
  FixtureHeight: number | null;
  FixtureDepth: number | null;
  RightToLeft: boolean | null;
  CreatedAt: Date;
  LastModified: Date;
  Revisions: AdminPlanoRevision[];
}

export interface AdminPlanoRevision {
  PlanogramRevisionId: string;
  LastModified: Date;
}

const getAdminPlanogramById = async (planogramID: string) => {
  const params = new URLSearchParams();
  params.append("id", planogramID);

  const response = await axiosInstance.get<AdminPlanoResponse>(
    `planogram/AdminGetPlanogramById?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the planogram. Please contact support."
    );
  }
};

export default getAdminPlanogramById;
