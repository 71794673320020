import {
  Button,
  Fade,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../../features/authentication/contexts/AuthContext";
import { useEffect, useState } from "react";
import ErrorDialog from "../../../components/ErrorDialog";
import { CognitoProvider } from "../contexts/CognitoProvider";
import AuthLayoutNew from "../layouts/AuthLayout";
import { LoginOutlined } from "@mui/icons-material";
import { useTeamStore } from "../../../stores/teams/TeamStore";

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuthContext();
  const [pageError, setPageError] = useState<string>("");
  const { fetchTeams} = useTeamStore();

  let from = location.state?.from?.pathname || "/dashboard";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    auth.signin(username, password, (success: boolean, data: any) => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      if (success) {
        fetchTeams(true);
        navigate(from, { replace: true });
      } else {
        // data.error.code has the Cognito error code. Add extra if branches here as more cases arise.
        if (data?.error?.code === "UserNotConfirmedException") {
          auth.resendVerification(username, () => {});
          navigate("/activate", { state: { username: username } });
        } else {
          setPageError("Login Failed, please Try again.");
        }
      }
    });
  }

  useEffect(() => {
    // If the user is already logged in, send them on their way
    if (
      auth.user &&
      CognitoProvider.isAuthenticated &&
      Object.keys(localStorage).some((key) =>
        key.includes("CognitoIdentityServiceProvider")
      )
    ) {
      /*
        With the addition of the .isAuthenticated check, this prevents sessions from being carried over neatly between tabs.
        The original intentioon of this effect was to forwar users away from the login page if they have an open session.
        It never worked for that functionality, and this entire effect will never ever fire in its current state.
        This check was added PURELY to workaround an issue more severe than tabs being maintained between sessions.
      */
      navigate(from, { replace: true });
    }
  }, [auth.user, navigate, from]);

  function onCloseErrorDialog() {
    setPageError("");
  }

  return (
    <AuthLayoutNew>
      <Fade in={true}>
        <Stack
          height={"100%"}
          alignItems={"center"}
          gap={4}
          justifyContent={"center"}
        >
          {location.state?.from && (
            <Typography align="center" mb={4}>
              You must log in to view the page at {from}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container gap={2}>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography sx={{ mb: 2 }} fontWeight={500}>
                  Email
                </Typography>
                <TextField name="username" fullWidth />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <Typography sx={{ mb: 2 }} fontWeight={500}>
                  Password
                </Typography>

                <TextField name="password" fullWidth type="password" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "var(--brand-tertiary)",
                  }}
                  startIcon={<LoginOutlined />}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <Typography variant="body2" mt={2}>
                  Help, I <Link to="/resetPassword">forgot my password</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Fade>
      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </AuthLayoutNew>
  );
}

export default LoginPage;
