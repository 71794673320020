import { Box, Button, Fade } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import getTasks, { Task } from "../../../api/jobs/getTasks";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { ColDef } from "ag-grid-community";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { AddRounded, EditRounded } from "@mui/icons-material";
import { TaskDialog } from "../components/TaskDialog";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

interface TaskListProps {
  selectedTeam: string;
}

interface TaskRow extends Task {
  actions?: null;
}

export const TaskList = (props: TaskListProps) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { selectedTeam, roleOnTeam } = useTeamStore();

  // Effects
  useEffect(() => {
    fetchTasks();
  }, [selectedTeam]);

  // State
  const [taskLoading, setTaskLoading] = useState<boolean>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskDialogOpen, setTaskDialogOpen] = useState<boolean>(false);
  const [editTask, setEditTask] = useState<Task | null>(null);
  const [colDefs] = useState<ColDef<TaskRow>[]>([
    {
      field: "Name",
    },
    {
      field: "StartDate",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "EndDate",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "FirstCaptureInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "MerchandiseInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "SecondCaptureInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: (row: any) => (
        <Button
          variant="outlined"
          disabled={roleOnTeam !== "admin"}
          startIcon={<EditRounded />}
          size="small"
          onClick={() => {
            setTaskDialogOpen(true);
            setEditTask(row.data as Task);
          }}
        >
          Edit
        </Button>
      ),
    },
  ]);

  // Functions
  const fetchTasks = async () => {
    try {
      setTaskLoading(true);
      const data = await getTasks(selectedTeam);

      setTasks(data);
    } catch {
      setTasks([]);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load tasks",
      });
    } finally {
      setTaskLoading(false);
    }
  };

  const TaskGridPlugins = (
    <Fade in={roleOnTeam === "admin"} unmountOnExit>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => setTaskDialogOpen(true)}
        disabled={roleOnTeam !== "admin"}
      >
        New Job
      </Button>
    </Fade>
  );

  // Render
  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Dialog */}
      <TaskDialog
        open={taskDialogOpen}
        handleClose={() => {
          // Close modal and clear the edit task.
          setTaskDialogOpen(false);
          setEditTask(null);
          fetchTasks();
        }}
        editTask={editTask}
      />

      {/* Table */}
      <AgGridWrapper
        id="task-list"
        loading={taskLoading}
        columnDefs={colDefs}
        rowData={tasks}
        quickSearch
        export
        rememberPageNumber
        plugins={TaskGridPlugins}
      />
    </Box>
  );
};
