import axiosInstance from "../axiosInstanceConfig";

export interface SnapshotUploadUrlResponse {
  CaptureId: string;
  SignedUrl: string;
}

const postSnapshotUploadLinkRequest = async (
  teamId: string,
  size: number,
  assignmentId: string
) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.post<SnapshotUploadUrlResponse>(
    `capture/CreateCapture?${params.toString()}`,
    {
      Filesize: size,
      PlanogramAssignmentId: assignmentId,
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "Could not generate a Signed URL for uploading the archive, sorry king."
    );
  }
};

export default postSnapshotUploadLinkRequest;
