import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Map, Marker, useMap } from "react-mapkit";
import { useMasterFilterStore } from "../../../stores/metrics/MasterFilterStore";
import { MetricsResponseData } from "../pages/DashboardPage";
import { urStorePalette } from "../../../themes/urStoreTheme";

export interface StoreDetails {
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  complianceScore: number;
}

const MarketLocations = () => {
  const { map, mapkit, mapProps } = useMap();
  const { syncedMetricData, setSyncFilters } = useMasterFilterStore();

  function extractStoreDetails(
    metricsData: MetricsResponseData[]
  ): StoreDetails[] {
    // Initialize an empty array to store the extracted coordinates
    const coordinatesArray: StoreDetails[] = [];

    // Loop through each object in the metricsData array
    metricsData.forEach((store) => {
      // Extract longitude and latitude from each object
      const {
        Longitude,
        Latitude,
        Name,
        RangingStockCount,
        RangingTotalProduct,
        StockTotalArea,
        TotalArea,
        PositionComplianceSumMatch,
        PositionComplianceTotalSum,
      } = store;

      const _complianceScore =
        ((RangingStockCount + PositionComplianceSumMatch + StockTotalArea) /
          (RangingTotalProduct + PositionComplianceTotalSum + TotalArea)) *
        100;

      // Create a new object containing longitude and latitude, and the id.
      const coordinatesObject = {
        id: store.StoreId,
        longitude: Longitude,
        latitude: Latitude,
        name: Name,
        complianceScore: _complianceScore,
      };

      // Push the coordinates object into the coordinatesArray

      coordinatesArray.push(coordinatesObject);
    });

    // Return the array of extracted coordinates
    return coordinatesArray;
  }

  // Function to determine the color of the pin based on the compliance score
  function getPinColor(complianceScore: number) {
    switch (true) {
      case complianceScore > 90:
        return urStorePalette.metric.green;
      case complianceScore >= 50 && complianceScore <= 90:
        return urStorePalette.metric.orange;
      default:
        return urStorePalette.metric.red;
    }
  }

  function handlePinClick(storeId: string) {
    const swapStore = syncedMetricData.find((s) => s.StoreId === storeId);

    if (swapStore) {
      // Autopopulate relevant filters.
      setSyncFilters(["country"], swapStore.Country);
      if (swapStore.Country === "Australia") {
        setSyncFilters(["state"], swapStore.State);
      } else {
        setSyncFilters(["state"], null);
      }
      setSyncFilters(["storeId"], [swapStore.StoreId]);
    } else {
      // Dev err (shouldnt hit, but if it does, ticket time!)
      console.error(
        "Filters were set in an unsafe way and may be invalid/corrupt now."
      );
    }
  }

  useEffect(() => {
    const clusteringIdentifier = "store-clustering";
    if (map && mapkit) {
      // Make sure old annotations are cleared between mounts.
      map.removeAnnotations(map.annotations);

      // Add event listener to handle updating filters when pin is clicked.
      map.addEventListener("select", function (event: any) {
        const selectedPin = event.annotation;

        if (selectedPin) {
          // non-clustered pins will have no memberAnnotations
          if (!selectedPin.memberAnnotations) {
            const storeId = selectedPin.data.id;
            handlePinClick(storeId);
          } else {
            // If there are multiple pins, we need to zoom in to the cluster.
            const currentSpan = map.region.span;
            const cluster = selectedPin.memberAnnotations;
            // Tried out different zoom levels, 8 seemed to be the perfect number to zoom into the cluster's member annotations.
            const span = new mapkit.CoordinateSpan(
              currentSpan.latitudeDelta / 8,
              currentSpan.longitudeDelta / 8
            );
            const region = new mapkit.CoordinateRegion(
              cluster[0].coordinate,
              span
            );

            if (region) {
              map.setRegionAnimated(region);
            }
          }
        } else {
          // Dev only error.
          console.error(
            "A map pin was clicked but the selected pin had no associated data.id field."
          );
        }
      });

      // Generate annotations for the map.
      try {
        extractStoreDetails(syncedMetricData).forEach((store) => {
          const pinCenter = new mapkit.Coordinate(
            store.latitude,
            store.longitude
          );
          const span = new mapkit.CoordinateSpan(50, 50);
          const region = new mapkit.CoordinateRegion(pinCenter, span);

          if (region) {
            map.setRegionAnimated(region);
          }

          const newPin = new mapkit.MarkerAnnotation(pinCenter, {
            color: getPinColor(store.complianceScore),
            title: store.name,
            data: {
              id: store.id,
            },
            clusteringIdentifier: clusteringIdentifier,
          });

          map.addAnnotation(newPin);
        });
      } catch (error) {
        console.log(error);
        console.error("Failed to set region to the map");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapkit, syncedMetricData]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {mapkit && <Map {...mapProps}></Map>}
    </Box>
  );
};

export default MarketLocations;
