import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";

interface AuthLayoutProps extends PropsWithChildren {}

function AuthLayout(props: AuthLayoutProps) {
  const theme = useTheme();
  const largeViewport = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
        {largeViewport && (
          <Box
            sx={{
              width: "50%",
              height: "100%",
              backgroundColor: "var(--brand-tertiary)",
            }}
          >
            <Stack
              gap={4}
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <img
                src="/URSTORE_CROP_2024.png"
                width={586 / 2}
                height={159 / 2}
                alt="URStore Logo"
              />
              <Typography
                color={urStorePalette.metric.blue}
                fontWeight={600}
                fontSize={20}
              >
                Reporting Portal
              </Typography>
            </Stack>
          </Box>
        )}

        <Box
          sx={{
            width: largeViewport ? "50%" : "100%",
            height: "100%",
            backgroundColor: urStorePalette.greys.lightest,
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Stack
            height={"100%"}
            maxWidth={"60%"}
            alignItems={"center"}
            gap={4}
            justifyContent={"center"}
          >
            {/* Shows URStore header on small viewports. */}
            {!largeViewport && (
              <Stack
                gap={2}
                sx={{
                  width: "100vw",
                  backgroundColor: "var(--brand-tertiary)",
                  px: 4,
                  pb: 2,
                  pt: 4,
                  // This neg margin offsets the drift from the header.
                  mb: -24,
                }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src="/URSTORE_CROP_2024.png"
                  width={586 / 2}
                  height={159 / 2}
                  alt="URStore Logo"
                />
                <Typography
                  color={urStorePalette.metric.blue}
                  fontWeight={600}
                  fontSize={14}
                >
                  Reporting Portal
                </Typography>
              </Stack>
            )}

            {/* Shows layout child. */}
            {props.children}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default AuthLayout;
