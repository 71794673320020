import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface DeleteCaptureDialogProps {
  handleClose: () => void;
  handleDelete: () => void;
  open: boolean;
  captureId: string | null;
  captureName: string | null;
  loading: boolean;
  errorMessage: string | null;
}

const DeleteCaptureDialog = (props: DeleteCaptureDialogProps) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this capture?
            <Grid container sx={{ my: 2 }}>
              <Grid item xs={3}>
                Capture name:
              </Grid>
              <Grid item xs={9}>
                <b>{props.captureName ? props.captureName : ""}</b>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}>
                Capture Id:
              </Grid>
              <Grid item xs={9}>
                <b>{props.captureId ? props.captureId : ""}</b>
              </Grid>
            </Grid>
            {props.errorMessage && (
              <Alert sx={{ mt: 2 }} severity="error">
                {props.errorMessage}
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={props.loading}
            onClick={props.handleDelete}
            color="error"
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCaptureDialog;
