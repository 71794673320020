import React, { useEffect, useState, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../contexts/CognitoProvider";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import NoTeams from "../../../layouts/AppLayout/NoTeams/NoTeams";
import MerchandiserView from "../../../layouts/AppLayout/Merchandiser/MerchandiserView";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { Refresh } from "@mui/icons-material";

interface RequireAuthPageWrapperProp {
  children: ReactElement;
}

const RequireAuthPageWrapper: React.FC<RequireAuthPageWrapperProp> = (prop) => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();
  const {
    teams,
    loading,
    roleOnTeam: userCurrentRole,
    error,
    fetchTeams,
  } = useTeamStore();

  const [authenticated, setAuthenticated] = useState(false);

  const isAuthenticated = () => {
    setAuthenticated(false);
    if (auth.user && cognitoUser) {
      setAuthenticated(true);
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  function renderOutletOrNoTeamPage(children: ReactElement) {
    // If teams is loading, show an overlay.
    if (loading) {
      return (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      );
    }

    // If there's an error, show an error message
    if (error) {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Alert severity="error" sx={{ border: "none" }}>
              <AlertTitle>Problem Loading Teams</AlertTitle>
              <Stack direction="column" gap={2} alignItems="center">
                A problem was encountered while loading teams. Please try again
                with the button below, or contact support if the problem
                persists.
              </Stack>
            </Alert>
            {/* <Typography color="error" align="center" variant="h5">
              An error occurred while fetching teams. Please try again later.
            </Typography> */}
          </Grid>
          <Grid item>
            <Button
              sx={{ width: "fit-content" }}
              variant="contained"
              color="primary"
              startIcon={<Refresh />}
              onClick={() => fetchTeams(true)}
            >
              Reload
            </Button>
          </Grid>
        </Grid>
      );
    }

    // If teams are loaded but there are none available, show the no teams page.
    if (teams.length === 0) {
      return <NoTeams />;
    }

    if (userCurrentRole === "merchandiser") {
      return <MerchandiserView />;
    }

    // If teams are loaded and there is at least 1, render the route outlet.
    return children;
  }

  return authenticated ? renderOutletOrNoTeamPage(prop.children) : null;
};

export default RequireAuthPageWrapper;
