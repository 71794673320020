import { create } from "zustand";

type Brand = string[];

type Manufacturer = {
  [manufacturerName: string]: {
    Brand: Brand;
  };
};

export type SubCategory = {
  [subCategoryKey: string]: {
    Manufacturer: Manufacturer;
  };
};

export type Category = {
  [categoryKey: string]: {
    SubCategory: SubCategory;
  };
};

type MetricsFilterStateActions = {
  setMetricsFilterOptionsData: (_arg0: MetricsOptionsState) => void;
};

export type MetricsOptionsState = {
  data: {
    Category: Category;
    BayCounts: number[];
    FixtureType: string[];
  } | null;
};

const initialMetricsOptionsState: MetricsOptionsState = {
  data: null,
};

export const useMetricsFilterOptions = create<
  MetricsOptionsState & MetricsFilterStateActions
>((set) => ({
  ...initialMetricsOptionsState,
  setMetricsFilterOptionsData: (_arg0: MetricsOptionsState | null) =>
    set(() => ({
      data: _arg0 as {
        Category: Category;
        BayCounts: number[];
        FixtureType: string[];
      } | null,
    })),
}));
