import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../features/authentication/contexts/AuthContext";
import { FormEvent, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Fade,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { urStorePalette } from "../../../themes/urStoreTheme";
import AuthLayoutNew from "../layouts/AuthLayout";
import { HowToRegOutlined } from "@mui/icons-material";

function Register() {
  let navigate = useNavigate();
  let auth = useAuthContext();

  const [error, setError] = useState<string | null>(null);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    auth.signup(username, password, (result: any) => {
      // if user had registered but isn't confirmed, show the confirmation form
      if (result.success) {
        if (result.data.userConfirmed === false) {
          navigate("/activate", { state: { username: username } });
        }
        if (result.data.userConfirmed === true) {
          navigate("/dashboard");
        }
      }
      if (!result.success) {
        setError(result.data.err.message);
      }
    });
  }

  return (
    <AuthLayoutNew>
      <Fade in={true}>
        <Stack
          height={"100%"}
          alignItems={"center"}
          gap={4}
          justifyContent={"center"}
        >
          <Box>
            <form onSubmit={handleSubmit}>
              <Grid container gap={2}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 2 }} fontWeight={500}>
                    Email
                  </Typography>
                  <TextField
                    name="username"
                    fullWidth
                    autoFocus
                    onChange={() => {
                      setError(null);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 2 }} fontWeight={500}>
                    Password
                  </Typography>
                  <TextField name="password" fullWidth type="password" />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    startIcon={<HowToRegOutlined />}
                    sx={{ backgroundColor: "var(--brand-tertiary)" }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Grid container mt={4} color={urStorePalette.greys.dark}>
              <Grid item xs={12} textAlign="center">
                <Typography variant="body2" mt={2}>
                  Already registered? <Link to="/login">Log in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Fade>
    </AuthLayoutNew>
  );
}

export default Register;
