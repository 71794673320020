import { useEffect, useState, ReactNode } from "react";
import { MapkitProvider } from "react-mapkit";
import { Box } from "@mui/material";
import getMapKitToken from "../../../api/dashboard/getMapKitToken";

interface URMapkitProviderProps {
    children: ReactNode;
}

const URMapkitProvider: React.FC<URMapkitProviderProps> = (props) => {
    const { children } = props;
    const [beaconMapProps, setBeaconMapProps] = useState<{ tokenOrCallback: string }>();

    useEffect(() => {
        getMapkitToken();
    }, []);

    const getMapkitToken = async () => {
        // Call API to get the token
        try {
            const data = await getMapKitToken();
            if (data) {
                setBeaconMapProps({ tokenOrCallback: data.token });
            }
        } catch (error:any) {
           console.log(error.message);
        }
    };

    if (beaconMapProps) {
        return < MapkitProvider {...beaconMapProps}> {children}</MapkitProvider >;
    } else {
        return <Box></Box>;
    }
};

export default URMapkitProvider;
