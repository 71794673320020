import React from "react";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import NumbersIcon from "@mui/icons-material/Numbers";
import FilterHeaderValue from "../../FIlterHeaderValue";
import DashboardBoxContainer from "../../DashboardBoxContainer";
import { urStorePalette } from "../../../../../themes/urStoreTheme";

interface BayCountFilterProps {
  handleFilterClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const BayCountFilter = (props: BayCountFilterProps) => {
  const bayCount = usePlanogramFilterStore((state) => state.bayCount);

  const GetBayTitle = (bayCount: number | null) =>
    bayCount ? `${bayCount} Bays` : `All Bay Counts`;

  return (
    <DashboardBoxContainer
      onClick={(e) => props.handleFilterClick(e)}
      active={props.active}
    >
      <NumbersIcon
        sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}
      />
      <FilterHeaderValue value={GetBayTitle(bayCount)} active={props.active} />
    </DashboardBoxContainer>
  );
};

export default BayCountFilter;
