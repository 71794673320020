import {
  Alert,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "../../../features/authentication/contexts/CognitoProvider";
import React, { useEffect, useState } from "react";

// icon
import Groups2Icon from "@mui/icons-material/Groups2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { urStorePalette } from "../../../themes/urStoreTheme";

export default function NoTeams() {
  const [inviteCode, setInviteCode] = useState<string>("");
  const [showInviteCopiedAlert, setShowInviteCopiedAlert] = useState<boolean>(false);

  const handleCloseCopiedAlert = ( event?: React.SyntheticEvent | Event, reason?: string ) => {
    if (reason === "clickaway") return; // prevents closing the component when users clicks outside it
    setShowInviteCopiedAlert(false);
  };

  const getInvite = async () => {
    await navigator.clipboard.writeText(inviteCode);
    setShowInviteCopiedAlert(true);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    // will get the username to be used for the invite code
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser()?.getUsername();
    if (cognitoUser !== undefined) setInviteCode(cognitoUser);
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Groups2Icon sx={{ fontSize: "5rem", color: urStorePalette.greys.default }} />
      </Grid>
      <Grid item>
        <Typography variant="h5" fontWeight="bold">
          Join Team
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h6"
          color={`${urStorePalette.greys.dark}`}
          fontWeight="bold"
          sx={{ maxWidth: "30rem", textAlign: "center" }}
        >
          To get started, send this invite code to your URStore team lead.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          Invite Code
        </Typography>
      </Grid>

      <Grid item>
        <Paper sx={{ px: 5, py: 2 }} square={false} elevation={2}>
          <Grid
            container
            justifyContent="center"
            direction="row"
            spacing={3}
            alignItems="center"
          >
            <Grid item>
              <Paper
                elevation={0}
                sx={{ background: urStorePalette.greys.light, px: 3, py: 2, minWidth: "10rem" }}
              >
                <Typography variant="h6" color="primary">
                  {inviteCode}
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <IconButton aria-label="copy" onClick={() => getInvite()}>
                <ContentCopyIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              sx={{ mt: 5, fontSize: 18 }}
              endIcon={<RefreshIcon />}
              onClick={reloadPage}
              color="primary"
            >
              Reload
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={showInviteCopiedAlert}
        autoHideDuration={3000}
        onClose={handleCloseCopiedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseCopiedAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          The invite code has been copied successfully!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
