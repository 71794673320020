import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Fade, Stack } from "@mui/material";
import CaptureFixtureRowContainer from "./CaptureFixtureRowContainer";
import { CaptureBayAnnotation } from "../../../models/planograms/AnnotationTypes";
import { usePlanogramStore } from "../../../stores/snapshots/PlanogramStore";

/*
  Bays are (afaik) always taken portrait so we use these two values to ensure a roughly correct appearance
  that doesn't blow out the page layout.
*/
const BAY_CONTENT_HEIGHT: number = 560;
const BAY_CONTENT_ASPECT_RATIO: number = 0.71;

const CaptureBayItemCard = ({
  imageUrl,
  captureBayData,
  captureId,
  callUpdatedData,
}: {
  imageUrl: string;
  key: string;
  captureBayData: CaptureBayAnnotation | undefined;
  captureId: string | null;
  callUpdatedData: () => Promise<void>;
}) => {
  const { setCurrentBayTotalItems } = usePlanogramStore();

  useEffect(() => {
    // Calculate the total number of products in the bay and set it in the store
    // This will be used to disable the next/previous buttons when the user
    // is at the end or start of the bay
    const totalBayProducts = captureBayData?.CaptureFixtures.reduce(
      (acc, row) => acc + row.CapturePositions.length,
      0
    );
    setCurrentBayTotalItems(totalBayProducts || 0);
  }, [captureBayData]);

  const [bayImgLoaded, setBayImgLoaded] = useState<boolean>(false);

  return (
    <Card sx={{ display: "flex" }}>
      <CardContent sx={{ overflowX: "auto" }}>
        <Stack direction="row">
          <Fade in={bayImgLoaded}>
            <img
              alt="Bay"
              style={{
                width: BAY_CONTENT_HEIGHT * BAY_CONTENT_ASPECT_RATIO,
                height: BAY_CONTENT_HEIGHT,
                objectFit: "fill",
                padding: 1,
                borderRadius: "12px",
              }}
              onLoad={() => setBayImgLoaded(true)}
              src={imageUrl}
            />
          </Fade>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-end",
              pl: 1,
              pb: 1,
              mt: 3,
              width: "100vw",
              height: BAY_CONTENT_HEIGHT,
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            {captureBayData?.CaptureFixtures.map((row, index) => (
              <CaptureFixtureRowContainer
                key={`${row.FixtureCardinality}-${index}`}
                fixtureRowData={row}
                mainProductImage={imageUrl}
                captureId={captureId}
                bayIndex={captureBayData.BayIndex}
                callUpdatedData={callUpdatedData}
              />
            ))}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CaptureBayItemCard;
