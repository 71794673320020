import { SnackbarContent } from "notistack";
import { forwardRef } from "react";
import { blue } from "@mui/material/colors";
import { HourglassEmptyRounded } from "@mui/icons-material";
import { CustomSnackbarProps, SnackbarBase } from "./SnackbarBase";

const InfoSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    return (
      <SnackbarContent ref={ref} role="alert">
        <SnackbarBase
          backgroundColour={blue[50]}
          iconBackgroundColour={blue[100]}
          borderColour={blue[300]}
          messageColour={blue[700]}
          ctaColour={blue[900]}
          message={props.message}
          cta={props.cta ?? null}
          icon={<HourglassEmptyRounded color="info" />}
        />
      </SnackbarContent>
    );
  }
);

InfoSnackbar.displayName = "InfoSnackbar";

export default InfoSnackbar;
