import axiosInstance from "../axiosInstanceConfig";

const postAdminAddProductImage = async (
  productID: string,
  data: FormData,
  revisionId?: string,
  sideNumber?: number | string
) => {

  const params = new URLSearchParams();
  params.append("productId", productID);
  revisionId && params.append("revisionId", revisionId);
  sideNumber && params.append("sideNumber", sideNumber.toString());

  const response = await axiosInstance.post(
    `products/AdminAddProductImage?${params.toString()}`,
    data
  );
  if (response.status === 200) {
    return response.data;

  } else {
    throw new Error(
      "There was an error adding the product image. Please contact support."
    );
  }
};

export default postAdminAddProductImage;
