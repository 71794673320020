import axiosInstance from "../axiosInstanceConfig";

const postUpdateUserFullname = async (data:UpdateUserFullnameRequest) => {
  const response = await axiosInstance.post<string>(
    `user/UpdateUserFullname`, data
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("There was an error creating the team. Please contact support.");
  }
};

export type UpdateUserFullnameRequest = {
  FullName: string
}

export default postUpdateUserFullname;
