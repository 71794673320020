import { create } from "zustand";

export type FilterDates = {
  startDate: Date | null;
  endDate: Date| null;
};

export type DateFilterState = {
  startDate: Date | null;
  endDate: Date| null;
};


type DateFilterActions = {
  setStartDate: (_newStartDate: Date | null) => void;
  setEndDate: (_newEndDate: Date | null) => void;
  resetDates: () => void;
};

const initialState: DateFilterState = {
  startDate: null,
  endDate: null,
};

export const useDateFilterStore = create<DateFilterState & DateFilterActions>(
  (set) => ({
    ...initialState,
    setStartDate: (_newStartDate: Date | null) =>
      set((state) => ({ ...state, startDate: _newStartDate })),
    setEndDate: (_newEndDate: Date | null) =>
      set((state) => ({ ...state, endDate: _newEndDate })),
    resetDates: () => {
      set((state: DateFilterState) => ({ ...state, startDate: null, endDate: null }));
    },
  })
);
