import {
  Chip,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CapturePositionAnnotation } from "../../../../models/planograms/AnnotationTypes";
import {
  InfoOutlined,
} from "@mui/icons-material";
import { useContext } from "react";
import { RevisionContext } from "../../pages/PlanogramCapturesIndex";
import { grey } from "@mui/material/colors";

interface ProductComparisonHeaderTypes {
  trainingMode: boolean;
  loading: boolean;
  capturePosition: CapturePositionAnnotation;
  closeCompareProductComparisonDialog: (reason: string) => void;
}

export default function ProductComparisonHeader({
  trainingMode,
  loading,
  capturePosition,
  closeCompareProductComparisonDialog,
}: ProductComparisonHeaderTypes) {
  const context = useContext(RevisionContext);

  return (
    <DialogTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Stack direction="column">
          {loading ? (
            <Skeleton />
          ) : (
            <Typography variant="h6">{capturePosition.ProductName}</Typography>
          )}
          <Typography variant="subtitle2" sx={{ color: grey[600] }}>
            {`${capturePosition.ExpectedUpc}  | ${context?.revision.Planogram?.PlanogramCategory?.Name ?? '-'}`}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Chip
            color={trainingMode ? "error" : "primary"}
            label={!trainingMode ? "Annotation Correction" : "ML Training"}
            sx={{ width: "min-content" }}
          />
          <Tooltip title="Use keyboard left/right to cycle between products">
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={() => closeCompareProductComparisonDialog("iconClick")}
            aria-label="closeDialog"
          >
            <CloseRoundedIcon fontSize="medium" />
          </IconButton>
        </Stack>
      </Stack>
    </DialogTitle>
  );
}
