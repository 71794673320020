import { WidthFull } from "@mui/icons-material";
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

const TextInputDialog = (props: any) => {
    const { onClose, open, title, inputLabel, cancelText, enterText } = props;
    const [newInput, setNewInput] = useState<string>("");


    const handleValueEdited = (event: any) => {
        setNewInput(event.target.value);
    };

    const handleEnterText = () => {
        onClose(newInput);
    };

    const handleCancelPressed = () => {
        onClose(null);
    };

    return (<div>
    <Dialog fullWidth open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <TextField
            autoFocus
            margin="dense"
            id="value"
            label={inputLabel}
            onChange={handleValueEdited}
            type="text"
            fullWidth
            variant="standard"
            />
        </DialogContent>
        <DialogActions>
            <Button variant="text" color="error" onClick={handleCancelPressed}>{cancelText || "Cancel"}</Button>
            <Button variant="outlined" color="primary" onClick={handleEnterText}>{enterText || "Enter"}</Button>
        </DialogActions>
    </Dialog>
  </div>);
};

export default TextInputDialog;