import {
  Box,
  Button,
  Fade,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAuthContext } from "../../../features/authentication/contexts/AuthContext";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";
import AuthLayoutNew from "../layouts/AuthLayout";
import { LockOpen } from "@mui/icons-material";

function ActivateAccount() {
  const theme = useTheme();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const emailToVerify = searchParams.get("email"); // We'll need to configure a link in the email to include this query param

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const username = location?.state?.username || emailToVerify;
    const form = event.currentTarget;

    const formData = new FormData(form);
    const verificationCode = formData.get("verificationCode") as string;

    if (!verificationCode) {
      setError("Please enter a verification code");
      return;
    }

    auth.verify(username, verificationCode, (result: any) => {
      if (result === "SUCCESS") {
        navigate("/login");
      } else {
        setError("Invalid verification code.");
      }
    });
  }

  return (
    <AuthLayoutNew>
      <Fade in={true}>
        <Stack
          height={"100%"}
          alignItems={"center"}
          gap={4}
          justifyContent={"center"}
        >
          <Box padding={theme.spacing(8)}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              mb={8}
              fontWeight="600"
            >
              Activate Your Account
            </Typography>
            <Typography
              mb={4}
              align="center"
              color={urStorePalette.greys.darkest}
            >
              Please enter the verification code we emailed you:
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container gap={8}>
                <Grid item xs={12}>
                  <TextField
                    name="verificationCode"
                    label="Verification Code"
                    fullWidth
                  />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: "var(--brand-tertiary)" }}
                    startIcon={<LockOpen />}
                    fullWidth
                  >
                    Activate Account
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Grid container mt={4} color={urStorePalette.greys.dark}>
              <Grid item xs={12} textAlign="center">
                <Typography variant="body2" mt={2}>
                  Already activated? <Link to="/login">Log in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Fade>
    </AuthLayoutNew>
  );
}

export default ActivateAccount;
