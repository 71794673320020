import { SelectedProductDataTypes } from "../../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../../axiosInstanceConfig";

export const getProductImage = async (productId: string) => {
  const params = new URLSearchParams();
  params.append("productId", productId);

  try {
    const response = await axiosInstance.get<SelectedProductDataTypes>(
      `products/AdminGetProductImages?${params.toString()}`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "There was an error getting the image. Please contact support."
    );
  }
};
