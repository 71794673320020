import { Alert, Box, Button, Chip, Snackbar, Stack } from "@mui/material";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useState } from "react";
import getExports, { MLExport } from "../../../../api/ml/getExports";
import { ColDef } from "ag-grid-community";
import {
  AddCircleOutline,
  ContentCopyOutlined,
  Download,
  RefreshOutlined,
} from "@mui/icons-material";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import { RequestMLArchiveUtility } from "../../tools/components/RequestMLArchive";
import { useSnackbar } from "notistack";
import { ResponsiveContainerStyle } from "../../../../helpers/generalUtilities";

export const ExportsList = () => {
  // Util
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [colDef] = useState<ColDef<MLExport>[]>([
    {
      field: "Id",
      flex: 0.5,
    },
    {
      field: "Category",
      flex: 1,
      cellRenderer: (_data: any) => (
        <Chip label={_data.value ?? "Unspecified Category"} size="small" />
      ),
    },
    {
      field: "CreatedAt",
      flex: 1,
      cellRenderer: (_data: any) => formatDisplayDate(_data.value),
      sort: "desc",
    },
    {
      field: "DownloadURL",
      headerName: "Actions",
      flex: 1,
      cellRenderer: (_data: any) => (
        <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<Download />}
            onClick={(_e) => window.open(_data.value, "_blank")}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ContentCopyOutlined />}
            onClick={() => {
              setCopySnackOpen(true);
              navigator.clipboard.writeText(_data.value);
            }}
          >
            Copy URL
          </Button>
        </Stack>
      ),
    },
  ]);

  const [exports, setExports] = useState<MLExport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [copySnackOpen, setCopySnackOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    fetchExports();
  }, []);

  async function fetchExports() {
    try {
      setLoading(true);
      const data = await getExports();
      setExports(data);
    } catch {
      setErrorSnackOpen(true);
    } finally {
      setLoading(false);
    }
  }

  const GridPlugins = () => {
    return (
      <Stack direction="row" alignItems={"center"} gap={2}>
        <Button
          variant="contained"
          startIcon={<RefreshOutlined />}
          onClick={async () => await fetchExports()}
        >
          Reload
        </Button>
        <Button
          variant="contained"
          startIcon={<AddCircleOutline />}
          onClick={() => setRequestModalOpen(true)}
        >
          New Export
        </Button>
      </Stack>
    );
  };

  // Render
  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Error Snack */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={errorSnackOpen}
        autoHideDuration={3000}
        onClose={() => setErrorSnackOpen(false)}
      >
        <Alert severity="error">
          Could not retrieve Export list. Please contact support.
        </Alert>
      </Snackbar>

      {/* Copy Snack */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={copySnackOpen}
        autoHideDuration={3000}
        onClose={() => setCopySnackOpen(false)}
      >
        <Alert severity="info">Download URL Copied to clipboard.</Alert>
      </Snackbar>

      {/* Export Request Modal */}
      <RequestMLArchiveUtility
        modal
        modalOnClose={() => setRequestModalOpen(false)}
        modalOpen={requestModalOpen}
      />

      {/* Grid */}
      <AgGridWrapper
        id="export-list"
        columnDefs={colDef}
        rowData={exports}
        loading={loading}
        quickSearch
        export
        rememberPageNumber
        plugins={<GridPlugins />}
      />
    </Box>
  );
};
