import axiosInstance from "../axiosInstanceConfig";

/**
 * @deprecated This endpoint is unmaintained and may no longer function correctly, and does not have typing.
 * @returns A list of ML Models used by URStore.
 */
const postAdminCreateAsset = async (data:AddUserToTeamRequest) => {
  const response = await axiosInstance.post(
    `asset/AdminCreateAsset`, data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error creating the asset. Please contact support.");
  }
};

export type AddUserToTeamRequest = {
  FileName: string
  AssetType: string
  Filesize: string | number
}

export default postAdminCreateAsset;
