import axiosInstance from "../axiosInstanceConfig";

const postCreateTeam = async (data:CreateTeamRequest) => {
  const response = await axiosInstance.post<string>(
    `user/CreateTeam`, data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error creating the team. Please contact support.");
  }
};

export type CreateTeamRequest = {
  Name: string
}

export default postCreateTeam;
