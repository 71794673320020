import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { VoidFunction } from '../../../types';

interface AmazonCognitoProvider {
  isAuthenticated: boolean;
  cognitoUser: AmazonCognitoIdentity.CognitoUser | null;
  signin(_username: string, _password: string, _callback: any): void;
  signout(_callback: VoidFunction): void;
  signup(_username: string, _password: string, _callback: any): void;
  forgotPassword(_username: string, _callback: any): void;
  confirmPassword(_username: string, _verificationCode: string, _newPassword: string, _callback: any): void;
  verify(_username: string, _code: string, _callback: any): void;
  resendVerificationCode(_username: string, _callback: any): void;
  getAccessToken(): Promise<CognitoAccessToken | null>;
  changePassword(_oldPassword: string, _newPassword: string, _callback: any): void;
}

const cognitoUserPoolId = process.env.REACT_APP_AMAZON_COGNITO_USER_POOL_ID!;
const cognitoClientId = process.env.REACT_APP_AMAZON_COGNITO_CLIENT_ID!;

export const poolData = {
  UserPoolId: cognitoUserPoolId,
  ClientId: cognitoClientId,
};


const CognitoProvider: AmazonCognitoProvider = {
  isAuthenticated: false,
  cognitoUser: null,

  signin(username: string, password: string, callback: any) {
    var authenticationData = {
      Username: username,
      Password: password,
    };
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username: username,
      Pool: userPool,
    };
    CognitoProvider.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    CognitoProvider.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result: any) {
        var accessToken = result.getAccessToken().getJwtToken();

        CognitoProvider.isAuthenticated = true;
        callback({
          success: true,
          data: {
            token: accessToken
          }
        });
      },

      onFailure: function(err: any) {
        console.error(err.message || JSON.stringify(err));
        
        callback({
          success: false,
          data: {
            error: err
          }
        });
      },
    });
  },

  signout(callback: VoidFunction) {
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    
    userPool.getCurrentUser()?.signOut(() => {
      CognitoProvider.isAuthenticated = false;
      callback();
    });
  },

  /**
   * Register a new, unactivated user
   */
  signup(username: string, password: string, callback : any) {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
   
    const attributeList = [];
    
    const dataEmail = {
      Name: 'email',
      Value: username,
    };
    
    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
    
    attributeList.push(attributeEmail);
    
    
    userPool.signUp(username, password, attributeList, [], function( err, result ) {
      if (err) {
        callback({ success: false, data: { err } });
        return;
      }

      const cognitoUser = result?.user;
      console.log('Username is: ' + cognitoUser?.getUsername());
      callback( { success: true, data: result });
    });
  },

  forgotPassword(username: string | null, callback : any) {
    var localCognitoUser = CognitoProvider.cognitoUser;
    if (localCognitoUser == null && username != null) {
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      localCognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    }

    // call forgotPassword on cognitoUser
    localCognitoUser?.forgotPassword({
        onSuccess: function(result) {
            console.log('call result: ' + result);
            callback({
              success: true
            });
        },
        onFailure: function(err) {
            callback({
              success: false
            });
        }
    });
  },

  confirmPassword(username: string, verificationCode: string, newPassword: string, callback: any) {
    var localCognitoUser = CognitoProvider.cognitoUser;
    if (localCognitoUser == null) {
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      var userData = {
        Username: username,
        Pool: userPool,
      };
      localCognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    }

    localCognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: function(result) {
          console.log('call result: ' + result);
          callback({
            success: true
          });
      },
      onFailure: function(err) {
          alert(err);
          callback({
            success: false
          });
      }
  });
  },

  /**
   * Activate the account with a confirmation code that gets sent to the user's email address
   */
  verify(username: string, code: string, callback: any) {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, function(err, result) {
      if (err) {
        alert(err!.message || JSON.stringify(err));
        return;
      }
      console.log('Activation result: ' + result);
      callback(result);
    });
  },



  getAccessToken(): Promise<CognitoAccessToken | null> {
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      if(currentUser === null){
        reject();
        return;
      }

      currentUser.getSession(function(err: Error, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
          return;
        }
  
        if (session.isValid()) {
          resolve(session.getAccessToken().getJwtToken());
        }
      });
    });
  },

  /**
   * Resend Verification Code
   */
  resendVerificationCode(username: string, callback: any) {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.resendConfirmationCode(() => {});
  },

  /*
  Change password 
  (This is NOT the same as the "reset password" flow. This is user-elected while signed in.)
  */
 changePassword(oldPassword: string, newPassword: string, callback: any){
  // Get current user + handle null user.
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const currentUser = userPool.getCurrentUser();

  if (currentUser === null) {
    console.error("CognitoProvider current user is null");
    callback(false);
    return;
  }

  // Get active session, if session valid, change password.
  currentUser.getSession(function (err: Error, session: any) {
    if (err) {
      console.log(err);
      alert(err.message || JSON.stringify(err));
      callback(false);
      return;
    }

    if (session.isValid()) {
      currentUser.changePassword(oldPassword, newPassword, (err, result) => {
        // Float response from server to client.
        if (err) {
          console.error(err);
          callback(false);
          alert(err.message);
        } else {
          callback(true);
          alert("Password changed successfully.");
        }
      });
    }
  });
 }
};

export { CognitoProvider };
