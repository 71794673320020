import {
  HomeRounded,
  StoreRounded,
  GridViewRounded,
  ShoppingCartRounded,
  PeopleRounded,
  ConstructionRounded,
  SellRounded,
  FactCheckRounded,
} from "@mui/icons-material";

export type LayoutStoreState = {
  routeMetadata: NavItem | null; 
  subRoute: SubRoute | null;
  drawerOpen: boolean;
};

export type LayoutStoreActions = {
  setRouteMetadata: (_arg: NavItem | null) => void;
  setDrawerOpen: (_arg: boolean) => void;
  setSubRoute: (_arg: SubRoute | null) => void;
};

export interface SubRoute {
  title: string
}

export const iconLookup = {
  dashboard: <HomeRounded />,
  stores: <StoreRounded />,
  planograms: <GridViewRounded />,
  products: <ShoppingCartRounded />,
  team: <PeopleRounded />,
  exports: <ConstructionRounded />,
  categories: <SellRounded />,
  task: <FactCheckRounded/>
};

export const IconTags = [
  "dashboard",
  "stores",
  "products",
  "planograms",
  "team",
  "exports",
  "categories",
  "task"
] as const;

export interface NavItem {
  /**  The route label as it will be presented in the sidebar and page title. */
  label: string;
  /** The route path. */
  path: string;
  /** The Icon to be used. These should be rounded MUI variants. */
  // TODO: Remove the need for the 'Icon' prop at all as its redundant somewhat.
  icon: JSX.Element;
  /** String alias for the icon being used. */
  iconTag: (typeof IconTags)[number];
  /** Determines the visual colour tinting of the icon. Does not affect route accesibility.. */
  admin: boolean;
  /** Determines if the route will show in the sidebar. */
  showInSidebar: boolean;
}
