
import { Box, Button, Container, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardListContainer from "../../../components/CardList/CardListContainer";
import ErrorDialog from "../../../components/ErrorDialog";
import PaperTitleContainer from "../../../components/PaperTitleContainer";
import { convertBytesToMBString } from "../../../helpers/generalUtilities";
import convertStringToLocale from "../../../helpers/StringUtilities";
import { CognitoProvider } from "../../authentication/contexts/CognitoProvider";
import getAdminListMLModels from "../../../api/assets/getAdminListMLModels";
import postAdminCreateAsset from "../../../api/assets/postAdminCreateAsset";

class MLModel {
    constructor(
        public id: string,
        public fileName: string,
        public fileSize: number,
        public dateUploaded: string,
        public status: string
    ) {}
}

/*
  This page is DEPRECATED and no longer used.
  It is being kept in case a need for it re-emerges.
*/
const AdminMachineLearningModelIndex = (props: any) => {
    const [mlModels, setMLModels] = useState<MLModel[]>([]);
    const [newMLModel, setNewMLModel] = useState<any | null>(null);
    const [newMLModelType, setNewMLModelType] = useState<string>("");
    const [uploadError, setUploadError] = useState<string>("");
    const [modelsUpToDate, setModelsUpToDate] = useState<Boolean>(false);
    const [pageError, setPageError] = useState<string>("");


    var clearFileInputFunc: Function;

    const modelTypes = [
        "mlmodel_objectdetection",
        "mlmodel_similarity_dental",
        "mlmodel_similarity_haircare",
        "mlmodel_similarity_mixed",
        "mlmodel_gap"
    ];

    useEffect(() => {

        if (modelsUpToDate) {
            return;
        }
        const fetchMLModels = async () => {
          try {
            const data = await getAdminListMLModels();
            if(data) {
              const sortedModels = data;
              sortedModels.sort((a: any, b: any) => (a.CreatedAt > b.CreatedAt) ? -1 : 1);
              setMLModels(sortedModels.map((model: any) => {
                  if (model.AssetId) {
                      return new MLModel(model.AssetId, model.FileName, model.Filesize, model.CreatedAt, model.Status);
                  }
              }));
              setModelsUpToDate(true);
            }
          } catch (error:any) {
            setPageError(error.message);
            console.log(error.message);
          }
        };
        fetchMLModels();
    }, [modelsUpToDate]);
    


    useEffect(() => {
        // Set errorMessage only if text is equal or bigger than MAX_LENGTH
        if (newMLModel !== null && newMLModel !== undefined) {
            setUploadError(
                "You must submit a model"
            );
        } else {
            setUploadError("");
        }
    }, [newMLModel]);

    const onModelAdded = (e: any) => {
        const model = e.target.files[0];
        if (model !== null && model !== undefined) {
            setNewMLModel(model);
        } else {
            setNewMLModel(null);
        }
    };

    const clearUpload = () => {
        setNewMLModel(null);
    };
    
    async function uploadNewMLModel() {
      if (
        newMLModel === null ||
        newMLModel.name === undefined ||
        newMLModel.size === undefined ||
        newMLModelType.length == 0
      ) {
        return;
      }
      const requestData = {
        FileName: newMLModel.name,
        AssetType: newMLModelType,
        Filesize: newMLModel.size,
      };

      try {
        const data = await postAdminCreateAsset(requestData);
        if (data) {
          setModelsUpToDate(false);

          let signedUrl = data.SignedUrl;
          if (signedUrl && signedUrl !== null && signedUrl.length > 0) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const fileBytes = event?.target?.result;
              if (fileBytes) {
                fetch(signedUrl, {
                  method: "PUT",
                  body: fileBytes,
                })
                  .then((response) => {
                    setModelsUpToDate(false);
                    setNewMLModel(null);
                    clearFileInputFunc();
                  })
                  .catch((err) => {
                    setPageError(err.message);
                    console.log(err.message);
                  });
              }
            };
            reader.onerror = (err) => {
              console.log(err);
            };
            reader.readAsArrayBuffer(newMLModel);
          }
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      }
    }

    function onCloseErrorDialog() {
        setPageError("");
    }

    function setClearFileFunction(func: Function) {
        clearFileInputFunc = func;
    }

    function handleMLModelTypeEdited(event: SelectChangeEvent<string>) {
        if (event.target.value && event.target.value !== null) {
            setNewMLModelType(event.target.value);
        }
    }

    return (
      <Container sx={{ padding: 0, margin: 0 }}>
        <PaperTitleContainer title="Manage Machine Learning Model">
          <Stack spacing={8}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                alignContent="stretch"
                ml={8}
                width="100%"
              >
                <InputLabel id="modelTypeLabel">Model Type</InputLabel>
                <Select
                  labelId="modelTypeLabel"
                  id="modelType"
                  fullWidth
                  value={newMLModelType}
                  onChange={handleMLModelTypeEdited}
                >
                  {modelTypes.map((mlType) => (
                    <MenuItem key={mlType} value={mlType}>
                      {mlType}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignContent="center">
              <Button
                variant="outlined"
                style={{ padding: 20 }}
                disabled={newMLModel === null || newMLModelType.length === 0}
                onClick={uploadNewMLModel}
              >
                Upload new ML Model
              </Button>
            </Box>
          </Stack>
          <ErrorDialog
            errorMessage={pageError}
            open={pageError.length > 0}
            onClose={onCloseErrorDialog}
          />
        </PaperTitleContainer>
      </Container>
    );
};

export default AdminMachineLearningModelIndex;