import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import TextInputDialog from "../../../components/TextInputDialog";
import getUserTeams from "../../../api/teams/getUserTeams";
import postCreateTeam, {
  CreateTeamRequest,
} from "../../../api/teams/postCreateTeam";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { UrStoreTeam, useTeamStore } from "../../../stores/teams/TeamStore";
import { ColDef } from "ag-grid-community";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { useSnackbar } from "notistack";
import { AddCircleOutline } from "@mui/icons-material";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

function MyTeamsIndex(props: any) {
  // Const
  const teamID = props.selectedTeam || null;

  // Util
  const { enqueueSnackbar } = useSnackbar();

  // Store
  const { fetchTeams } = useTeamStore();

  // State
  const [teams, setTeams] = useState<UrStoreTeam[]>([]);
  const [addNewTeam, setAddNewTeam] = useState<boolean>(false);
  const [teamsUpToDate, setTeamsUpToDate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // ColDefs
  const [colDef] = useState<ColDef<UrStoreTeam>[]>([
    { field: "Name" },
    { field: "Role" },
    {
      field: "JoinedTeam",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "TeamCreatedAt",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "TeamLastModified",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
  ]);

  // Effects
  useEffect(() => {
    const fetchUserTeams = async () => {
      if (teamID === null) {
        setTeams([]);
        return;
      }

      if (!teamsUpToDate) {
        setLoading(true);
        try {
          const data = await getUserTeams();
          if (data) {
            setTeams(data);
            setTeamsUpToDate(true);
            setLoading(false);
          }
        } catch (error: any) {
          setLoading(false);
          enqueueSnackbar("Error", {
            variant: "error",
            cta: "Failed to load teams.",
          });
          console.log(error.message);
        }
      }
    };

    fetchUserTeams();
  }, [teamsUpToDate, teamID]);

  function openAddTeamDialog() {
    setAddNewTeam(true);
  }

  async function createNewTeam(newTeamName: string | null) {
    setAddNewTeam(false);

    if (!newTeamName) {
      return;
    }

    const postBody: CreateTeamRequest = { Name: newTeamName };

    try {
      const data = await postCreateTeam(postBody);
      if (data) {
        enqueueSnackbar("Success", {
          variant: "success",
          cta: `'${newTeamName}' was created.`,
        });
        setTeamsUpToDate(false);
        fetchTeams(false);
      }
    } catch (error: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create team.",
      });
      console.log(error.message);
    }
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      <TextInputDialog
        onClose={createNewTeam}
        open={addNewTeam}
        title="Create new team"
        inputLabel="Name"
        enterText="Create Team"
      />
      <AgGridWrapper
        quickSearch
        loading={loading}
        id="teams"
        columnDefs={colDef}
        rowData={teams}
        plugins={
          <Button
            startIcon={<AddCircleOutline />}
            variant="contained"
            onClick={openAddTeamDialog}
          >
            Add New Team
          </Button>
        }
      />
    </Box>
  );
}

export default MyTeamsIndex;
