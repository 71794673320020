import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Circle,
  FastForwardOutlined,
  FastRewindOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  MoreVert,
  OpenInBrowserOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import exportCaptureDataByCaptureId from "../../../api/planograms/exportCaptureDataByCaptureId";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";
import getPlanogramList from "../../../api/planograms/getPlanogramsList";
import { SnapshotUploader } from "../components/SnapshotUploader";
import { ColDef } from "ag-grid-community";
import { DisplayCapture } from "../hooks/usePaginatedCaptureData";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { useSnackbar } from "notistack";
import { CheckAdminAuth } from "../../authentication/contexts/AuthContext";
import ActionsMenu from "../components/ActionsMenu";

interface DisplayCaptureColumn extends DisplayCapture {
  Actions?: null;
}

const PlanogramsListPage = (props: any) => {
  // Util
  const navigate = useNavigate();
  const location = useLocation();
  const { downloadCSV } = useCSVDownloader();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const teamID = props.selectedTeam || null;
  const [allCaptures, setAllCaptures] = useState<any[]>([]);
  const [page, setPage] = useState<number>(() => {
    const searchParams = new URLSearchParams(location.search);
    return parseInt(searchParams.get("page") || "0", 10);
  });
  const [morePagesAvailable, setMorePagesAvailable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snapshotUploaderOpen, setSnapshotUploaderOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getAccuracyColour = (value: number) => {
    if (value * 100 < 50) return "warning";
    if (value * 100 >= 50) {
      if (value === 1) {
        return "info";
      } else {
        return "success";
      }
    }
  };

  // AG State
  const [colDefs] = useState<ColDef<DisplayCaptureColumn>[]>([
    { field: "PlanogramName", flex: 1.5 },
    { field: "TaskName" },
    {
      field: "Accuracy",
      flex: 1,
      hide: !CheckAdminAuth(),
      cellRenderer: (data: any) => (
        <Chip
          size="small"
          label={`${(data.value * 100).toFixed(0)}%`}
          icon={<Circle color={getAccuracyColour(data.value)} />}
        />
      ),
    },
    { field: "Store", flex: 1 },
    { field: "NumberOfBays", headerName: "Bays", flex: 0.5 },
    { field: "UserName", flex: 1 },
    {
      field: "CreatedAt",
      flex: 1,
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "Status",
      flex: 1,
      cellRenderer: (data: any) => {
        const statusLabel = getStatusLabel(data.value);

        return (
          <Chip
            icon={<Circle color={statusLabel.severity} />}
            label={statusLabel.label}
            size="small"
          />
        );
      },
    },
    {
      field: "Actions",

      sortable: false,
      flex: 1,
      cellRenderer: (row: any) => (
        <ActionsMenu
          rowData={row.data}
          handlePlanogramSelected={handlePlanogramSelected}
        />
      ),
    },
  ]);

  useEffect(() => {
    async function fetchPlanograms() {
      try {
        setLoading(true);
        const data = await getPlanogramList(teamID, page);
        navigate(`?page=${page}`, { replace: true });

        if (data) {
          setAllCaptures(data.items);
          setMorePagesAvailable(data.more);
        }
      } catch {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Planograms failed to load",
          preventDuplicate: true,
        });
        setAllCaptures([]);
        setMorePagesAvailable(false);
      } finally {
        setLoading(false);
      }
    }

    fetchPlanograms();
  }, [teamID, page]);

  function handlePlanogramSelected(planogramId: string) {
    navigate(`captures?capture=${planogramId}`, { state: { page: page } });
  }

  function getStatusLabel(status: string): {
    label: string;
    severity: "error" | "success" | "warning";
  } {
    switch (status) {
      case "duplicate":
        return { label: "Duplicate", severity: "error" };
      case "outofdate":
        return { label: "Out of Date", severity: "error" };
      case "pending":
        return { label: "Pending", severity: "warning" };
      case "processing":
        return { label: "Processing", severity: "warning" };
      case "complete":
        return { label: "Complete", severity: "success" };
      default:
        return { label: "Unknown", severity: "error" };
    }
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Add Snapshot Modal */}
      <SnapshotUploader
        open={snapshotUploaderOpen}
        handleClose={() => setSnapshotUploaderOpen(false)}
      />

      {/* Table */}
      <AgGridWrapper
        id="plano"
        columnDefs={colDefs}
        rowData={allCaptures}
        loading={loading}
        rememberPageNumber
        hidePagination
        plugins={
          <Stack direction="row" alignItems={"center"} gap={4}>
            <Typography variant="subtitle2">
              Page: <b> {page + 1} </b>
            </Typography>
            <Tooltip
              title={`One page of captures are loaded at a time. Use these controls to load the newer or older results.`}
            >
              <ButtonGroup sx={{ mb: 0 }}>
                <Button
                  disabled={page === 0}
                  variant="contained"
                  startIcon={<FastRewindOutlined />}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={morePagesAvailable === false}
                  variant="contained"
                  endIcon={<FastForwardOutlined />}
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </Button>
              </ButtonGroup>
            </Tooltip>
            <Button
              variant="contained"
              onClick={() => setSnapshotUploaderOpen(true)}
              startIcon={<UploadFileOutlined />}
            >
              Upload Snapshot
            </Button>
          </Stack>
        }
        quickSearch
        export
      />
    </Box>
  );
};

export default PlanogramsListPage;
