import {
  HomeRounded,
  StoreRounded,
  GridViewRounded,
  ShoppingCartRounded,
  PeopleRounded,
  ConstructionRounded,
  SellRounded,
  FactCheckRounded,
} from "@mui/icons-material";
import { NavItem } from "../../../stores/layout/LayoutStoreTypes";

export const navItems: NavItem[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <HomeRounded />,
    iconTag: "dashboard",
    admin: false,
    showInSidebar: true,
  },
  {
    label: "Stores",
    path: "/stores",
    icon: <StoreRounded />,
    iconTag: "stores",
    admin: false,
    showInSidebar: true,
  },
  {
    label: "Snapshots",
    path: "/planograms",
    icon: <GridViewRounded />,
    iconTag: "planograms",
    admin: false,
    showInSidebar: true,
  },
  {
    label: "Products",
    path: "/products",
    icon: <ShoppingCartRounded />,
    iconTag: "products",
    admin: false,
    showInSidebar: true,
  },
  {
    label: "My Team",
    path: "/team",
    icon: <PeopleRounded />,
    iconTag: "team",
    admin: false,
    showInSidebar: true,
  },
  {
    label: "My Teams",
    path: "/my-teams",
    icon: <PeopleRounded />,
    iconTag: "team",
    admin: false,
    showInSidebar: false,
  },
  {
    label: "Jobs",
    path: "/jobs",
    iconTag: "task",
    icon: <FactCheckRounded />,
    admin: false,
    showInSidebar: true,
  },
];

export const adminNavItems: NavItem[] = [
  {
    label: "Manage Planograms",
    path: "/admin/planograms",
    icon: <GridViewRounded />,
    iconTag: "planograms",
    admin: true,
    showInSidebar: true,
  },
  {
    label: "Manage Products",
    path: "/admin/products",
    icon: <ShoppingCartRounded />,
    iconTag: "products",
    admin: true,
    showInSidebar: true,
  },
  // hidden until further notice.
  // {
  //   label: "ML Models",
  //   path: "admin/mlModels",
  //   icon: <CpuChipIcon className={styles.mainNav__icon} />,
  // },
  {
    label: "Manage Stores",
    path: "/admin/stores",
    icon: <StoreRounded />,
    iconTag: "stores",
    admin: true,
    showInSidebar: true,
  },
  // Hidden until more tools needed.
  // {
  //   label: "Admin Utilities",
  //   path: "/admin/tools",
  //   icon: <WrenchScrewdriverIcon className={styles.mainNav__icon} />,
  // },
  {
    label: "ML Exports",
    path: "/admin/exports",
    icon: <ConstructionRounded />,
    iconTag: "exports",
    admin: true,
    showInSidebar: true,
  },
  {
    label: "Planogram Categories",
    path: "/admin/categories",
    icon: <SellRounded />,
    iconTag: "categories",
    admin: true,
    showInSidebar: true,
  },
];
