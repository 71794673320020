import React from "react";
import { Add, UploadFile } from "@mui/icons-material";
import { Button } from "@mui/material";

interface AddImageButtonProps {
  onFileDrop: (file: File | File[]) => void;
  disabled?: boolean;
}

const AddImageButton = (props: AddImageButtonProps) => {
  const acceptedFileTypes = [".png"];

  const handleFile = (file: File) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
    const lowerCaseAcceptedFileTypes = acceptedFileTypes.map((type) =>
      type.toLowerCase()
    );
    if (
      lowerCaseAcceptedFileTypes.includes("." + fileExtension) ||
      lowerCaseAcceptedFileTypes.includes(file.type.toLowerCase())
    ) {
      props.onFileDrop(file);
    } else {
      console.error("Invalid file type.");
    }
  };

  const handleClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = acceptedFileTypes.join(",");
    fileInput.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        handleFile(file as File);
      }
    };
    fileInput.click();
  };

  return (
    <Button
      disabled={props.disabled}
      component="label"
      role={undefined}
      variant="contained"
      onClick={handleClick}
      startIcon={<UploadFile />}
    >
      Select Image
    </Button>
  );
};

export default AddImageButton;
