import {
  Alert,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ListProduct } from "../../../api/products/getProducts";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ProductImg } from "./ProductImg";
import { CloseOutlined, RefreshOutlined } from "@mui/icons-material";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import postUpdateProductSKUCode from "../../../api/products/postUpdateProductSKUCode";

interface ProductModalProps {
  open: boolean;
  onClose: () => void;
  listProduct: ListProduct | null;
}

/*
  Modal appropriate for viewing properties of a single product and allowing SKU Code updating.
*/
export const ProductModal = (props: ProductModalProps) => {
  // Util
  const { roleOnTeam, selectedTeam } = useTeamStore();

  // State
  const [customSku, setCustomSku] = useState<string | null>(
    props.listProduct?.SKUCode ?? null
  );
  const [skuUpdateLoading, setSkuUpdateLoading] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackProps, setSnackProps] = useState<{
    color: "success" | "error";
    text: string;
  }>({ color: "success", text: "SKU Updated" });

  // Effect
  useEffect(() => {
    // Handles customSKU set & reset.
    if (props.open) {
      setCustomSku(props.listProduct?.SKUCode ?? null);
    } else {
      setCustomSku(null);
    }
  }, [props.open]);

  // Func
  async function handleSkuUpdate() {
    setSkuUpdateLoading(true);

    // API will accept `null`s as a stand-in for removing a custom SKU. This just flattens a '' into a null.
    const transformedSKU = customSku === "" ? null : customSku;

    const resp = await postUpdateProductSKUCode(
      selectedTeam,
      transformedSKU,
      props.listProduct?.ProductId!
    );

    // Snack management
    if (resp.name !== "AxiosError") {
      setSnackOpen(true);
      setSnackProps({ color: "success", text: "SKU Updated" });
    } else {
      setSnackOpen(true);
      setSnackProps({
        color: "error",
        text:
          resp.response.data.detail ??
          "SKU Failed to update. Please contact support.",
      });
    }

    setSkuUpdateLoading(false);
  }

  return (
    <>
      {/* Alert */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
      >
        <Alert color={snackProps.color}>{snackProps.text}</Alert>
      </Snackbar>

      {/* Dialog */}
      <Dialog
        open={props.open}
        onClose={(e, reason) => {
          if (reason) props.onClose();
        }}
      >
        <DialogTitle>
          {/* Title Bar + Close Control */}
          <Stack direction="row" gap={4} justifyContent={"space-between"}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: 12, fontWeight: 500 }}
              >
                {props.listProduct?.Name ?? "Unnamed Product"}
              </Typography>

              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {props.listProduct?.UPC ?? "Unknown UPC"}
              </Typography>
            </Box>
            <IconButton
              sx={{ width: "40px", height: "40px", my: "auto" }}
              size="medium"
              onClick={props.onClose}
            >
              <CloseOutlined />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={4} width={"100%"}>
            <Card>
              {/* Product Image */}
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ProductImg
                    productId={props.listProduct?.ProductId!}
                    revisionId={props.listProduct?.RevisionId!}
                    defaultSize={320}
                    growSize={320}
                  />
                </Box>
              </CardContent>
            </Card>
            {/* SKU Update Controls */}
            <Stack direction="row" gap={4}>
              <TextField
                label="SKU Code"
                value={customSku ?? ""}
                onChange={(e) => setCustomSku(e.target.value)}
                fullWidth
                size="small"
                disabled={roleOnTeam !== "admin" || skuUpdateLoading}
              />
              <LoadingButton
                loading={skuUpdateLoading}
                variant="contained"
                startIcon={<RefreshOutlined />}
                disabled={roleOnTeam !== "admin"}
                onClick={handleSkuUpdate}
              >
                Update
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
