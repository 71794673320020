import { UpdateCapturePositionStatusRequestData } from "../../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../../axiosInstanceConfig";

const postUpdateCapturePositionStatus = async (
  data: UpdateCapturePositionStatusRequestData
) => {
  try {
    const response = await axiosInstance.post<boolean>(
      `capture/UpdateCapturePositionStatus`,
      data
    );

    return response.data;
    
  } catch (error) {
    throw new Error(
      "There was an error updating the capture position status. Please contact support."
    );
  }
};

export default postUpdateCapturePositionStatus;