import { createContext, useState, useContext, useEffect } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";


import { Navigate, useLocation } from "react-router-dom";
import { CognitoProvider, poolData } from "./CognitoProvider";
import { VoidFunction } from "../../../types";
import { AppLayout } from "../../../layouts/AppLayout/AppLayout";
import { useSessionStore } from "../../../stores/auth/SessionStore";

interface AuthContextType {
  user: any;
  adminRole: any;
  setUser: (_username: string) => void;
  setAdminRole: (_adminRole: boolean) => void;
  signin: (_username: string, _password: string, _callback: any) => void;
  signout: (_callback: VoidFunction) => void;
  logoutAllTabs: () => void;
  signup: (_username: string, _password: string, _callback: any) => void;
  forgotPassword: (_username: string, _callback: any) => void;
  confirmPassword(
    _username: string,
    _verificationCode: string,
    _newPassword: string,
    _callback: any
  ): void;
  verify: (_username: string, _code: string, _callback: any) => void;
  resendVerification: (_username: string, _callback: any) => void;
}

let AuthContext = createContext<AuthContextType>(null!);

export const signOutChannel = new BroadcastChannel('SIGNOUT_CHANNEL');

export function AuthProvider({ children }: { children: React.ReactNode }) {
  // Util
  let location = useLocation();
  

  // State
  let [user, setLocalUser] = useState<any>(null);
  let [adminRole, setAdminRole] = useState<boolean>(false);
  let [initializing, setInitializing] = useState<boolean>(true);

  // Store
  const { setUser } = useSessionStore();

  useEffect(() => {
    if (user === null || adminRole === null) {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
      // Attempt to get the user from the session. If they're not logged in,
      var cognitoUser = userPool.getCurrentUser();

      if (cognitoUser !== null) {
        // NOTE: getSession must be called to authenticate user before calling getUserAttributes
        cognitoUser.getSession((err: any, session: any) => {
          if (
            session &&
            session.idToken &&
            session.idToken.payload &&
            session.idToken.payload["cognito:groups"] !== undefined &&
            (session.idToken.payload["cognito:groups"].includes("admin") ||
              session.idToken.payload["cognito:groups"].includes("adminreadonly") ||
              (process.env.REACT_APP_ENV === "DEV"
                && session.idToken.payload["cognito:groups"].includes("devadmin")))
          ) {
            setAdminRole(true);
          }

          if (err) {
            alert(err.message || JSON.stringify(err));
            setInitializing(false);
            return <Navigate to="/login" state={{ from: location }} replace />;
          }

          cognitoUser!.getUserAttributes((err: any, attributes: any) => {
            if (err) {
              // Handle error
              console.error(err);
              setInitializing(false);
              return (
                <Navigate to="/login" state={{ from: location }} replace />
              );
            } else {
              attributes.forEach((attribute: any) => {
                switch (attribute.Name) {
                  case "email":
                    setLocalUser(attribute.Value);
                    break;
                }
              });
              setInitializing(false);
              return;
            }
          });
        });
      } else {
        setInitializing(false);
      }
    } else {
      setInitializing(false);
    }
  }, [location, user]);

  const signin = async (username: string, password: string, callback: any) => {
    return CognitoProvider.signin(
      username,
      password,
      (values: { success: boolean; data: any }) => {
        if (values.success) {
          // Set local-scoped user.
          setLocalUser(username);

          // Additionally, send information about the new session to the SessionStore.
          const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          var cognitoUser = userPool.getCurrentUser();

          if (cognitoUser) {
            cognitoUser.getSession((err: any, session: any) => {
              setUser({
                email: session?.idToken?.payload["email"],
                token: session?.idToken?.["jwtToken"],
                roles: session?.idToken?.payload["cognito:groups"],
              });
            });
          }

          callback(values.success, values.data);
        }

        // If the call wasn't successful, callback with the entire response data.
        callback(values.success, values.data);
      }
    );
  };

  const signout = async (callback?: VoidFunction) => {
    return await CognitoProvider.signout(() => {
      signOutChannel.postMessage('SIGNOUT');
      setLocalUser(null);
      callback && callback();
    });
  };

  function logoutAllTabs(){
    signout();
    signOutChannel.close();
  }

  const signup = async (username: string, password: string, callback: any) => {
    return await CognitoProvider.signup(username, password, (result: any) => {
      setLocalUser(username);
      callback(result);
    });
  };

  const forgotPassword = async (username: string, callback: any) => {
    return await CognitoProvider.forgotPassword(username, (result: any) => {
      callback(result);
    });
  };

  const confirmPassword = async (
    username: string,
    verificationCode: string,
    newPassword: string,
    callback: any
  ) => {
    return await CognitoProvider.confirmPassword(
      username,
      verificationCode,
      newPassword,
      (result: any) => {
        callback(result);
      }
    );
  };

  const verify = async (username: string, code: string, callback: any) => {
    return await CognitoProvider.verify(username, code, (result: any) => {
      setLocalUser(username);
      callback(result);
    });
  };

  const resendVerification = async (username: string, callback: any) => {
    // This is a void return at the moment, once called will dispatch an email to the user.
    return CognitoProvider.resendVerificationCode(username, () => { });
  };

  const value = {
    user,
    adminRole,
    signin,
    signout,
    logoutAllTabs,
    signup,
    forgotPassword,
    confirmPassword,
    verify,
    resendVerification,
    setUser: setLocalUser,
    setAdminRole,
  };

  return initializing ? (
    <AuthContext.Provider value={value}>
      {/* Render an empty provider here to prevent remount issues with AppLayout */}
    </AuthContext.Provider>
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
}


export function useAuthContext() {
  return useContext(AuthContext);
}

// Wrapper component that enforces an auth check before child components are rendered.
export function RequireAdminAuth({ children }: { children: any }) {
  let location = useLocation();

  if (CheckAdminAuth()) {
    return children;
  }

  // Redirect them to the /dashboard page, as the user could be already logged in.
  return <Navigate to="/dashboard" state={{ from: location }} replace />;
}

export const CheckAdminAuth = (): boolean => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  // Check if user has one of the admin roles on their token.
  const acceptedAdminRoles = ["admin", "devadmin", "adminreadonly"];
  let userIsAdmin = false;

  cognitoUser?.getSession((err: any, session: any) => {
    if (err) {
      console.error("Something went wrong fetching session from cognito.", err);
    } else {
      // If session token roles contains any of the admin roles, user is hopefully an admin.
      userIsAdmin = session?.idToken?.payload["cognito:groups"]?.some(
        (el: string) => acceptedAdminRoles.includes(el)
      );
    }
  });

  return userIsAdmin;
};

export const ChangePassword = async (
  oldPassword: string,
  newPassword: string,
  callback?: any
) => {
  return await CognitoProvider.changePassword(
    oldPassword,
    newPassword,
    (changeSuccessfully: boolean) => {
      callback && callback(changeSuccessfully);
    }
  );
};
