import axiosInstance from "../axiosInstanceConfig";

export interface SnapshotUploadUrlResponse {
  CaptureId: string;
  SignedUrl: string;
}

const putSnapshotToBucket = async (
  // This function expects a signed URL from the postRequestSnapshotUploadUrl function.
  url: string,
  file: File
) => {
  // This function uses fetch instead of axios due to axios adding unhelpful headers
  // that can break this function. Rather than making it a maintenance risk and forcing it to use Axios,
  // it uses base functions.
  await fetch(url, {
    method: "PUT",
    body: file,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw new Error("An error occurred when uploading to the server.");
    });
};

export default putSnapshotToBucket;
