import { Routes, Route } from "react-router-dom";
import { AppLayout } from "./layouts/AppLayout/AppLayout";
import { AuthProvider } from "./features/authentication/contexts/AuthContext";
import DashboardPage from "./features/dashboard/pages/DashboardPage";
import LoginPage from "./features/authentication/pages/Login";
import RegisterPage from "./features/authentication/pages/Register";
import ActivatePage from "./features/authentication/pages/ActivateAccount";
import { DefaultLayout } from "./layouts/DefaultLayout";
import {
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { urStoreTheme } from "./themes/urStoreTheme";
import AdminProductsListPage from "./features/admin/products/AdminProductsListPage";
import AdminProductEditIndex from "./features/admin/products/AdminProductsEditPage";
import MyTeamsIndex from "./features/team/pages/MyTeamsIndex";
import PlanogramsListPage from "./features/planograms/pages/PlanogramsListPage";
import StoresListPage from "./features/stores/pages/StoresListPage";
import ProfileIndex from "./features/profile/pages/ProfileIndex";
import AdminPlanogramsPage from "./features/admin/planograms/AdminPlanogramsPage";
import WelcomeIndex from "./features/welcome/WelcomeIndex";
import { RequireTeam } from "./components/RequireTeam";
import AdminPlanogramsEditPage from "./features/admin/planograms/AdminPlanogramsEditPage";
import TeamListPage from "./features/team/pages/TeamListPage";
import AdminMachineLearningModelIndex from "./features/admin/mlModel/AdminMachineLearningModelIndex";
import StoresEditIndex from "./features/stores/pages/StoresEditIndex";
import PlanogramCapturesIndex from "./features/planograms/pages/PlanogramCapturesIndex";
import TeamEditPage from "./features/team/pages/TeamEditPage";
import AdminStoresListPage from "./features/admin/stores/AdminStoresListPage";
import AdminStoresEditIndex from "./features/admin/stores/AdminStoresEditIndex";
import AdminStoresAssignPlanogramPage from "./features/admin/stores/AdminStoresAssignPlanogramPage";
import ResetPassword from "./features/authentication/pages/ResetPassword";
import LandingPage from "./features/authentication/pages/LandingPage";
import RequireAuthPageWrapper from "./features/authentication/components/RequireAuthPageWrapper";
import { useTeamStore } from "./stores/teams/TeamStore";
import { useSessionStore } from "./stores/auth/SessionStore";
import { ProductListPage } from "./features/products/pages/ProductListPage";
import { AdminToolsDashboard } from "./features/admin/tools/AdminToolsDashboard";
import { ExportsList } from "./features/admin/exports/pages/ExportsList";
import { SnackbarProvider } from "notistack";
import SuccessSnackbar from "./components/snackbars/SuccessSnackbar";
import ErrorSnackbar from "./components/snackbars/ErrorSnackbar";
import WarningSnackbar from "./components/snackbars/WarningSnackbar";
import { ListCategoriesPage } from "./features/admin/categories/pages/ListCategoriesPage";
import { TaskList } from "./features/tasks/pages/TaskList";
import InfoSnackbar from "./components/snackbars/InfoSnackbar";

declare module "notistack" {
  interface VariantOverrides {
    success: {
      cta?: string;
    };
    error: {
      cta?: string;
    };
    warning: {
      cta?: string;
    };
    info: {
      cta?: string;
    }
  }
}

export default function App() {
  const { selectedTeam } = useTeamStore();
  const { user } = useSessionStore();

  // TODO: duplicate func, handle seperately.
  function isUserAdmin(): boolean {
    let acceptedAdminRoles = ["admin", "adminreadonly"];
    process.env.REACT_APP_ENV === "DEV" && acceptedAdminRoles.push("devadmin");

    const userIsAdmin =
      user?.roles?.some((el: string) => acceptedAdminRoles.includes(el)) ??
      false;

    return userIsAdmin;
  }

  return (
    <div id="wrapper">
      <ThemeProvider theme={urStoreTheme}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          Components={{
            success: SuccessSnackbar,
            error: ErrorSnackbar,
            warning: WarningSnackbar,
            info: InfoSnackbar
          }}
          
          
          
        >
          <CssBaseline />

          <AuthProvider>
            {/* <h1>Auth Example</h1>

          <p>
            This example demonstrates a simple login flow with three pages: a public
            page, a protected page, and a login page. In order to see the protected
            page, you must first login. Pretty standard stuff.
          </p>

          <p>
            First, visit the public page. Then, visit the protected page. You&apos;re not
            yet logged in, so you are redirected to the login page. After you login,
            you are redirected back to the protected page.
          </p>

          <p>
            Notice the URL change each time. If you click the back button at this
            point, would you expect to go back to the login page? No! You&apos;re already
            logged in. Try it out, and you&apos;ll see you go back to the page you
            visited just *before* logging in, the public page.
          </p> */}
            <Routes>
              <Route element={<DefaultLayout />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/activate" element={<ActivatePage />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
              </Route>
              <Route element={<AppLayout />}>
                <Route
                  path="/welcome"
                  element={
                    <RequireAuthPageWrapper>
                      <WelcomeIndex />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuthPageWrapper>
                      <DashboardPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/team"
                  element={
                    <RequireAuthPageWrapper>
                      <TeamListPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/team/edit"
                  element={
                    <RequireAuthPageWrapper>
                      <TeamEditPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/my-teams"
                  element={
                    <RequireAuthPageWrapper>
                      <MyTeamsIndex selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/jobs"
                  element={
                    <RequireAuthPageWrapper>
                      <TaskList selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/planograms"
                  element={
                    <RequireAuthPageWrapper>
                      <PlanogramsListPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/products"
                  element={
                    <RequireAuthPageWrapper>
                      <ProductListPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/planograms/captures"
                  element={
                    <RequireAuthPageWrapper>
                      <PlanogramCapturesIndex selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/stores"
                  element={
                    <RequireAuthPageWrapper>
                      <StoresListPage selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/stores/edit"
                  element={
                    <RequireAuthPageWrapper>
                      <StoresEditIndex selectedTeam={selectedTeam} />
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuthPageWrapper>
                      <RequireTeam>
                        <ProfileIndex selectedTeam={selectedTeam} />
                      </RequireTeam>
                    </RequireAuthPageWrapper>
                  }
                />
                <Route
                  path="/admin/planograms"
                  element={isUserAdmin() && <AdminPlanogramsPage />}
                />
                <Route
                  path="/admin/planograms/edit"
                  element={isUserAdmin() && <AdminPlanogramsEditPage />}
                />
                <Route
                  path="/admin/products"
                  element={isUserAdmin() && <AdminProductsListPage />}
                />
                <Route
                  path="/admin/products/edit"
                  element={isUserAdmin() && <AdminProductEditIndex />}
                />
                <Route
                  path="/admin/mlModels"
                  element={isUserAdmin() && <AdminMachineLearningModelIndex />}
                />
                <Route
                  path="admin/stores"
                  element={
                    isUserAdmin() && (
                      <AdminStoresListPage selectedTeam={selectedTeam} />
                    )
                  }
                />
                <Route
                  path="admin/stores/edit"
                  element={
                    isUserAdmin() && (
                      <AdminStoresEditIndex selectedTeam={selectedTeam} />
                    )
                  }
                />
                <Route
                  path="admin/stores/edit/assignPlanogram"
                  element={
                    isUserAdmin() && (
                      <AdminStoresAssignPlanogramPage
                        selectedTeam={selectedTeam}
                      />
                    )
                  }
                />
                <Route
                  path="admin/tools"
                  element={isUserAdmin() && <AdminToolsDashboard />}
                />
                <Route
                  path="admin/exports"
                  element={isUserAdmin() && <ExportsList />}
                />
                <Route
                  path="admin/categories"
                  element={isUserAdmin() && <ListCategoriesPage />}
                />
              </Route>

              {/* TODO: 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

function NotFound() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ background: "var(--brand-tertiary)", height: "100vh" }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="h1"
          color="secondary"
          textAlign={"center"}
          mb={2}
          sx={{
            backgroundImage:
              "radial-gradient(circle farthest-side at top left, var(--brand-primary) 0%, var(--brand-secondary) 100%)",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          404
        </Typography>
        <Typography variant="body1" color="white" textAlign={"center"}>
          Page not Found
        </Typography>

        {/* TODO: Check auth status an provide appropriate guidance */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ background: "var(--brand-tertiary)", gap: "2rem" }}
          mt={16}
        >
          <Button
            variant="contained"
            component={Link}
            to={"/login"}
            sx={{ minWidth: "8rem" }}
          >
            Log In
          </Button>
          <Button
            variant="outlined"
            component={Link}
            to={"/register"}
            color="secondary"
            sx={{ minWidth: "8rem" }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
