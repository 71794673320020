import { create } from "zustand";
import { CaptureProductNoInput } from "../../models/planograms/ProductComparisonTypes";

type ImageStoreState = {
  cachedProducts: CaptureProductNoInput[];
  mostRecentRevision: string | null;
};

type ImageStoreActions = {
  setCachedProducts: (_products: CaptureProductNoInput[]) => void;
  setMostRecentRevision: (_newRevisionId: string | null) => void;
};

export const useImageStore = create<ImageStoreState & ImageStoreActions>(
  (set) => ({
    cachedProducts: [],
    mostRecentRevision: null,
    setCachedProducts: (_productData: CaptureProductNoInput[]) =>
      set(() => ({ cachedProducts: _productData })),
    setMostRecentRevision: (_newRevisionId: string | null) =>
      set(() => ({ mostRecentRevision: _newRevisionId })),
  })
);
