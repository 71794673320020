import axiosInstance from "../axiosInstanceConfig";

export interface ListProduct {
  ProductId: string;
  RevisionId: string | null;
  Name: string;
  UPC: string;
  SKUCode: string | null;
}

// Returns all products included on planograms for a given team.
const getAllProducts = async (teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  try {
    const response = await axiosInstance.get<ListProduct[]>(
      `products/ProductOnPlanogramAssignmentByTeamId?${params.toString()}`
    );

    return response.data as ListProduct[];
  } catch {
    // TODO (Sep PR): Experiment more with error response handling (again).
    return false;
  }
};

export default getAllProducts;
