import axiosInstance from "../axiosInstanceConfig";

const postAdminEnablePlanogramAtStore = async (
  planogramId: string,
  deactivated: boolean
) => {
  const params = new URLSearchParams();
  params.append("planogramAssignmentId", planogramId);
  params.append("deactivated", deactivated.toString());

  const response = await axiosInstance.post(
    `planogram/AdminEnablePlanogramAtStore?${params.toString()}`
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error(
      "There was an error enabling/disabling the planogram at the store. Please contact support."
    );
  }
};

export default postAdminEnablePlanogramAtStore;
