import React, { ReactNode } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  SxProps,
  Stack,
} from "@mui/material";
import { urStorePalette } from "../themes/urStoreTheme";

interface DashboardCardProps {
  sxStyle?: SxProps;
  title?: string | ReactNode;
  titleStyle?: SxProps;
  contentStyle?: SxProps;
  children: ReactNode;
  plugins?: ReactNode;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  sxStyle,
  title = "",
  titleStyle,
  contentStyle,
  children,
  plugins,
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        background: urStorePalette.greys.lightest,
        border: `solid 0.5px ${urStorePalette.greys.default}`,
        ...sxStyle,
      }}
    >
      {title ? (
        <CardHeader
          sx={{
            px: 1.6,
            py: 2.4,
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
            alignItems: "center",
          }}
          title={
            typeof title === "object" ? (
              title
            ) : (
              <Stack
                direction="row"
                sx={{ width: "100%", pr: 2 }}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  sx={{ px: 2, fontSize: 15, fontWeight: 600, ...titleStyle }}
                >
                  {title}
                </Typography>

                {plugins && plugins}
              </Stack>
            )
          }
        />
      ) : null}
      <CardContent
        sx={{
          height: "100%",
          p: 4,
          "&:last-of-type": {
            pb: 2,
          },
          ...contentStyle,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
