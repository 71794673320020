import React from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { StoreFormInitialValues } from "../types/StoreFormInitialValues";
import { australianStates } from "../../../planograms/utils/strings";
import { useMetricsFilterOptions } from "../../../../stores/metrics/MetricsOptionsStore";
import { LoadingButton } from "@mui/lab";

const formInitialValues: StoreFormInitialValues = {
  name: "",
  address: "",
  addressLine2: null,
  latitude: null,
  longitude: null,
  city: "",
  suburb: "",
  state: "",
  country: "",
  postCode: "",
};

const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .max(50, "Exceed character limit")
    .min(5, "Must be more than 5 characters"),
  address: Yup.string().required("Required"),
  addressLine2: Yup.string().nullable(),
  latitude: Yup.number()
    .required("Required")
    .min(-90, "Choose a number greater than -90")
    .max(90, "Choose a number less than 90"),
  longitude: Yup.number()
    .required("Required")
    .min(-180, "Choose a number greater than -180")
    .max(180, "Choose a number less than 180"),
  city: Yup.string().required("Required"),
  suburb: Yup.string().nullable(),
  state: Yup.string().when("country", ([country], schema) => {
    return country === "Australia"
      ? Yup.string().required("Required")
      : Yup.string().nullable();
  }),
  country: Yup.string().required("Required"),
  postCode: Yup.string().required("Required"),
});

const AdminStoreFormDialog = ({
  open,
  handleClose,
  formValues,
  mode,
  handleSubmit,
  loading,
}: {
  open: boolean;
  mode: "create" | "edit";
  handleClose: () => void;
  formValues?: StoreFormInitialValues;
  handleSubmit: (_values: StoreFormInitialValues) => void;
  loading: boolean;
}) => {
  // Options
  const { data } = useMetricsFilterOptions();

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>
        {mode === "create" ? "Create New Store" : "Edit Store Details"}
      </DialogTitle>

      <Formik
        enableReinitialize
        // formValues is passed if the mode is edit.
        initialValues={formValues ?? formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, errors, touched, isValid, dirty }) => (
          <Form>
            <DialogContent sx={{ mt: 0 }}>
              <Grid container spacing={5}>
                <Grid item container flexDirection="column" spacing={2} gap={3}>
                  <Grid item>
                    <Field
                      size="small"
                      fullWidth
                      as={TextField}
                      id="name"
                      name="name"
                      label="Store Name"
                      required
                      value={values.name}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      size="small"
                      fullWidth
                      as={TextField}
                      id="address"
                      name="address"
                      label="Address"
                      required
                      value={values.address}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      size="small"
                      fullWidth
                      as={TextField}
                      id="postCode"
                      name="postCode"
                      label="Post Code"
                      required
                      value={values.postCode}
                      error={touched.postCode && Boolean(errors.postCode)}
                      helperText={touched.postCode && errors.postCode}
                    />
                  </Grid>

                  <Grid item>
                    <Stack direction="row" gap={2}>
                      <Field
                        size="small"
                        required
                        fullWidth
                        as={TextField}
                        type="number"
                        id="latitude"
                        name="latitude"
                        label="Latitude"
                        value={values.latitude}
                        error={touched.latitude && Boolean(errors.latitude)}
                        helperText={touched.latitude && errors.latitude}
                      />

                      <Field
                        size="small"
                        required
                        fullWidth
                        as={TextField}
                        type="number"
                        id="longitude"
                        name="longitude"
                        label="Longitude"
                        value={values.longitude}
                        error={touched.longitude && Boolean(errors.longitude)}
                        helperText={touched.longitude && errors.longitude}
                      />
                    </Stack>
                  </Grid>
                  <Grid item>
                    <FormControl
                      required
                      size="small"
                      fullWidth
                      error={touched.country && Boolean(errors.country)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Field
                        fullWidth
                        as={Select}
                        id="country"
                        name="country"
                        label="Country"
                        value={values.country || ""}
                        helperText={touched.country && errors.country}
                      >
                        <MenuItem value="Australia">Australia</MenuItem>
                        <MenuItem value="New Zealand">New Zealand</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  {values.country === "Australia" && (
                    <Grid item>
                      <FormControl
                        required
                        size="small"
                        fullWidth
                        error={touched.state && Boolean(errors.state)}
                      >
                        <InputLabel id="demo-simple-select-label-2">
                          State
                        </InputLabel>
                        <Field
                          fullWidth
                          as={Select}
                          id="state"
                          name="state"
                          label="State"
                          value={values.state || ""}
                        >
                          {australianStates.map((state) => (
                            <MenuItem key={state.value} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </Field>

                        <FormHelperText>
                          State is required if the selected country is
                          Australia.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item>
                    <Field
                      required
                      size="small"
                      fullWidth
                      as={TextField}
                      id="city"
                      name="city"
                      label="City"
                      value={values.city || ""}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      size="small"
                      fullWidth
                      as={TextField}
                      id="suburb"
                      name="suburb"
                      label="Suburb"
                      value={values.suburb || ""}
                      error={touched.suburb && Boolean(errors.suburb)}
                      helperText={touched.suburb && errors.suburb}
                    />
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Field
                      size="small"
                      fullWidth
                      as={TextField}
                      id="addressLine2"
                      name="addressLine2"
                      label="Store Code"
                      value={values.addressLine2 || ""}
                      error={
                        touched.addressLine2 && Boolean(errors.addressLine2)
                      }
                      helperText={touched.addressLine2 && errors.addressLine2}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                disabled={!isValid || !dirty}
                type="submit"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AdminStoreFormDialog;
