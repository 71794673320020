import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Chip, Stack, Tab, Tabs } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import getAdminPlanogramById, { AdminPlanoResponse } from "../../../api/planograms/getAdminPlanogramById";
import getAdminMissingPlanogramImages from "../../../api/planograms/getAdminMissingPlanogramImages";
import getAdminFindLowResImagesForPlanogram from "../../../api/planograms/getAdminFindLowResImagesForPlanogram";
import getAdminAllProducts from "../../../api/planograms/getAdminAllProducts";
import postAdminMakeProduct from "../../../api/planograms/postAdminMakeProduct";
import postAdminUploadPlanogramRevision from "../../../api/planograms/postAdminUploadPlanogramRevision";
import postAdminUpdatePlanogram, { convertFormDataToUpdateRequestData } from "../../../api/planograms/postAdminUpdatePlanogram";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { CaptureProductNoInput } from "../../../models/planograms/ProductComparisonTypes";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import UploadPlanogramRevisionModal from "./components/UploadPlanogramRevisionModal";
import AdminPlanogramEditFormikWrapper, { PlanogramFormData, convertPlanogramResponseToFormData } from "./AdminPlanogramEditFormikWrapper";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

class DisplayProductMissingImages {
    constructor(
        public id: string,
        public UPC: string,
        public Name: string
    ) {}
}

const AdminPlanogramEditPage = (props: any) => {
    // Fields & Data
    const [planogramID, setPlanogramID] = useState<string | null>(null);
    const [createdDate, setCreatedDate] = useState<Date | null>(null);
    const [latestRevision, setLatestRevision] = useState<any | null>(null);
    const [originalPlanogram, setOriginalPlanogram] = useState<AdminPlanoResponse | null>(null);
    const [tab, setTab] = useState<string>("1");
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [submittingPlanogram, setSubmittingPlanogram] = useState<boolean>(false);

    // Meta State
    const [params, setParams] = useSearchParams();
    const [editing, setEditing] = useState<boolean>(false);
    const [isUploadRevisionModalOpen, setIsUploadRevisionModalOpen] = useState<boolean>(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
    const [refreshFormFlag, setRefreshFormFlag] = useState<boolean>(false);

    //States for the missing images
    const [missingImageProducts, setMissingImageProducts] = useState<any[]>([]);

    //Low Res Images
    const [lowResImageProducts, setLowResImageProducts] = useState<any[]>([]);

    // Hooks
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { setSubRoute } = useLayoutStore();

    useEffect(() => {
        let planogramID = params.get("planogram");
        if (planogramID) {
            setPlanogramID(params.get("planogram"));
        } else {
            navigate('../admin/planogram');
        }
    }, []);

    useEffect(() => {
      fetchAdminPlanogramById();
    }, [planogramID]);

    const fetchAdminPlanogramById = async () => {
      if (!planogramID) {
        return;
      }
      
      try {
        setLoading(true);
        const data = await getAdminPlanogramById(planogramID);
        if (data) {
          // Keep a ref to the unchanged version in case we need to revert user changes.
          setOriginalPlanogram(data);

          setCreatedDate(data.CreatedAt);
          if (data.Revisions && data.Revisions.length > 0) {
            setLatestRevision(data.Revisions[0]);
          } else {
            setLatestRevision(null);
          }

          setSubRoute({title: data.Name});
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to load planogram.",
        });
        console.log(error.message);
      }
    };

    useEffect(() => {
      const fetchAdminMissingPlanogramImages = async () => {
        if(!planogramID) {
          return;
        }

        try {
          const data = await getAdminMissingPlanogramImages(planogramID);
          if(data) {
            setMissingImageProducts(data.map((product: any) => {
              return new DisplayProductMissingImages(product.UPC, product.UPC, product.Name);
            }));
            recursivelyFetchMissingImageProducts([], null, true);
          }
        } catch (error: any) {
          enqueueSnackbar("Error", {
            variant: "error",
            cta: "Failed to load missing planogram images.",
          });
          console.log(error.message);
        }
      };
      fetchAdminMissingPlanogramImages();
    }, [planogramID]);

    async function recursivelyFetchMissingImageProducts(productArray: any[], continuationToken: string|null, firstCall: boolean) {
        if ((!firstCall && continuationToken == null) || planogramID == null) {
            setLowResImageProducts(productArray);
            return;
        } else {
            try {
              const data = await getAdminFindLowResImagesForPlanogram(planogramID, continuationToken);
              if (data) {
                recursivelyFetchMissingImageProducts([...productArray, ...data.items.map((product: any) => {
                    if (product.ProductId !== null) {
                        return new DisplayProductMissingImages(product.productId, product.productUPC, product.productName);
                    }
                })], data.continuationToken, false);
            }
            } catch (error: any) {
              enqueueSnackbar("Error", {
                variant: "error",
                cta: "Failed to load missing planogram images.",
              });
              console.log(error.message);
            }
        }
    }

    async function uploadNewPlanogramRevision(file:File) {
        if (planogramID == null) {
            return;
        }
        setUploadLoading(true);
        const data = new FormData();
        data.append("file", file);

        try {
            const responseData = await postAdminUploadPlanogramRevision(planogramID, data);
            if (responseData) {
                setUploadLoading(false);
                enqueueSnackbar("Success", {
                  variant: "success",
                  cta: "Planogram Revision upload successful.",
                });
                setIsUploadRevisionModalOpen(false);
                // Re-load with new planogram
                fetchAdminPlanogramById();
            } 
        } catch (err: any) {
            setUploadLoading(false);
            enqueueSnackbar("Error", {
              variant: "error",
              cta: "Planogram Revision upload failed.",
            });
            console.log(err.message);
        }
    }

    //functions for missing image products
    const onProductSelected = async (
        productUPC: string,
      ) => {
        let upc = productUPC;

        try {
            const data = await getAdminAllProducts();
            if (data) {
                let productExists = false;

                if (data && data.length > 0) {
                    data.forEach((product: CaptureProductNoInput) => {
                        if (product.UPC === upc) {
                            openEditProductMissingImage(product.ProductId);
                            productExists = true;
                        }
                    });
                }

                if (!productExists) {
                    createMissingImageProduct(upc);
                }
            }
        } catch (err: any) {
            enqueueSnackbar("Error", {
              variant: "error",
              cta: "Failed to load products.",
            });
            console.log(err.message);
        }
    };

    function openEditProductMissingImage(productID: String) {
        if (productID.length === 0 || planogramID === null || planogramID.length === 0) {
          enqueueSnackbar("Error", {
            variant: "error",
            cta: "No product selected.",
          });
        }

        navigate({
            pathname: "../admin/products/edit",
            search: "?product=" + productID + "&fromPlanogram=" + planogramID
        });
    }

    async function createMissingImageProduct(upc: string) {

        if (upc === "") {
            return;
        }

        let requestData = { "UPC": upc };

        try {
            const data = await postAdminMakeProduct(requestData);
            if (data) {
                const newProductID = data.id;

                if (newProductID !== null && newProductID !== undefined && newProductID.length > 0) {
                    openEditProductMissingImage(newProductID);
                }
            }
        } catch (error: any) {
            enqueueSnackbar("Error", {
              variant: "error",
              cta: "Failed to create product.",
            });
            console.log(error.message);
        }
    }

    function revisionMessage(revisionExists: boolean): string {
        if (revisionExists) {
            return "Latest Revision: ";
        } else {
            return "No Revisions";
        }
    }

    function handleDiscardChanges() {
        // Refresh state to initialise forms
        setRefreshFormFlag(true);
        
        // Leave edit mode after mutations are performed.
        setEditing(false);
    }

    async function handleSavePlanogram(values: PlanogramFormData) {
      // Pre-flight checks. Don't even attempt to call without these.
      if (planogramID == null) return;

      try {
        setSubmittingPlanogram(true);
        const request = convertFormDataToUpdateRequestData(planogramID, values);

        const success = await postAdminUpdatePlanogram(request);

        // If we get a value out of above, assume successful.
        if (success) {
          await fetchAdminPlanogramById();

          enqueueSnackbar("Success", {
            variant: "success",
            cta: "Planogram updated.",
          });

          // Leave edit mode
          setEditing(false);
        }
      } catch (err: any) {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to update planogram.",
        });
        console.log(err.message);
      } finally {
        setSubmittingPlanogram(false);
      }
    }

    return (
      <Box sx={ResponsiveContainerStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ mb: 5, marginTop: 5 }}
          gap={2}
        >
          <Stack direction="row" display="flex">
          {/* Created */}
          {createdDate && (
            <Chip
              sx={{mr:2}}
              label={
                <>
                  <b>Created: </b>
                  {formatDisplayDate(new Date(createdDate))}
                </>
              }
            />
          )}

          {/* Last Modified */}
          <Chip
            label={
              <>
                <b>{revisionMessage(latestRevision != null)} </b>
                {formatDisplayDate(latestRevision?.LastModified)}
              </>
            }
          />
          </Stack>
          <Stack gap={1} direction="row">
          <Button 
            startIcon={<Add/>} 
            onClick={()=> setIsUploadRevisionModalOpen(true)} 
            variant="contained">Add Revision</Button>
          {/* Show Edit when not in edit mode. */}
          {!editing && (
            <Button startIcon={<Edit/>} variant="contained" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}

          {/* Show Save/Cancel when in Edit Mode */}
          {editing && (
            <ButtonGroup>
              <LoadingButton
                loading={submittingPlanogram}
                variant="outlined"
                color="secondary"
                type="submit"
                form="planogram-form"
                disabled={saveButtonDisabled}
              >
                Save
              </LoadingButton>
              <Button color="error" onClick={handleDiscardChanges}>
                Discard
              </Button>
            </ButtonGroup>
          )}
          </Stack>
          
        </Box>
        <Stack spacing={4}>
          <AdminPlanogramEditFormikWrapper
            initialValues={convertPlanogramResponseToFormData(
              originalPlanogram
            )}
            isEditMode={editing}
            handleSubmit={(values) => handleSavePlanogram(values)}
            onDisabledChanged={(disabled) => setSaveButtonDisabled(disabled)}
            displayHelperText={true}
            refreshForm={refreshFormFlag}
            setRefreshForm={setRefreshFormFlag}
          />
        </Stack>

        <Tabs
          value={tab}
          sx={{ textDecoration: "none", marginTop: 4 }}
          onChange={(e, nv) => setTab(nv)}
        >
          <Tab label="Low Resolution Images" value={"1"} />
          <Tab label="Missing Images" value={"2"} />
        </Tabs>

        {tab === "1" && (
          <Box sx={{ my: 3 }}>
            <AgGridWrapper
              loading={loading}
              height="calc(100vh - 400px)"
              id="admin-planogram-low-res-images"
              rowData={lowResImageProducts}
              columnDefs={[
                { field: "UPC", headerName: "UPC", flex: 1 },
                { field: "Name", headerName: "Name", flex: 1 },
              ]}
              onRowClicked={(row: any) => onProductSelected(row.data.UPC)}
              quickSearch
              export
            />
          </Box>
        )}
        {tab === "2" && (
          <Box sx={{ my: 3 }}>
            <AgGridWrapper
              loading={loading}
              height="calc(100vh - 400px)"
              id="admin-planogram-missing-images"
              rowData={missingImageProducts}
              onRowClicked={(row: any) => onProductSelected(row.data.UPC)}
              columnDefs={[
                { field: "UPC", headerName: "UPC", flex: 1 },
                { field: "Name", headerName: "Name", flex: 1 },
              ]}
              quickSearch
              export
            />
          </Box>
        )}
        <UploadPlanogramRevisionModal
          open={isUploadRevisionModalOpen}
          onClose={() => setIsUploadRevisionModalOpen(false)}
          onSubmit={uploadNewPlanogramRevision}
          loading={uploadLoading}
        />
      </Box>
    );
};

export default AdminPlanogramEditPage;