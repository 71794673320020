import { TeamMember } from "../../stores/teams/TeamMemberStore";
import axiosInstance from "../axiosInstanceConfig";

const getTeamMembers = async (teamId: string) => {

  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.get<TeamMember[]>(
    `user/TeamMembers?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error getting the team members. Please contact support.");
  }
  
};

export default getTeamMembers;
