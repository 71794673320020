import {
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  Box,
  LinearProgress,
  Paper,
} from "@mui/material";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import LazyLoadAvatar from "../LazyLoadAvatar";
import dayjs from "dayjs";
import { AggregateEntity, AggregateManufacturer, NormalisePct } from "./util";
import { orange } from "@mui/material/colors";

// Module extension to djs.
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

type VirtualisedMetricRowProps = {
  // Data Props
  /** Index of the current iterable within data. */
  index: number;
  /** CSS Style properties that will be inherited by this row. */
  style: React.CSSProperties;
  /** A list of either Aggregate Products or Manufacturers. */
  data: (AggregateEntity | AggregateManufacturer)[];
  /** The key on either Product or Manufacturer that represents the 'complete' or 'progress' value of the total. */
  completeKey: keyof Omit<
    AggregateEntity | AggregateManufacturer,
    "LastCapturedDate"
  >;
  /** The key on either Product or Manufacturer that represents the 'total' value of a metric. */
  totalKey: keyof Omit<
    AggregateEntity | AggregateManufacturer,
    "LastCapturedDate"
  >;
  /** Optional: Label and Prog bar values will invert. E.g. values of 3 / 100 would instead show of 97 /100 with this prop. @default false */
  invertValues?: boolean;
  /** Optional: Specify a divisor which is applied to complete and total values. @default: 1 */
  valueDivisor?: number;

  // Display Props
  /** The colour of the linear progress bar. Tinting occurs automatically. Expects any value that resolves to a hex code. */
  linearProgressColour: string;
  /** Suffix applied to the complete and total values when used for display purposes. E.g. 'm2' would cause values to read '32m2 / 100m2'. @default ''*/
  unitSuffix?: string;
  /** The number of decimal points passed to .toFixed() functions used when displaying values. @default 0 */
  fixedDecimalPoints?: number;
};

/**
 * This component is designed to exclusively work with AggregateProduct and AggregateManufacturer types.
 * Please do not update the typedefs of this component unless you know exactly what you are doing.
 */
export const VirtualisedMetricRow = ({
  index,
  style,
  data,
  completeKey,
  totalKey,
  valueDivisor = 1,

  invertValues = false,
  linearProgressColour,
  unitSuffix = "",
  fixedDecimalPoints = 0,
}: VirtualisedMetricRowProps) => {
  const sp = data[index];

  const isProduct = (
    sp: AggregateEntity | AggregateManufacturer
  ): sp is AggregateEntity => {
    return (sp as AggregateEntity).Identifier !== undefined;
  };

  return (
    <ListItem
      style={style}
      key={isProduct(sp) ? sp.Identifier : sp.Manufacturer}
      dense
    >
      {/* Image (Product Aggregates Only) */}
      {isProduct(sp) && (
        <LazyLoadAvatar src={sp.ImageUrl} alt={"Product Image"} />
      )}

      {/* PCT Readout (Manufacturer Aggregates Only) */}
      {!isProduct(sp) && (
        <Paper
          sx={{
            width: 42,
            height: 42,
            backgroundColor: orange[50],
            border: "none",
            mr: 2,
          }}
        >
          <Box
            sx={{ height: "100%", width: "100%" }}
            alignItems={"center"}
            display="flex"
            justifyContent={"center"}
          >
            <Typography fontWeight={600} sx={{ color: orange[800] }}>
              {NormalisePct(
                sp[completeKey],
                sp[totalKey],
                invertValues
              ).toFixed(0)}
              <span style={{ fontSize: 10 }}>%</span>
            </Typography>
          </Box>
        </Paper>
      )}

      {/* Identifier and lastcap */}
      <ListItemText>
        <Stack gap={1}>
          <Tooltip title={isProduct(sp) ? sp.DisplayName : sp.Manufacturer}>
            <Typography fontWeight={500} variant="body2" noWrap>
              {/* @ts-ignore */}
              {sp.DisplayName}
              {/* {isProduct(sp) ? sp.DisplayName : sp.Manufacturer} */}
            </Typography>
          </Tooltip>

          {/* LinProg */}
          <Box sx={{ width: "100%", color: linearProgressColour }}>
            <LinearProgress
              sx={{ borderRadius: "4px" }}
              variant="determinate"
              color="inherit"
              value={NormalisePct(sp[completeKey], sp[totalKey], invertValues)}
            />
          </Box>

          {/* Subtitle + Lastcap */}
          <Stack
            direction="row"
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              noWrap
              variant="caption"
              sx={{
                color: urStorePalette.greys.dark
              }}
            >
              <i>
                Last captured{" "}
                {/* @ts-ignore TypeScript doesn't like the required module injection for this function. It's safe. */}
                {`${dayjs().to(dayjs(sp.LastCapturedDate))}`}
              </i>
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: urStorePalette.greys.dark,
                flexGrow: "0 2",
                display: "flex",
              }}
              noWrap
            >
              {invertValues ? (
                <b>
                  {`${((sp[totalKey] - sp[completeKey]) / valueDivisor).toFixed(
                    fixedDecimalPoints
                  )}${unitSuffix} / ${(sp[totalKey] / valueDivisor).toFixed(
                    fixedDecimalPoints
                  )}${unitSuffix} `}
                </b>
              ) : (
                <b>
                  {`${(sp[completeKey] / valueDivisor).toFixed(
                    fixedDecimalPoints
                  )}${unitSuffix} / ${(sp[totalKey] / valueDivisor).toFixed(
                    fixedDecimalPoints
                  )}${unitSuffix} `}
                </b>
              )}
            </Typography>
          </Stack>
        </Stack>
      </ListItemText>
    </ListItem>
  );
};

export default VirtualisedMetricRow;
