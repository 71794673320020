import { PlanogramProduct } from "../../features/admin/planograms/types";
import axiosInstance from "../axiosInstanceConfig";

const getAdminProductImage = async (productId: string) => {

  const params = new URLSearchParams();
  params.append("productId", productId);

  const response = await axiosInstance.get<PlanogramProduct>(
    `products/AdminGetProductImages?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the product image. Please contact support."
    );
  }
  
};

export default getAdminProductImage;
