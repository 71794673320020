import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { urStorePalette } from "../../../../themes/urStoreTheme";

type MetricsTableProps = {
  title: string;
  children: JSX.Element[] | JSX.Element | null;
  actions?: JSX.Element[] | JSX.Element | null;
};

/*
  Metric Tables are specifically designed to be used when contained within a 
  <DashboardCard/> and their styling assumes that the DashboardCard's title prop is empty.
*/
const MetricsTable = (props: MetricsTableProps) => {
  let { title, children } = props;

  return (
    <Box sx={{mt: '-4px'}}>
      <Grid container gap={0}>
        {title && props.actions && (
          <>
            <Grid display="flex" alignItems="center" item xs={6} sm={6}>
              <Typography
                variant="body1"
                mb={3}
                sx={{ fontWeight: 600, fontSize: 15 }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Grid container justifyContent="flex-end">
                {props.actions}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableBody sx={{ width: "100%" }}>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MetricsTable;
