import { StoreCategory } from "../../features/admin/stores/types/CategoryTypes";
import axiosInstance from "../axiosInstanceConfig";

const upsertStoreCategory = async (_category: StoreCategory) => {
  const response = await axiosInstance.post(
    `StoreCategory/CreateUpdateStoreCategory`,
    {
      ..._category,
    }
  );

  return response.data;
};

export default upsertStoreCategory;
