import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StoreCategory } from "../types/CategoryTypes";
import { Close, ErrorRounded, InfoRounded } from "@mui/icons-material";
import getCategories, {
  PlanogramCategory,
} from "../../../../api/categories/getCategories";
import { enqueueSnackbar } from "notistack";
import upsertStoreCategory from "../../../../api/store-categories/upsertStoreCategory";

export default function StoreCategoryDialog(props: {
  open: boolean;
  storeId: string;
  existingCategory: StoreCategory | null;
  inUseCategories: StoreCategory[];
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  // State
  const [form, setForm] = useState<Partial<StoreCategory>>(
    props.existingCategory ?? {}
  );
  const [planogramCategories, setPlanogramCategories] = useState<
    PlanogramCategory[]
  >([]);
  const [categoryInUseWarning, setCategoryInUseWarning] =
    useState<boolean>(false);

  // Effects
  useEffect(() => {
    const fetchPlanogramCategories = async () => {
      try {
        const data = await getCategories();
        setPlanogramCategories(data);
      } catch {
        enqueueSnackbar("Failed to load Category Data.", { variant: "error" });
      }
    };

    fetchPlanogramCategories();
  }, []);

  // Handles resetting form data if the edit form changes.
  useEffect(() => {
    if (props.existingCategory) {
      setForm(props.existingCategory);
    } else {
      setForm({});
    }
  }, [props.existingCategory]);

  useEffect(() => {
    if (
      props.inUseCategories
        .map((iuc) => iuc.PlanogramCategoryId)
        .includes(form.PlanogramCategoryId ?? "") &&
      !props.existingCategory
    ) {
      setCategoryInUseWarning(true);
    } else {
      setCategoryInUseWarning(false);
    }
  }, [form.PlanogramCategoryId, props.existingCategory, props.inUseCategories]);

  // Check all forms are filled.
  const isFormValid = () => {
    return (
      form.PlanogramCategoryId &&
      form.BayCount &&
      form.BayHeight &&
      form.FixtureDepth &&
      form.FixtureHeight &&
      form.FixtureName &&
      form.FixtureType
    );
  };

  // Handles submission.
  async function handleFormSubmit() {
    try {
      // Coerce the current form data back out of being a Partial<T>.
      const success = await upsertStoreCategory({
        ...form,
        StoreId: props.storeId,
      } as StoreCategory);

      if (success) {
        // Fire the prop submit handler. This is usually to refetch data.
        props.handleSubmit();

        // Snacky
        enqueueSnackbar("Changes Saved", { variant: "success" });

        // Close
        handleFormClose();
      }
    } catch {
      enqueueSnackbar("Failed to save Store Category.", { variant: "error" });
    }
  }

  function handleFormClose() {
    setForm({});
    props.handleClose();
  }

  return (
    <Dialog open={props.open} maxWidth={"sm"} onClose={handleFormClose}>
      <DialogTitle>
        {!props.existingCategory ? "New Store Category" : "Edit Store Category"}
      </DialogTitle>
      <DialogContent>
        {/* Explanation */}
        <Alert
          color='info'
          sx={{ border: "none", mb: 2 }}
          icon={<InfoRounded />}
        >
          <AlertTitle>Usage</AlertTitle>
          Store Categories affect which Planograms can be assigned to this store. Updating categories may make prior assignments invalid.
        </Alert>

        {/* Error (in use category) */}
        <Grow in={categoryInUseWarning} unmountOnExit>
          <Alert
            color="error"
            sx={{ border: "none", mb: 2 }}
            icon={<ErrorRounded />}
          >
            <AlertTitle>Category In Use</AlertTitle>
            Shelf metrics are already configured for this Category. To edit the
            existing shelf metrics, go back and select this category from the
            list of store categories.
          </Alert>
        </Grow>

        {/* Form Stack */}
        <Stack direction="column" gap={2}>
          {/* Plano Category */}
          <Autocomplete
            loading={!planogramCategories}
            loadingText={"Loading..."}
            disabled={Boolean(props.existingCategory)}
            value={
              planogramCategories.find(
                (pc) => pc.Id === form.PlanogramCategoryId
              ) ?? null
            }
            options={planogramCategories}
            onChange={(_e, _value) => {
              setForm((prev) => ({
                ...prev,
                PlanogramCategoryId: _value ? _value.Id : undefined,
              }));
            }}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                variant="outlined"
                size="small"
              />
            )}
          />

          {/* Bay Count */}
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, BayCount: Number(e.target.value) }))
            }
            variant="outlined"
            size="small"
            label="Bay Count"
            value={form.BayCount ?? ''}
          />

          {/* Bay Height */}
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                BayHeight: Number(e.target.value),
              }))
            }
            variant="outlined"
            size="small"
            label="Bay Height"
            value={form.BayHeight ?? ''}
          />

          {/* Fixture Depth */}
          <Typography variant="caption">Fixure Detail</Typography>
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                FixtureDepth: Number(e.target.value),
              }))
            }
            variant="outlined"
            size="small"
            label="Fixture Depth"
            value={form.FixtureDepth ?? ''}
          />

          {/* Fixture Height */}
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                FixtureHeight: Number(e.target.value),
              }))
            }
            variant="outlined"
            size="small"
            label="Fixture Height"
            value={form.FixtureHeight ?? ''}
          />

          {/* Fixture Name */}
          <TextField
            type="text"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, FixtureName: e.target.value }))
            }
            variant="outlined"
            size="small"
            label="Fixture Name"
            value={form.FixtureName ?? ''}
          />

          {/* Fixture Type */}
          {/* TODO: This will leverage a lookup in a later update. */}
          <TextField
            type="text"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, FixtureType: e.target.value }))
            }
            variant="outlined"
            size="small"
            label="Fixture Type"
            value={form.FixtureType ?? ''}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 6, pb: 4 }}>
        <Button
          onClick={() => props.handleClose()}
          color="error"
          startIcon={<Close />}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !isFormValid() || (categoryInUseWarning && !props.existingCategory)
          }
          onClick={handleFormSubmit}
          variant="contained"
        >
          {props.existingCategory ? "Save" : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
