import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { useEffect, useState } from "react";
import { urStorePalette } from "../../../themes/urStoreTheme";
import postSnapshotUploadLinkRequest from "../../../api/planograms/postRequestSnapshotUploadUrl";
import { LoadingButton } from "@mui/lab";
import getMyPlanograms, {
  PlanogramAssignment,
} from "../../../api/planograms/getMyPlanograms";
import putSnapshotToBucket from "../../../api/planograms/putSnapshotToBucket";
import { AttachFile, CloseOutlined, Delete } from "@mui/icons-material";
import getAllStores from "../../../api/stores/getAllStores";
import { StoreData } from "../../stores/types/StoreResponseData";
import DragAndDropUploader from "../../../components/DragAndDropUploader/DragAndDropUploader";

interface SnapshotUploaderProps {
  open: boolean;
  handleClose: () => void;
}

export const SnapshotUploader = (props: SnapshotUploaderProps) => {
  // Util
  const { selectedTeam } = useTeamStore();

  // State
  const [step, setStep] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [assignedPlanograms, setAssignedPlanograms] = useState<
    PlanogramAssignment[]
  >([]);
  const [selectedStore, setSelectedStore] = useState<StoreData | null>(null);
  const [selectedAssignedPlanogram, setSelectedAssignedPlanogram] =
    useState<PlanogramAssignment | null>(null);
  const [stores, setStores] = useState<StoreData[]>([]);
  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);

  // Loading States
  const [gettingAssignedPlanograms, setGettingAssignedPlanograms] =
    useState<boolean>(false);
  const [uploadProcessing, setUploadProcessing] = useState<boolean>(false);

  // Constants
  const steps = ["Select Archive", "Processing", "Complete"];

  // Effects
  useEffect(() => {
    resetInternalState();
  }, [props.open]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const resp = await getAllStores(selectedTeam);
        setStores(resp);
      } catch {
        setErrorSnackOpen(true);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    const fetchMyPlanograms = async () => {
      try {
        setGettingAssignedPlanograms(true);
        const resp = await getMyPlanograms(selectedTeam);

        setAssignedPlanograms(resp);
      } catch {
        setErrorSnackOpen(true);
      } finally {
        setGettingAssignedPlanograms(false);
      }
    };

    fetchMyPlanograms();
  }, [selectedTeam]);

  const ProcessingStep = () => (
    <Fade in={true}>
      <Stack
        sx={{ width: "100%", height: "100%" }}
        gap={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress
          color={"secondary"}
          sx={{ width: "50%" }}
          variant="indeterminate"
        />
        <Typography sx={{ fontWeight: 500, color: urStorePalette.greys.dark }}>
          Upload in Progress
        </Typography>

        <Typography
          variant="caption"
          sx={{ color: urStorePalette.greys.default }}
        >
          Please do not close this tab.
        </Typography>
      </Stack>
    </Fade>
  );

  const FinaliseStep = () => (
    <Box
      sx={{ width: "100%", height: "100%" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Fade in={true}>
        <Typography
          sx={{
            color: urStorePalette.greys.lightest,
            // color: urStorePalette.metric.green,
            fontWeight: 600,
            fontSize: 18,
            backgroundColor: urStorePalette.metric.green,
            py: 2,
            px: 4,
            borderRadius: 6,
          }}
        >
          Snapshot Uploaded
        </Typography>
      </Fade>
    </Box>
  );

  const handleSnapshotUploadProcessing = async () => {
    // Generates an upload link that the archive can be sent to.
    try {
      setUploadProcessing(true);
      const resp = await postSnapshotUploadLinkRequest(
        selectedTeam,
        file!.size,
        selectedAssignedPlanogram!.PlanogramAssignmentId
      );

      if (file) {
        // Goto processing tab
        setStep(1);

        // Send to bucket
        await putSnapshotToBucket(resp.SignedUrl, file);

        // Goto done tab
        setStep(2);
      }
    } catch {
      setErrorSnackOpen(true);
      resetInternalState();
    } finally {
      setUploadProcessing(false);
    }
  };

  const resetInternalState = () => {
    setStep(0);
    setFile(null);
    setSelectedAssignedPlanogram(null);
  };

  return (
    <>
      {/* Error feedback snack */}
      <Snackbar
        open={errorSnackOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          An error occurred with your request. Please contact support.
        </Alert>
      </Snackbar>

      {/* Dialog */}
      <Dialog open={props.open} fullWidth>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            Upload Snapshot
            <IconButton onClick={props.handleClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ height: "268px" }}>
          {/* Render based on current step */}
          {step === 0 && (
            <Stack gap={2} sx={{ height: "100%" }} alignItems={"center"}>
              {/* Store Selector */}
              <Autocomplete
                disabled={gettingAssignedPlanograms}
                sx={{ mt: 1.2 }}
                size="small"
                id="sel-store"
                onChange={(e, nv, r) => {
                  setSelectedStore(nv);
                  setSelectedAssignedPlanogram(null);
                }}
                options={stores}
                getOptionLabel={(option) => option.Name}
                value={selectedStore}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={"Store"} required={true} />
                )}
              />

              {/* Planogram selector */}
              <Autocomplete
                disabled={selectedStore === null || gettingAssignedPlanograms}
                sx={{ mt: 1.2 }}
                size="small"
                id="sel-assign-plano"
                onChange={(e, nv) => setSelectedAssignedPlanogram(nv)}
                options={assignedPlanograms.filter(
                  (ap) => ap.StoreId === selectedStore?.StoreId
                )}
                getOptionLabel={(option) =>
                  option.RevisionName ?? "Missing Revision Name!"
                }
                renderOption={(p, data) => {
                  return (
                    <>
                      <MenuItem
                        onClick={() => setSelectedAssignedPlanogram(data)}
                      >
                        <Stack>
                          <Typography fontSize={12} fontWeight={600}>
                            {data.RevisionName ?? "No Revision Name"}
                          </Typography>
                          <Typography
                            fontSize={10}
                            fontWeight={400}
                            variant="subtitle2"
                          >
                            {data.PlanogramDisplayName ?? "No Display Name"}
                          </Typography>
                          <Typography
                            fontSize={10}
                            fontWeight={400}
                            variant="subtitle2"
                          >
                            {data.PlanogramName ?? "No Planogram Name"}
                          </Typography>
                        </Stack>
                      </MenuItem>
                      <Divider />
                    </>
                  );
                }}
                value={selectedAssignedPlanogram}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={"Planogram"} required={true} />
                )}
              />
              {file && (
                <Box sx={{ width: "100%", height: "100%"}} display="flex" justifyContent="center" alignContent="center" mt={2} mb={3}>
                <Typography variant="body1" display="flex" alignItems="center">
                  <AttachFile fontSize="small" />
    
                  {file.name}
    
                  <IconButton
                    onClick={() => setFile(null)}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                </Typography>
              </Box>
              )}
              
              {!file &&
              <DragAndDropUploader
                onFileDrop={(file: File | File[]) => setFile(file as File)}
                acceptedFileTypes={[".zip"]}
              />}
            </Stack>
          )}
          {step === 1 && <ProcessingStep />}
          {step === 2 && <FinaliseStep />}
        </DialogContent>
        <DialogActions>
          {/* Workflow stepper */}
          <Stepper activeStep={step}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* This is a cute bit of css to float stuff above it left, and stuff below right. */}
          <div style={{ flex: "1 1 0" }} />

          {/* Contextual Next/Done/Empty slot. */}
          {step === 0 && (
            <LoadingButton
              loading={uploadProcessing}
              disabled={Boolean(!file) || selectedAssignedPlanogram === null}
              variant="contained"
              onClick={async () => await handleSnapshotUploadProcessing()}
            >
              Start Upload
            </LoadingButton>
          )}
          {/* Start Over control */}
          {step === 2 && (
            <Fade in={true}>
              <Button variant="contained" onClick={resetInternalState}>
                Start Over
              </Button>
            </Fade>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
