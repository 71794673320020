import { useState } from "react";
import dayjs from "dayjs";

interface CSVDownloaderProps {
  csvData: string;
  startDate?: Date | null;
  endDate?: Date | null;
  type: string;
  id: string;
}

const useCSVDownloader = (_sd?: Date, _ed?: Date) => {
  const [startDate, setStartDate] = useState<Date | null>(_sd ?? null);
  const [endDate, setEndDate] = useState<Date | null>(_ed ?? null);

  const downloadCSV = ({
    csvData,
    type,
    id,
    startDate: startDateParam,
    endDate: endDateParam,
  }: CSVDownloaderProps) => {
    if (csvData && type && id) {
      // Use provided dates or default to state values
      const start = startDateParam || startDate;
      const end = endDateParam || endDate;

      // Create a Blob with the CSV content
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      switch (type) {
        case "dashboard":
          link.download = `Capture-${id}_${dayjs(start).format(
            "DD-MM-YYYY"
          )}-${dayjs(end).format("DD-MM-YYYY")}.csv`;

          break;
        case "store":
          link.download = `Store-${id}_${dayjs(start).format(
            "DD-MM-YYYY"
          )}-${dayjs(end).format("DD-MM-YYYY")}.csv`;
          break;
        case "planogram":
          link.download = `Planogram-${id}.csv`;
          break;
        default:
          break;
      }

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Update state if new dates were provided
      if (startDateParam) setStartDate(startDateParam);
      if (endDateParam) setEndDate(endDateParam);
    }
  };

  return { downloadCSV, setStartDate, setEndDate, startDate, endDate };
};

export default useCSVDownloader;
