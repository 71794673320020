export interface DashboardFilterRequestParams {
  teamId: string;
  // Location
  country?: string | null;
  state?: string[] | null;
  storeId?: string[] | null;

  // Plano
  bayCount?: number | null;
  brand?: string | null;
  category?: string | null;
  subcategory?: string | null;
  manufacturer?: string | null;
  upc?: string | null;
  fixtureType?: string | null;

  // Dates
  startDate: Date | null;
  endDate: Date | null;
}

// Custom style used only for dashboard filter inputs and selects.
export const FilterInputStyle = {
  fontWeight: 500,
  color: 'royalblue'
};

