import axiosInstance from "../axiosInstanceConfig";

export interface TaskType {
  TaskTypeId: string;
  Name: string;
  HasFirstCaptureInstructions: boolean;
  HasMerchandiseInstructions: boolean;
  HasSecondCaptureInstructions: boolean;
  HasFillStep: boolean;
  SecondStepThreshold: number | null;
}

const getTaskTypes = async (_teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", _teamId);

  const response = await axiosInstance.get<TaskType[]>(
    `task/TaskTypeList?${params.toString()}`
  );

  return response.data;
};

export default getTaskTypes;
