import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Alert, AlertTitle, Chip, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import { LoadingButton } from "@mui/lab";
import { InfoRounded } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const ExportDateRangeModal = ({
  open,
  handleClose,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleExport,
  exportIsProcessing,
  info,
}: {
  open: boolean;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (_date: Date) => void;
  setEndDate: (_date: Date) => void;
  handleClose: () => void;
  handleExport: () => void;
  exportIsProcessing?: boolean;
  info?: string;
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          Export CSV
          {info && <Chip icon={<InfoRounded />} color="info" label={info} />}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <DialogContentText>
            Select the date range you would like to export.
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={4}>
              <DatePicker
                disabled={exportIsProcessing}
                format="DD/MM/YYYY"
                onChange={(value) =>
                  value ? setStartDate(value.toDate()) : null
                }
                value={startDate ? startDate : null}
                label="Start Date"
              />

              <DatePicker
                disabled={exportIsProcessing}
                format="DD/MM/YYYY"
                onChange={(value) =>
                  value ? setEndDate(value.toDate()) : null
                }
                value={endDate ? endDate : null}
                label="End Date"
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <LoadingButton
          disabled={startDate === null || endDate === null}
          onClick={() => {
            // If info is provided, show an extra prompt so the user understands that the export may take extra time.
            info &&
              enqueueSnackbar("Export started. Please do not close this window.", {
                variant: "info",
              });
            handleExport();
          }}
          loading={exportIsProcessing}
          variant="contained"
        >
          Export (.csv)
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDateRangeModal;
