import React, { ReactNode } from "react";
import { Card, CardContent, CardHeader, Typography, SxProps } from "@mui/material";

interface AdminStoreLocationCardProps {
    sxStyle?: SxProps;
    title?: string | ReactNode;
    titleStyle?: SxProps;
    contentStyle?: SxProps;
    children: ReactNode;
}

const AdminStoreLocationCard: React.FC<AdminStoreLocationCardProps> = ({
    sxStyle,
    title = "",
    titleStyle,
    contentStyle,
    children
}) => {
    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "solid 0.5px lightgray",
                ...sxStyle
            }}
        >
            {title ? (
                <CardHeader
                    sx={{
                        backgroundColor: "#EEEEEE",
                        px: 0,
                        py: 0,
                        pb: 0,
                        margin: 0,
                        height: "36px",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden",
                        },
                    }}
                    title={
                        typeof title === "object" ? (
                            title
                        ) : (
                            <Typography sx={{ px: 2, fontSize: 16, fontWeight: 600, ...titleStyle }}>{title}</Typography>
                        )
                    }
                />
            ) : null}
            <CardContent
                sx={{
                    height: "100%",
                    p: 4,
                    "&:last-of-type": {
                        pb: 2,
                    },
                    ...contentStyle,
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};

export default AdminStoreLocationCard;
