import axiosInstance from "../axiosInstanceConfig";

export interface AdminUpdateStoreRequestData {
  StoreId: string
  Name: string
  Address: string
  AddressLine2: string | null
  Latitude: number
  Longitude: number
  City: string
  Suburb: string
  State: string | null
  Country: string
  Postcode: string
}

export interface AdminCreateStoreRequestData {
  Name: string;
  Address: string;
  AddressLine2: string | null;
  Suburb: string | null;
  Country: string;
  State: string | null;
  City: string;
  Latitude: number;
  Longitude: number;
  PostCode: string;
}

const postAdminUpdateStore = async (data: AdminUpdateStoreRequestData | AdminCreateStoreRequestData) => {
  const response = await axiosInstance.post<string>(`store/AdminUpdateStore`, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error updating the store. Please contact support.");
  }
};

export default postAdminUpdateStore;
