import { Dayjs } from "dayjs";
import axiosInstance from "../axiosInstanceConfig";

const postStartTrainingBundleExport = async (
  _category: string,
  _startDate?: Dayjs,
  _endDate?: Dayjs,
) => {
  const response = await axiosInstance.post(
    `trainingimage/StartTrainingBundleExport`,
    {
      Category: _category,
      StartDate: _startDate,
      EndDate: _endDate
    }
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "Request for ML Bundle export failed. Please contact support."
    );
  }
};

export default postStartTrainingBundleExport;
