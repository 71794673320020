import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import PaperTitleContainer from "../../../components/PaperTitleContainer";
import ExportDateRangeModal from "../../../components/ExportDateRangeModal";
import dayjs from "dayjs";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";
import { StoreFormInitialValues } from "../types/StoreFormInitialValues";
import { StoreData } from "../types/StoreResponseData";
import URMapkitProvider from "../../dashboard/components/URMapkitProvider";
import StoreLocationCard from "../components/StoreLocationCard";
import StoreLocation from "../../../components/StoreLocation/StoreLocation";
import { FileUploadOutlined, RefreshOutlined } from "@mui/icons-material";
import getStoreExport from "../../../api/stores/getStoreExport";
import getStoreMetrics from "../../../api/stores/getStoreMetrics";
import updateStoreCode from "../../../api/stores/updateStoreCode";
import { LoadingButton } from "@mui/lab";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import getAllStores from "../../../api/stores/getAllStores";
import useLayoutStore from "../../../stores/layout/LayoutStore";

const formInitialValues: StoreFormInitialValues = {
  name: "",
  address: "",
  addressLine2: null,
  latitude: 0,
  longitude: 0,
  city: "",
  suburb: "",
  state: "",
  country: "",
};

const StoresEditIndex = (props: any) => {
  // Util
  const navigate = useNavigate();
  const { downloadCSV, setStartDate, setEndDate, startDate, endDate } =
    useCSVDownloader(dayjs().subtract(1, "year").toDate(), dayjs().toDate());
  const { roleOnTeam } = useTeamStore();
  const { setSubRoute } = useLayoutStore();

  // Destruct Props
  const teamId = props.selectedTeam;

  // Content State
  const [storeId, setStoreId] = useState<string | null>(null);
  const [pageError, setPageError] = useState<string>("");
  const [params, setParams] = useSearchParams();
  const [storeLatitude, setStoreLatitude] = useState<number>(0);
  const [storeLongitude, setStoreLongitude] = useState<number>(0);
  const [csvData, setCsvData] = useState<string | null>(null);
  // Note that formValues.name is a "load bearing" value for the sake of showing loading states.
  const [formValues, setFormValues] =
    useState<StoreFormInitialValues>(formInitialValues);
  const [storeCode, setStoreCode] = useState<string>("");
  const [originalStoreCode, setOriginalStoreCode] = useState<string>("");

  // Modal & Meta State
  const [isStoreExportModalOpen, setIsStoreExportModalOpen] =
    useState<boolean>(false);
  const [exportProcessing, setExportProcessing] = useState<boolean>(false);
  const [codeChangeLoading, setCodeChangeLoading] = useState<boolean>(false);

  // Snackbar
  const [snackEnabled, setSnackEnabled] = useState<boolean>(false);
  const [snackSeverity, setSnackSeverity] = useState<"success" | "error">(
    "success"
  );
  const [snackMessage, setSnackMessage] = useState<string>("");

  useEffect(() => {
    let storeID = params.get("store");
    if (storeID) {
      setStoreId(storeID);
    } else {
      navigate("../stores");
    }
  }, []);

  useEffect(() => {
    const fetchStoreData = async () => {
      if (!teamId || !storeId) return;

      setFormValues(formInitialValues);

      try {
        const data = await getAllStores(teamId);
        if (data) {
          data.forEach((store: StoreData) => {
            if (store.StoreId === storeId) {
              setStoreLatitude(store.Latitude);
              setStoreLongitude(store.Longitude);
              setStoreCode(store.StoreCode);
              setOriginalStoreCode(store.StoreCode);
              composeEditFormData(store);

              setSubRoute({title: store.Name});
            }
          });
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      }
    };
    if (storeId === null || storeId === undefined) {
      return;
    }
    if (teamId === null || teamId === undefined) {
      return;
    }

    fetchStoreData();
  }, [teamId, storeId]);

  useEffect(() => {
    if (!csvData || !storeId) return;

    downloadCSV({
      csvData: csvData,
      type: "store",
      id: storeId,
      startDate: startDate,
      endDate: endDate,
    });

    setCsvData(null);

    return () => {
      setCsvData(null);
    };
  }, [csvData, downloadCSV]);

  const cancelEdit = (e: any) => {
    navigate("../stores");
  };

  const viewStorePlanograms = async (e: any) => {
    if (!storeId || !teamId) {
      return;
    }

    try {
      // TODO: Getting store level metrics from this context is currently unsupported.
      const data = await getStoreMetrics(teamId, storeId);
    } catch (error: any) {
      setPageError(error.message);
      console.log(error.message);
    }
  };

  function composeEditFormData(storeData: StoreData) {
    const values = {
      name: storeData.Name,
      address: storeData.Address,
      addressLine2: storeData.AddressLine2,
      latitude: storeData.Latitude,
      longitude: storeData.Longitude,
      city: storeData.City,
      suburb: storeData.Suburb,
      country: storeData.Country,
      state: storeData.State,
    };

    setFormValues(values);
  }

  function onCloseErrorDialog() {
    setPageError("");
  }

  const handleStoreExport = async () => {
    try {
      setExportProcessing(true);
      const formattedStartDate = dayjs(startDate).toISOString();
      const formattedEndDate = dayjs(endDate).endOf("day").toISOString();

      const data = await getStoreExport(
        teamId,
        storeId!,
        formattedStartDate,
        formattedEndDate
      );
      if (data) {
        setCsvData(data);
        setIsStoreExportModalOpen(false);
      }
    } catch (error) {
      setIsStoreExportModalOpen(false);
      console.error("Error fetching CSV data:", error);
    } finally {
      setExportProcessing(false);
    }
  };

  const handleStoreCodeUpdate = async () => {
    setCodeChangeLoading(true);

    try {
      await updateStoreCode({
        Code: storeCode,
        StoreId: storeId!,
        TeamId: teamId,
      });

      setSnackMessage("Code Updated");
      setSnackSeverity("success");
      setSnackEnabled(true);
    } catch (e: any) {
      setSnackSeverity("error");
      setSnackMessage(e.response.data.detail);
      setSnackEnabled(true);
    } finally {
      setCodeChangeLoading(false);
    }
  };

  return (
    <Container sx={{ padding: 0, margin: 0 }}>
      {/* Success Change Snackbar */}
      <Snackbar
        open={snackEnabled}
        autoHideDuration={3000}
        onClose={() => setSnackEnabled(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={snackSeverity} sx={{ width: "100%" }}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <PaperTitleContainer>
        <Grid container display="flex" flexDirection="row">
          <Grid item md={6}>
            <Typography variant="h6" mb={5}>
              {formValues.name ? (
                `${formValues.name}`
              ) : (
                <CircularProgress size={"1.7rem"} />
              )}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box display="flex" justifyContent="right" sx={{ mb: 5 }}>
              {/* Commenting this button for now as it just console logs the store planograms */}
              {/* <Button
            variant="outlined"
            color="primary"
            onClick={viewStorePlanograms}
            style={{ marginRight: 6 }}
          >
            <TableCellsIcon
              style={{ width: "1.5rem", marginRight: "0.75rem" }}
            />
            View Store Metrics
          </Button> */}
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                startIcon={<FileUploadOutlined />}
                onClick={() => setIsStoreExportModalOpen(true)}
                disabled={!formValues.name}
              >
                Store Metrics (.csv)
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container display="flex" flexDirection="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack>
              <Stack direction="row" gap={2} justifyContent={"space-between"}>
                {formValues?.name ? (
                  <>
                    <TextField
                      label="Store Code"
                      size="small"
                      fullWidth
                      value={storeCode ?? ""}
                      onChange={(e) => setStoreCode(e.target.value)}
                      disabled={!formValues || roleOnTeam !== "admin"}
                    />
                    <LoadingButton
                      loading={codeChangeLoading}
                      startIcon={<RefreshOutlined />}
                      variant="contained"
                      onClick={handleStoreCodeUpdate}
                      disabled={
                        !formValues ||
                        roleOnTeam !== "admin" ||
                        originalStoreCode === storeCode
                      }
                    >
                      Update
                    </LoadingButton>
                  </>
                ) : (
                  <Skeleton variant="rounded" width={"100%"} height={30} />
                )}
              </Stack>

              <Divider sx={{ my: 4 }} />

              {formValues?.name ? (
                <>
                  {/* Address */}
                  <Typography sx={{ fontWeight: 600 }}>Address</Typography>
                  <Typography>{formValues.address}</Typography>
                  <Typography>{formValues.addressLine2}</Typography>
                  <Typography>{formValues.city}</Typography>
                  <Typography>{formValues.suburb}</Typography>{" "}
                </>
              ) : (
                <Skeleton height={120} variant="rounded" width={"100%"} />
              )}
              {/* Address
              <Typography sx={{ fontWeight: 600 }}>Address</Typography>
              <Typography>{formValues.address}</Typography>
              <Typography>{formValues.addressLine2}</Typography>
              <Typography>{formValues.city}</Typography>
              <Typography>{formValues.suburb}</Typography> */}

              {/* Show 'state, country' when available, otherwise just 'country'. */}
              <Typography>
                {formValues.state
                  ? `${formValues.state}, ${formValues.country}`
                  : formValues.country}
              </Typography>
            </Stack>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <StoreLocationCard
                // title="Store Location"
                sxStyle={{
                  height: "100%",
                  minHeight: "320px",
                  width: "100%",
                }}
                contentStyle={{
                  p: 0,
                  "&:last-of-type": { pb: 0 },
                }}
              >
                <URMapkitProvider>
                  <StoreLocation
                    longitude={storeLongitude ?? 0}
                    latitude={storeLatitude ?? 0}
                  />
                </URMapkitProvider>
              </StoreLocationCard>
            </Grid>
          </Grid>
        </Grid>
        <ErrorDialog
          errorMessage={pageError}
          open={pageError.length > 0}
          onClose={onCloseErrorDialog}
        />
      </PaperTitleContainer>
      <ExportDateRangeModal
        setStartDate={(date: Date | null) => setStartDate(date)}
        setEndDate={(date: Date | null) => setEndDate(date)}
        startDate={startDate ? dayjs(startDate) : null}
        endDate={endDate ? dayjs(endDate) : null}
        open={isStoreExportModalOpen}
        handleClose={() => setIsStoreExportModalOpen(false)}
        handleExport={handleStoreExport}
        exportIsProcessing={exportProcessing}
      />
    </Container>
  );
};

export default StoresEditIndex;
