import axiosInstance from "../axiosInstanceConfig";

const postAssignUsertoStore = async (data:AssignUserToStoreRequest) => {
  const response = await axiosInstance.post(
    `store/AssignUserToStore`, data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error assigning the user to the store. Please contact support.");
  }
};

export type AssignUserToStoreRequest = {
  TeamId: string
  UserId: string | null
  StoreId: string,
  StoreAssignmentId?: string,
  Notes: string,
  Deactivated: boolean
}

export default postAssignUsertoStore;
