import { CaptureProductNoInput } from "../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../axiosInstanceConfig";

const getAdminAllProducts = async () => {
  try {
    // Otherwise, call server and set response as the new cache value.
    const response = await axiosInstance.get<CaptureProductNoInput[]>(
      `products/AdminAllProducts`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "There was an error getting the list of products. Please contact support."
    );
  }
};

export default getAdminAllProducts;
