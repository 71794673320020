import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../features/authentication/contexts/AuthContext";
import { FormEvent, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  Stack,
  Alert,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import { urStorePalette } from "../../../themes/urStoreTheme";
import AuthLayoutNew from "../layouts/AuthLayout";
import { LockOpen, LockResetOutlined, Refresh } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const FinishResetDialog = (props: any) => {
  const { open } = props;

  let navigate = useNavigate();

  const handleClose = () => {
    navigate({
      pathname: "../login",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Password reset successfully.
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          return to login page
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function ResetPassword() {
  let auth = useAuthContext();
  let theme = useTheme();

  const [error, setError] = useState<string | null>(null);
  const [codeIsSent, setCodeIsSent] = useState<boolean>(false);
  const [activeEmail, setActiveEmail] = useState<string>("");
  const [displayFinishAlert, setDisplayFinishAlert] = useState<boolean>(false);
  const [pageError, setPageError] = useState<string>("");
  const [validationError, setValidationError] = useState<boolean | null>(null);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);

    if (!codeIsSent) {
      let email = formData.get("email") as string;

      if (email.length < 1) {
        setPageError("must enter an email");
        return;
      }

      auth.forgotPassword(email, (result: any) => {
        if (result.success) {
          setActiveEmail(email);
          setCodeIsSent(true);
        } else {
          enqueueSnackbar("Error", {
            variant: "error",
            cta: "Failed to request reset code. Are your details correct?",
          });
        }
      });
    } else {
      let passcode = formData.get("passcode") as string;
      let newPassword = formData.get("newPassword") as string;
      let passwordConfirmation = formData.get("passwordConfirmation") as string;

      if (!activeEmail || activeEmail.length < 1) {
        setPageError("No email entered, please try again");
        setCodeIsSent(false);
        return;
      }

      if (passcode.length < 1) {
        setPageError("Must enter passcode you recieved in your email!");
        return;
      }

      if (newPassword.length < 1) {
        setPageError("Must enter a new password!");
        return;
      }

      if (newPassword != passwordConfirmation) {
        setPageError("Both passwords must match!");
        return;
      }

      auth.confirmPassword(
        activeEmail,
        passcode,
        newPassword,
        (result: any) => {
          if (result.success) {
            console.info("successfully changed password");
            setDisplayFinishAlert(true);
          } else {
            let err = result.data.err.message;
            if (err) {
              setPageError(err);
            } else {
              setPageError("Failed to change password, please try again.");
            }
          }
        }
      );
    }
  }

  function resendVerificationCode() {
    let email = activeEmail;

    if (email == null || email.length < 1) {
      setPageError("Please re-enter email");
      setCodeIsSent(false);
      return;
    }

    auth.forgotPassword(email, (result: any) => {
      if (result.success) {
        setActiveEmail(email);
        setCodeIsSent(true);
        enqueueSnackbar("Success", {
          variant: "success",
          cta: "A new code has been sent to your email.",
        });
      } else {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Unable to send a new verification code. Please try again later.",
        });
      }
    });
  }

  function getTitle(): string {
    if (!codeIsSent) {
      return "Reset Password";
    } else {
      return "Choose New Password";
    }
  }

  function getInputForm(): JSX.Element {
    if (!codeIsSent) {
      return (
        <form onSubmit={handleSubmit}>
          <Grid container gap={4}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                autoFocus
                onChange={() => {
                  setError(null);
                }}
              />
            </Grid>
            {validationError !== null && validationError ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  Could not reset password for the account, please contact
                  support or try again
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                startIcon={<LockResetOutlined />}
                sx={{
                  backgroundColor: "var(--brand-tertiary)",
                }}
              >
                Request Password Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <Paper
            sx={{
              border: "none",
              backgroundColor: urStorePalette.greys.light,
              p: 4,
              mb: 4,
            }}
          >
            <Typography fontWeight={500}>
              A verification code has been sent to your email.
            </Typography>
          </Paper>
          <Stack gap={2}>
            <Typography fontWeight={500}>Code</Typography>
            <TextField
              sx={{ mb: 2 }}
              name="passcode"
              fullWidth
              autoFocus
              onChange={() => {
                setError(null);
              }}
            />

            <Typography fontWeight={500}>New Password</Typography>
            <TextField
              name="newPassword"
              fullWidth
              type="password"
              sx={{ mb: 2 }}
            />
            <Typography fontWeight={500}>Repeat Password</Typography>
            <TextField
              sx={{ mb: 2 }}
              name="passwordConfirmation"
              fullWidth
              type="password"
            />

            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}

            <Stack direction="row" gap={4}>
              <Button
                fullWidth
                startIcon={<Refresh />}
                variant="outlined"
                onClick={resendVerificationCode}
              >
                Resend Code
              </Button>

              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<LockOpen />}
                fullWidth
                sx={{ backgroundColor: "var(--brand-tertiary)" }}
              >
                Reset Password
              </Button>
            </Stack>
          </Stack>
        </form>
      );
    }
  }

  function onCloseErrorDialog() {
    setPageError("");
  }

  return (
    <AuthLayoutNew>
      <Fade in={true}>
        <Stack
          height={"100%"}
          alignItems={"center"}
          gap={4}
          justifyContent={"center"}
        >
          <Box padding={theme.spacing(8)}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              mb={8}
              fontWeight="600"
            >
              {getTitle()}
            </Typography>

            {/* Form */}
            {getInputForm()}

            {/* Already registered redir */}
            <Grid container mt={4} color={urStorePalette.greys.dark}>
              <Grid item xs={12} textAlign="center">
                <Typography variant="body2" mt={2}>
                  Already registered? <Link to="/login">Log in</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Fade>
      <FinishResetDialog open={displayFinishAlert} />
      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </AuthLayoutNew>
  );
}

export default ResetPassword;
