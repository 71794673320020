import { Box, Paper, Typography } from "@mui/material";
import { urStorePalette } from "../themes/urStoreTheme";
import { ResponsiveContainerStyle } from "../helpers/generalUtilities";

const PaperTitleContainer = (props: any) => {
  return (
    <Box
      sx={ResponsiveContainerStyle}
      display="flex"
      flexDirection="column"
      alignContent="stretch"
    >
      <Typography variant="h5" mb={5}>
        {props.title}
      </Typography>
      <Paper sx={{ padding: 5, background: urStorePalette.greys.lightest }}>{props.children}</Paper>
    </Box>
  );
};

export default PaperTitleContainer;
