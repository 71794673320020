import { useState } from "react";
import { CapturePositionColumn } from "../../models/CaptureAnnotation";
import { CapturePositionAnnotation } from "../../../../models/planograms/AnnotationTypes";

const useImageCroppingHook = () => {
  /** canvas cropped image used for capture box */
  const [savedCroppedImageDataURL, setSavedCroppedImageDataURL] =
    useState<string>("#");
  const [canvasToConvertToABlob, setCanvasToConvertToABlob] =
    useState<HTMLCanvasElement | null>(null);

  const handleImageCrop = (
    mainProductImage: string,
    capturePosition: CapturePositionAnnotation
  ) => {
    const cropCanvas = document.createElement("canvas");
    const cropContext = cropCanvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = mainProductImage;

    image.onload = () => {
      const sourceX =
        capturePosition.MlBoxX !== null && capturePosition.MlBoxWidth !== null
          ? (capturePosition.MlBoxX - capturePosition.MlBoxWidth / 2) *
            image.width
          : capturePosition.Xpos * image.width;
      const sourceY =
        capturePosition.MlBoxY !== null && capturePosition.MlBoxHeight !== null
          ? (capturePosition.MlBoxY - capturePosition.MlBoxHeight / 2) *
            image.height
          : capturePosition.Ypos * image.height;
      const sourceWidth =
        capturePosition.MlBoxWidth !== null
          ? capturePosition.MlBoxWidth * image.width
          : capturePosition.Width * image.width;
      const sourceHeight =
        capturePosition.MlBoxHeight !== null
          ? capturePosition.MlBoxHeight * image.height
          : capturePosition.Height * image.height;

      const destinationX = 0;
      const destinationY = 0;
      const destinationWidth = sourceWidth;
      const destinationHeight = sourceHeight;

      cropCanvas.width = destinationWidth;
      cropCanvas.height = destinationHeight;

      if (cropContext !== null) {
        // Draw the cropped image on the new canvas
        cropContext.drawImage(
          image,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          destinationX,
          destinationY,
          destinationWidth,
          destinationHeight
        );

        // Save the cropped image
        const dataUrl = cropCanvas.toDataURL("image/png");
        setSavedCroppedImageDataURL(dataUrl);
        setCanvasToConvertToABlob(cropCanvas);
      }
    };
  };

  return { handleImageCrop, savedCroppedImageDataURL, canvasToConvertToABlob };
};

export default useImageCroppingHook;
