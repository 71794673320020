import { Capture } from "../../models/planograms/CaptureTypes";
import axiosInstance from "../axiosInstanceConfig";

const getCaptureMetaData = async (captureId: string) => {
  const params = new URLSearchParams();

  params.append('id', captureId);

  const response = await axiosInstance.get<Capture>(
    `capture/GetCaptureMetadata?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error getting the capture metadata. Please contact support.");
  }
};

export default getCaptureMetaData;
