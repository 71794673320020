import { Box, Popover } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import PaperTitleContainer from "../../../components/PaperTitleContainer";
import ErrorDialog from "../../../components/ErrorDialog";
import { urStorePalette } from "../../../themes/urStoreTheme";
import getAdminAllPlanograms from "../../../api/planograms/getAdminAllPlanograms";
import getAdminPlanogramById, { AdminPlanoRevision } from "../../../api/planograms/getAdminPlanogramById";
import postAdminAssignPlanogramToStore from "../../../api/planograms/postAdminAssignPlanogramToStore";
import { PlanogramAssignMenu } from "./components/PlanogramAssignMenu";

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", minWidth: 350 },
  { field: "createdDate", headerName: "Created Date", minWidth: 200 },
];

class DisplayPlanogram {
  constructor(
    public id: string,
    public name: string,
    public createdDate: string
  ) {}
}

const AdminStoresAssignPlanogramPage = (props: any) => {
  const [storeId, setStoreId] = useState<string | null>(null);
  const [planograms, setPlanograms] = useState<any[]>([]);
  const navigate = useNavigate();
  const [addNewPlanogram, setAddNewPlanogram] = useState<boolean>(false);
  const [pageError, setPageError] = useState<string>("");

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    let storeID = params.get("store");
    if (storeID) {
      setStoreId(storeID);
    } else {
      navigate("../admin/stores");
    }

    const fetchPlanograms = async () => {
      try {
        const data = await getAdminAllPlanograms();
        if (data) {
          setPlanograms(
            data.map((planogram: any) => {
              if (planogram.PlanogramId !== null) {
                return new DisplayPlanogram(
                  planogram.PlanogramId,
                  planogram.Name,
                  new Date(planogram.CreatedAt).toLocaleString()
                );
              }
            })
          );
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      }
    };
    fetchPlanograms();
  }, []);

  const assignPlanogramToStore = async (latestRevision: {
    LastModified: Date;
    PlanogramRevisionId: string;
  }) => {
    if (latestRevision) {
      const requestData = {
        PlanogramRevisionId: latestRevision.PlanogramRevisionId,
        StoreId: storeId!,
        Notes: "",
        Direction: false,
        Deactivated: false,
      };

      try {
        const data = await postAdminAssignPlanogramToStore(requestData);
        if (data) {
          navigate({
            pathname: "../admin/stores/edit",
            search: "?store=" + storeId,
          });
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      }
    }
  };

  const handleRowClickEvent: GridEventListener<"rowClick"> = async (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    let planogramID = params.row.id;

    if (!planogramID) {
      setPageError("Cannot open planogram without an ID!");
      return;
    }

    try {
      const data = await getAdminPlanogramById(planogramID);
      if (data) {
        let latestRevision: AdminPlanoRevision | null = null;
        if (data.Revisions && data.Revisions.length > 0) {
          latestRevision = data.Revisions[0];
        }

        if (latestRevision) {
          assignPlanogramToStore(latestRevision);
        }
      }
    } catch (err: any) {
      setPageError(err.message);
      console.log(err.message);
    }
  };

  function cancelAssignment() {
    navigate({
      pathname: "../admin/stores/edit",
      search: "?store=" + storeId,
    });
  }

  function onCloseErrorDialog() {
    setPageError("");
  }

  return (
    <PaperTitleContainer title="Assign Planogram to Store">
      
      <Box display="flex" justifyContent="right" mb={4}>
        <Button variant="contained" onClick={cancelAssignment}>
          Cancel
        </Button>
      </Box>
      <DataGrid
        getRowId={(row) => row.id}
        style={{
          padding: 5,
          height: 1200,
          width: "100%",
          background: urStorePalette.greys.lightest,
        }}
        rows={planograms}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        onRowClick={handleRowClickEvent}
        components={{ Toolbar: GridToolbarQuickFilter }}
      />
      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </PaperTitleContainer>
  );
};

export default AdminStoresAssignPlanogramPage;
