import { Chip } from "@mui/material";
import { urStorePalette } from "../../../../themes/urStoreTheme";

// This is neeeded to passthrough custom styles to the tag.
export default function MultiTag(tagProps: any) {
  return (
    <Chip
      {...tagProps}
      sx={{
        color: "royalblue",
        fontWeight: 600,
        backgroundColor: urStorePalette.greys.light,
      }}
    />
  );
}
