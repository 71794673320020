import { StoreData } from "../../features/stores/types/StoreResponseData";
import axiosInstance from "../axiosInstanceConfig";

const getAdminAllStores = async () => {
  const response = await axiosInstance.get<StoreData[]>(`store/AdminAllStores`);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of stores. Please contact support."
    );
  }
};

export default getAdminAllStores;
