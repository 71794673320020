import { Grid, Typography } from "@mui/material";
import { urStorePalette } from "../../../themes/urStoreTheme";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";

function MerchandiserView() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <DoNotDisturbOnOutlinedIcon
          sx={{ fontSize: "4rem", color: urStorePalette.greys.default }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h5" fontWeight="bold">
          No Access
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h6"
          color={`${urStorePalette.greys.dark}`}
          fontWeight="bold"
          sx={{ maxWidth: "30rem", textAlign: "center" }}
        >
          You do not have access to reporting in this team. Please contact your
          team leader for access.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default MerchandiserView;
