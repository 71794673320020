import TeamMemberStore from "../../features/team/types/TeamMemberStore";
import axiosInstance from "../axiosInstanceConfig";

const getTeamMemberStores = async (teamId: string, userId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);
  params.append("userId", userId);
  params.append("includeDeactivated", "true"); // Always set to true, not handled by a func arg.

  const response = await axiosInstance.get<TeamMemberStore[]>(
    `store/StoresForTeamMember?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the stores. Please contact support."
    );
  }
};

export default getTeamMemberStores;
