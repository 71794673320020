import {
  Box,
  Button,
  Checkbox,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getTeamMembers from "../../../api/teams/getTeamMembers";
import getTeamMemberStores from "../../../api/teams/getTeamMemberStores";
import TeamMemberStore from "../types/TeamMemberStore";
import postAssignUsertoStore from "../../../api/teams/postAssignUserToStore";
import { TeamMember } from "../../../stores/teams/TeamMemberStore";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { useSnackbar } from "notistack";
import { AddCircleOutline, CopyAll } from "@mui/icons-material";
import AssignStoreToUserModal from "../components/AssignStoreToUserModal";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { urStorePalette } from "../../../themes/urStoreTheme";

const TeamEditPage = (props: any) => {
  const selectedTeam = props.selectedTeam;
  const { enqueueSnackbar } = useSnackbar();
  const { roleOnTeam } = useTeamStore();
  const { setSubRoute } = useLayoutStore();

  const [userId, setUserId] = useState<string | null>(null);
  const [teamMember, setTeamMember] = useState<TeamMember | null>(null);
  const [params, setParams] = useSearchParams();
  const [isAssignStoreDialogOpen, setIsAssignStoreDialogOpen] =
    useState<boolean>(false);

  const [assignedStores, setAssignedStores] = useState<TeamMemberStore[]>([]);
  const [storesChecked, setStoresChecked] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedStoreId, setSelectedStoreId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [storeAssignLoading, setStoreAssignLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    let userId = params.get("userId");
    if (userId) {
      setUserId(userId);
    } else {
      navigate("../team");
    }
  }, []);

  const fetchTeamMemberStores = async () => {
    setLoading(true);
    if (userId) {
      const data = await getTeamMemberStores(selectedTeam, userId);

      setLoading(false);

      if (data) {
        setAssignedStores(data);
        data.forEach((store: TeamMemberStore) => {
          storesChecked[store.StoreId] = !store.Deactivated;
        });
      }
    }
  };

  useEffect(() => {
    if (selectedTeam && userId) {
      const fetchTeamMembers = async () => {
        try {
          const teamMembersData = await getTeamMembers(selectedTeam);
          if (teamMembersData) {
            teamMembersData.forEach((teamMember: TeamMember) => {
              if (teamMember.UserId === userId) {
                setTeamMember(teamMember);
                setSubRoute({ title: teamMember.Fullname ?? teamMember.Email });
              }
            });
          }
        } catch (error: any) {
          console.log(error.message);
        }
      };

      fetchTeamMembers();
      fetchTeamMemberStores();
    }
  }, [selectedTeam, userId]);

  async function onStoreCheckboxToggled(storeId: string, checked: boolean) {
    let selectedStore: TeamMemberStore | null = null;
    assignedStores.forEach((store) => {
      if (store.StoreId === storeId) {
        selectedStore = store;
      }
    });

    if (!selectedStore) {
      return;
    }

    const requestData = {
      TeamId: selectedTeam,
      UserId: userId as string,
      StoreId: storeId,
      StoreAssignmentId: (selectedStore &&
        (selectedStore as TeamMemberStore).StoreAssignmentId) as string,
      Notes: "",
      Deactivated: checked,
    };

    try {
      const data = await postAssignUsertoStore(requestData);
      if (data) {
        enqueueSnackbar("Success", {
          variant: "success",
          cta: `${!checked ? "Activated" : "Deactivated"} Store.`,
        });

        fetchTeamMemberStores();
      }
    } catch (error: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: `Failed to ${!checked ? "Activate" : "Deactivate"} Store.`,
      });
    }
  }

  async function assignStoreToUser(storeId: string) {
    setSelectedStoreId(storeId);
    setStoreAssignLoading(true);
    const requestData = {
      TeamId: selectedTeam,
      UserId: userId,
      StoreId: storeId,
      Notes: "",
      Deactivated: false,
    };

    try {
      const data = await postAssignUsertoStore(requestData);
      if (data) {
        setStoreAssignLoading(false);
        enqueueSnackbar("Success", {
          variant: "success",
          cta: `Store assigned to user.`,
        });
        fetchTeamMemberStores();
        setSelectedStoreId("");
      }
    } catch (error: any) {
      setStoreAssignLoading(false);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: `Failed to assign store.`,
      });
      console.log(error.message);
    }
  }

  const copyInviteCode = async () => {
    if(!userId) return;
    await navigator.clipboard.writeText(userId);
    enqueueSnackbar("Invite code copied to clipboard", {
      variant: "success",
    });
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <Box gap={2} sx={{ display: "flex" }} justifyContent={"space-between"}>
        <Typography variant="h6">
          {teamMember?.Fullname || teamMember?.Email}
        </Typography>
      </Box>
      <Box
        gap={2}
        sx={{ display: "flex", mb: 3 }}
        justifyContent={"space-between"}
      >
        <Stack direction="row">
          {teamMember?.Fullname ? (
            <Typography variant="h6">
              {
                <Chip
                  size="small"
                  label={
                    <>
                      <b>Email: </b> {teamMember?.Email || "-"}
                    </>
                  }
                />
              }
            </Typography>
          ) : null}
          <Typography variant="h6">
            {
              <Chip
                size="small"
                sx={{ ml: teamMember?.Fullname ? 4 : 0 }}
                label={
                  <>
                    <b>Role: </b> {teamMember?.Role || "-"}
                  </>
                }
              />
            }
          </Typography>
          <Typography variant="h6">
            <Chip
              size="small"
              sx={{ ml: 4 }}
              label={
                <>
                  <b>Created: </b>{" "}
                  {(teamMember &&
                    teamMember.JoinedTeam &&
                    formatDisplayDate(new Date(teamMember?.JoinedTeam))) ||
                    "-"}
                </>
              }
            />
          </Typography>
          <Typography
            sx={{
              cursor: "pointer",
            }}
            onClick={copyInviteCode}
            variant="h6"
          >
            <Tooltip title="Copy invite code" placement="top" arrow>
              <Chip
                size="small"
                sx={{ ml: 4, "&:hover": {background: urStorePalette.greys.light} }}
                label={
                  <Stack direction="row" gap={1} display="flex">
                    <div
                      style={{ display: "flex", alignItems: "center"}}
                    >
                      <b>Invite Code: </b> {" "}{userId || "-"}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CopyAll sx={{ fontSize: 20}} />
                    </div>
                  </Stack>
                }
              />
            </Tooltip>
          </Typography>
        </Stack>
      </Box>
      <Box sx={{height : "100vh"}}>
      <AgGridWrapper
        id="team-stores"
        columnDefs={[
          {
            headerName: "Store",
            field: "Name",
            flex: 1,
            minWidth: 200,
          },
          {
            headerName: "Activated",
            field: "Deactivated",
            flex: 1,
            minWidth: 100,
            cellRenderer: (row: any) => {
              console.log(row);
              return (
                <Box sx={{ width: "100%" }} alignItems="center">
                  <Checkbox
                    disabled={roleOnTeam !== "admin"}
                    checked={!row.data.Deactivated}
                    onClick={() =>
                      onStoreCheckboxToggled(
                        row.data.StoreId,
                        !row.data.Deactivated
                      )
                    }
                  />
                </Box>
              );
            },
          },
        ]}
        rowData={assignedStores}
        loading={loading}
        quickSearch
        plugins={
          <Button
            disabled={roleOnTeam !== "admin"}
            startIcon={<AddCircleOutline />}
            variant="contained"
            onClick={() => setIsAssignStoreDialogOpen(true)}
          >
            Assign Store to User
          </Button>
        }
        hidePagination
      />
      </Box>
      
      <AssignStoreToUserModal
        selectedId={selectedStoreId}
        loading={storeAssignLoading}
        userId={userId!}
        selectedTeam={selectedTeam}
        open={isAssignStoreDialogOpen}
        handleClose={() => setIsAssignStoreDialogOpen(false)}
        handleAssignStore={assignStoreToUser}
        teamMemberStores={assignedStores}
      />
    </Box>
  );
};

export default TeamEditPage;
