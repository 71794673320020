import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  FileUploadOutlined,
  ReplayRounded,
  Search,
  ViewColumnRounded,
} from "@mui/icons-material";
import { urStorePalette } from "../../themes/urStoreTheme";
import { ColDef } from "ag-grid-community";

interface AgGridWrapperProps<T> {
  // Required
  id: string; // Must be unique from other AgGridWrapper instances app-wide. Used for internal state tracking/memory.
  columnDefs: ColDef<T>[]; // Any array of iterable ColDef objects.
  rowData: T[]; // Any array of iterable objects.

  // Events
  onRowClicked?: (_row: T) => void;

  // Style Overrides
  height?: string; // Custom height expressed as css.
  width?: string; // Custom width expressed as css.
  rowHeight?: number; // Optionally force a fixed row height. This should be used sparingly, usually autoRowHeight on the column def will be sufficient.

  // Features
  quickSearch?: boolean; // When true, shows a simple global search above the grid.
  gridTitle?: string; // Display a title above the grid. Use sparingly!
  export?: boolean; // Shows an export control above the grid. Note that this will not correctly handle custom render cells.
  plugins?: React.ReactNode; // Displays right-aligned content above the grid.
  rememberPageNumber?: boolean; // Page number is stored in localStorage and will be jumped to when revisiting this grid instance.
  loading?: boolean; // While true, show a inline loading progress bar. Managed externally.
  hidePagination?: boolean; // Disables pagination controls. Use if you are externally managing pagination. Not compatible with rememberPageNumber!
  disableRowAnimation?: boolean; // Disables potentially jarring animations when rows resize/sort. Useful on pages with custom/recalculating row heights.
}

/*
  This is a wrapper component that unifies usage and styling for AG Grid 
  within this project. It includes a reasonable default size, and reduces the amount
  of imports that need to be defined on each file. It also includes commonly requested features
  that can be opted into - like searching, grid titling, exporting, and pagination memory.

  Please consult Dec before extending the scope of this table, as it is designed
  to be deliberately restrained and only include essential features to force best practice when
  using DataGrids throughout the application.
*/
export const AgGridWrapper = <T,>(props: AgGridWrapperProps<T>) => {
  // Ref
  const gridRef = useRef();

  // Search State
  const [quickSearchText, setQuickSearchText] = useState<string>("");

  // Column State
  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(
    /*
      Column State will try to restore from session storage where possible, otherwise will use
      the provided column defs.
    */
    sessionStorage.getItem(`${props.id}-visible-columns`)
      ? new Set(
          JSON.parse(sessionStorage.getItem(`${props.id}-visible-columns`)!)
        )
      : new Set(props.columnDefs.map((col) => col.field!))
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const columnMenuOpen = Boolean(anchorEl);

  // Column Saving Effects
  useEffect(() => {
    // When user changes their columns, persist their settings to storage.
    sessionStorage.setItem(
      `${props.id}-visible-columns`,
      JSON.stringify(Array.from(visibleColumns))
    );
  }, [visibleColumns]);

  // Functions
  function renderQuickSearch() {
    return (
      <TextField
        id="grid-search"
        onChange={(e) => setQuickSearchText(e.target.value)}
        variant={"outlined"}
        size="small"
        placeholder="Search"
        InputProps={{
          sx: {
            borderRadius: "8px",
            background: urStorePalette.greys.lightest,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  function renderGridTitle() {
    return <Typography variant="h6">{props.gridTitle}</Typography>;
  }

  function renderExportButton() {
    return (
      <Tooltip title="Export">
        <IconButton
          /* @ts-expect-error Deliberately bypassed. See comment in final render for info. */
          onClick={() => gridRef?.current?.api.exportDataAsCsv()}
        >
          <FileUploadOutlined />
        </IconButton>
      </Tooltip>
    );
  }

  function renderLoadingIndicator() {
    /*
      Unlike other features the wrapper supports, the load indicator always mounts regardless
      of whether or not prop.loading is provided. This is because in order for the fade out transition
      to work correctly, a node for this component must always be in the DOM and just switch
      between display: none and display: inherit as needed rather than totally unmounting.
    */

    // Recommended: 6 to sit inline with borderRad of containing grid.
    const LOAD_OVERLAY_HEIGHT = 6;

    // Also handle display of Grid API's loading overlay.
    props.loading
      ? /* @ts-expect-error Deliberately bypassed. */
        gridRef?.current?.api?.showLoadingOverlay()
      : /* @ts-expect-error Deliberately bypassed. */
        gridRef?.current?.api?.hideOverlay();

    return (
      <Fade in={props.loading}>
        <LinearProgress
          sx={{
            mx: "auto",
            height: `${LOAD_OVERLAY_HEIGHT}px`,
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            marginBottom: `-${LOAD_OVERLAY_HEIGHT}px`,
            zIndex: 1, // Required to sit above the grid itself.
          }}
          color="secondary"
        />
      </Fade>
    );
  }

  function renderColumnSelector() {
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (field: string) => {
      handleColumnVisibilityChange(field);
    };

    return (
      <>
        {/* Button Control */}
        <Tooltip title="Show/Hide Columns">
          {/* Shows a badge on this control if the user has opted to adjust their columns. */}
          <Badge
            variant="dot"
            invisible={props.columnDefs.length === visibleColumns.size}
            sx={{
              "& .MuiBadge-badge": {
                top: 6,
                right: 6,
              },
            }}
            color="error"
          >
            <IconButton onClick={handleMenuOpen}>
              <ViewColumnRounded />
            </IconButton>
          </Badge>
        </Tooltip>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={columnMenuOpen}
          onClose={handleMenuClose}
        >
          <FormGroup>
            {props.columnDefs.map((col) => (
              <MenuItem
                dense
                key={col.field}
                onClick={() => handleMenuItemClick(col.field!)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ height: 28, fontWeight: 500 }}
                      checked={visibleColumns.has(col.field!)}
                      onChange={() => handleColumnVisibilityChange(col.field!)}
                    />
                  }
                  // Label will prefer to show headerName where available.
                  label={col.headerName ?? col.field}
                  onClick={(e) => e.stopPropagation()}
                />
              </MenuItem>
            ))}
          </FormGroup>

          <Box display="flex" justifyContent={"center"}>
            <Button
              color="error"
              disabled={props.columnDefs.length === visibleColumns.size}
              sx={{ width: "160px" }}
              startIcon={<ReplayRounded />}
              onClick={() =>
                setVisibleColumns(
                  new Set(props.columnDefs.map((col) => col.field!))
                )
              }
            >
              Reset Columns
            </Button>
          </Box>
        </Menu>
      </>
    );
  }

  function handleColumnVisibilityChange(field: string) {
    const newVisibleColumns = new Set(visibleColumns);
    if (newVisibleColumns.has(field)) {
      newVisibleColumns.delete(field);
    } else {
      newVisibleColumns.add(field);
    }
    setVisibleColumns(newVisibleColumns);
  }

  function setStoredPage(params: any) {
    if (params.newPage) {
      let currentPage = params.api.paginationGetCurrentPage();
      sessionStorage.setItem(
        `${props.id}-current-page`,
        JSON.stringify(currentPage)
      );
    }
  }

  function goToStoredPage(params: any) {
    const pageToNavigate: number = JSON.parse(
      //@ts-expect-error Failure to retrieve this key will safely default the grid back to page 1.
      sessionStorage.getItem(`${props.id}-current-page`)
    ) as number;

    params.api.paginationGoToPage(pageToNavigate ?? 0);
  }

  const filteredColumnDefs = props.columnDefs.filter((col) =>
    visibleColumns.has(col.field!)
  );

  // Final Render
  return (
    <Box>
      {/* 
        Stack that contains contextual actions above the grid. 
        Fixed features (like title, search) are always left-aligned. 
        Custom plugins (like actions) are always right-aligned. 
       */}
      <Grid container gap={4} justifyContent={"space-between"} sx={{ mb: 4 }}>
        {/* Grid item containing integrated features. */}
        <Grid item>
          <Stack direction="row" gap={2} alignItems={"center"}>
            {/* GridTitle */}
            {props.gridTitle && renderGridTitle()}

            {/* QuickSearch */}
            {props.quickSearch && renderQuickSearch()}

            {/* Column Filter */}
            {renderColumnSelector()}

            {/* Export */}
            {props.export && renderExportButton()}
          </Stack>
        </Grid>

        {/* Grid item containing additional plugins. */}
        <Grid item>{props.plugins}</Grid>
      </Grid>

      {/* Container for the Grid itself. Do not touch! */}
      <div
        className="ag-theme-quartz"
        style={{ height: props.height ?? "calc(100vh - 146px)" }}
      >
        {/* Loading Indicator*/}
        {renderLoadingIndicator()}

        {/* Grid Core */}
        <AgGridReact
          // @ts-expect-error AgGrid Free uses a legacy ref type not supported by current React, but it still actually works.
          ref={gridRef}
          columnDefs={filteredColumnDefs}
          defaultColDef={{
            cellStyle: () => ({
              display: "flex",
              alignItems: "center",
            }),
          }}
          suppressMovableColumns
          pagination={!props.hidePagination}
          rowData={props.rowData}
          autoSizeStrategy={{
            // Fits available width of the containing grid element.
            type: "fitGridWidth",
            // Note that specifying a specific column min width will override this.
            defaultMinWidth: 100,
          }}
          quickFilterText={quickSearchText}
          overlayLoadingTemplate="Loading..."
          overlayNoRowsTemplate="No Rows"
          suppressDragLeaveHidesColumns
          // Props related to rememberPageNumber
          {...(props.rememberPageNumber && {
            onPaginationChanged: (params) => setStoredPage(params),
            onFirstDataRendered: (params) => goToStoredPage(params),
          })}
          // Props related to event management
          {...(props.onRowClicked && { onRowClicked: props.onRowClicked })}
          // Misc Settings
          {...(props.disableRowAnimation && { animateRows: false })}
          {...(props.rowHeight && { rowHeight: props.rowHeight })}
        />
      </div>
    </Box>
  );
};
