import { Box, Button, Checkbox, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import { AddCircleOutline, ManageAccounts } from "@mui/icons-material";
import getUserTeams from "../../../api/teams/getUserTeams";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { ManageTeamMemberDialog } from "../components/ManageTeamMemberModal/ManageTeamMemberDialog";
import { OpenInBrowserOutlined } from "@mui/icons-material";
import { ToggleUserActivationModal } from "../components/ToggleUserActivationModal";
import { UrStoreTeam, useTeamStore } from "../../../stores/teams/TeamStore";
import useTeamMemberStore, {
  TeamMember,
} from "../../../stores/teams/TeamMemberStore";
import { ColDef } from "ag-grid-community";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

interface TeamMemberColumn extends TeamMember {
  Actions?: null;
}

const TeamIndex = (props: any) => {
  // Store
  const { roleOnTeam } = useTeamStore();

  // Prop Destructuring
  const selectedTeam = props.selectedTeam;

  // Util
  const navigate = useNavigate();
  const colDefs: ColDef<TeamMemberColumn>[] = [
    { field: "Email", flex: 1 },
    {
      field: "Fullname",
      flex: 1,
      headerName: "Full Name",
      cellRenderer: (row: any) => row.data.Fullname,
    },
    {
      field: "Role",
      flex: 0.5,
      cellRenderer: (data: any) => (
        <Chip size="small" label={data.value} variant="outlined" />
      ),
    },
    {
      field: "JoinedTeam",
      flex: 0.5,
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "Active",
      sortable: false,
      flex: 0.2,
      cellRenderer: (row: any) => (
        <Box sx={{ width: "100%" }} alignItems="center">
          <Checkbox
            disabled={roleOnTeam !== "admin"}
            checked={row.data.Active}
            onClick={() => openToggleUserActivationModal(row.data)}
          />
        </Box>
      ),
    },
    {
      field: "Actions",
      sortable: false,
      flex: 0.5,
      minWidth: 230,
      cellRenderer: (row: any) => (
        <Box>
          <Button
            disabled={roleOnTeam !== "admin"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<OpenInBrowserOutlined />}
            onClick={() => {
              openTeamMemberPage(row.data.UserId);
            }}
          >
            Open
          </Button>
          <Button
            disabled={roleOnTeam !== "admin"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<ManageAccounts />}
            onClick={() => {
              openManageTeamModal("edit", row.data);
              console.log(row.data);
            }}
          >
            Permissions
          </Button>
        </Box>
      ),
    },
  ];

  const { teamMembers, fetchTeamMembers } = useTeamMemberStore();

  // State
  const [teamName, setTeamName] = useState<string | null>(null);
  const [createdDate, setCreatedDate] = useState<string | null>(null);
  const [pageError, setPageError] = useState<string>("");
  const [usersUpToDate, setUsersUpToDate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isManageTeamModalOpen, setIsManageTeamModalOpen] =
    useState<boolean>(false);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState<boolean>(false);
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMember | null>(null);
  const [manageType, setManageType] = useState<"add" | "edit" | null>(null);

  // Effects
  useEffect(() => {
    const fetchUserTeams = async () => {
      if (!selectedTeam) {
        return;
      }
      try {
        setLoading(true);
        const data = await getUserTeams();
        if (data) {
          let foundTeam = false;

          data.forEach((team: any) => {
            if (team.TeamId === selectedTeam) {
              setTeamName(team.Name);
              setCreatedDate(team.TeamCreatedAt);
              foundTeam = true;
            }
          });

          if (!foundTeam) {
            setTeamName(null);
            setCreatedDate(null);
          }
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      }finally{
        setLoading(false);
      }
    };

    fetchUserTeams();
    fetchTeamMembers(selectedTeam);
  }, [selectedTeam, usersUpToDate]);

  // Functions
  function onCloseErrorDialog() {
    setPageError("");
  }

  function openAddTeamMemberDialog() {
    setManageType("add");
    setIsManageTeamModalOpen(true);
  }

  function openTeamMemberPage(userId: string) {
    navigate({
      pathname: "./edit",
      search: "?userId=" + userId,
    });
  }

  function openManageTeamModal(type: "add" | "edit", teamMember?: TeamMember) {
    setManageType(type);
    if (teamMember) {
      setSelectedTeamMember(teamMember);
    }
    setIsManageTeamModalOpen(true);
  }

  function openToggleUserActivationModal(teamMember: TeamMember) {
    setSelectedTeamMember(teamMember);
    setIsDeactivateUserModalOpen(true);
  }

  return (
    <>
      <Box sx={ResponsiveContainerStyle}>
        <AgGridWrapper
          id="team-members"
          rowData={teamMembers}
          columnDefs={colDefs}
          quickSearch
          rememberPageNumber
          gridTitle={teamName ?? ""}
          loading={loading}
          plugins={
            <>
              <Chip
                sx={{ mr: 4 }}
                label={
                  <>
                    <b>Created: </b>
                    {createdDate && formatDisplayDate(new Date(createdDate))}
                  </>
                }
              />
              <Button
                variant="contained"
                onClick={openAddTeamMemberDialog}
                startIcon={<AddCircleOutline />}
                disabled={roleOnTeam !== 'admin'}
              >
                Add User to Team
              </Button>
            </>
          }
        />
      </Box>

      <ManageTeamMemberDialog
        type={manageType!}
        teamId={selectedTeam}
        onClose={() => {
          setIsManageTeamModalOpen(false);
          setSelectedTeamMember(null);
        }}
        teamMember={selectedTeamMember!}
        open={isManageTeamModalOpen}
        teamName={teamName!}
        reloadTeamMembers={() => fetchTeamMembers(selectedTeam)}
      />

      {/* <ManageTeamMemberModal
        open={isManageUserModalOpen}
        onClose={() => {
            setIsManageUserModalOpen(false);
            setSelectedTeamMember(null);
          }
        }
        teamMember={selectedTeamMember!}
        teamId={selectedTeam}
        reloadTeamMembers={fetchTeamMembers}
      /> */}

      <ToggleUserActivationModal
        user={selectedTeamMember}
        open={isDeactivateUserModalOpen}
        onClose={() => {
          setIsDeactivateUserModalOpen(false);
          setSelectedTeamMember(null);
        }}
        reloadTeamMembers={() => fetchTeamMembers(selectedTeam)}
        selectedTeam={selectedTeam}
      />

      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </>
  );
};

export default TeamIndex;
