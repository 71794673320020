import { SvgIconComponent } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export interface ProductButtonType {
  id: number;
  label: string;
  status: number;
  icon: SvgIconComponent;
  color: string;
}

export const productButtonStates: ProductButtonType[] = [
  {
    id: 0,
    label: "In Stock",
    icon: CheckCircleOutlineIcon,
    status: 0,
    color: 'success',
  },
  {
    id: 2,
    label: "Out of Stock",
    icon: CheckBoxOutlineBlankIcon,
    status: 2,
    color: "error",
  },
  {
    id: 1,
    label: "Wrong Product",
    icon: HighlightOffIcon,
    status: 1,
    color: "warning",
  },
];