import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { roles } from "../../utils/strings";
import postAddUsertoTeam from "../../../../api/teams/postAddUserToTeam";
import postUpdateUserAccessLevel from "../../../../api/teams/postUpdateUserAccessLevel";
import { LoadingButton } from "@mui/lab";
import { TeamMember } from "../../../../stores/teams/TeamMemberStore";
import { green, grey } from "@mui/material/colors";
import { enqueueSnackbar } from "notistack";

interface ManageTeamMemberDialogProps {
  open: boolean;
  teamName: string;
  teamId: string;
  teamMember?: TeamMember;
  onClose: () => void;
  type: "add" | "edit";
  reloadTeamMembers: () => void;
}

export const ManageTeamMemberDialog = (props: ManageTeamMemberDialogProps) => {
  // State
  const [userInviteCode, setUserInviteCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedRole, setSelectedRole] = useState<string>(
    props.teamMember?.Role || ""
  );

  // Effects
  useEffect(() => {
    if (props.teamMember) {
      setSelectedRole(props.teamMember.Role || "");
    }
  }, [props.teamMember]);

  useEffect(() => {
    if (props.open === false) {
      setSelectedRole("");
    }
  }, [props.open]);

  // Functions
  const handleAddUserToTeam = async () => {
    const requestData = {
      TeamId: props.teamId,
      UserId: userInviteCode,
      Role: selectedRole,
    };

    try {
      setLoading(true);
      await postAddUsertoTeam(requestData);
      enqueueSnackbar("", { cta: "User Added", variant: "success" });
      onSuccessRequest();
    } catch (error: any) {
      enqueueSnackbar("", { cta: "Failed to add User", variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEditRole = async () => {
    const requestData = {
      TeamId: props.teamId,
      UserId: props.teamMember?.UserId || "",
      Role: selectedRole,
    };

    try {
      setLoading(true);
      await postUpdateUserAccessLevel(requestData);
      enqueueSnackbar("", { cta: "Role Updated", variant: "success" });
      onSuccessRequest();
    } catch (error: any) {
      enqueueSnackbar("", { cta: "Role Change Failed", variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const onSuccessRequest = () => {
    props.reloadTeamMembers();
    props.onClose();
    setLoading(false);
  };

  const handleSubmit = () => {
    if (props.type === "add") {
      handleAddUserToTeam();
    }

    if (props.type === "edit") {
      handleEditRole();
    }
  };

  const handleCancel = () => {
    resetState();
    props.onClose();
  };

  function resetState() {
    setUserInviteCode("");
    setSelectedRole("");
  }

  // Final Render
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
        {/* Headers - Depends on context of the dialog. */}
        {props.type === "add" && (
          <DialogTitle sx={{ fontSize: 18 }}>
            Add User to <b>{props.teamName}</b>
          </DialogTitle>
        )}

        {props.type === "edit" && (
          <DialogTitle sx={{ fontSize: 18 }}>
            <b>{props.teamMember && props.teamMember.Email}</b>
          </DialogTitle>
        )}

        <DialogContent>
          {/* Code Field. */}
          {props.type === "add" && (
            <TextField
              autoFocus
              margin="dense"
              id="userInviteCode"
              label="Invite Code"
              onChange={(e) => setUserInviteCode(e.target.value)}
              type="text"
              fullWidth
            />
          )}

          {/* Role mgmt. */}

          <Stack
            direction="row"
            gap={2}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            {roles.map((r, i) => (
              <Paper
                onClick={() => setSelectedRole(r.id)}
                key={r.id}
                sx={{
                  width: "33%",
                  p: 4,
                  transition: "0.1s all ease-in-out",
                  border:
                    selectedRole === r.id ? "none" : `0.5px solid ${grey[400]}`,
                  backgroundColor:
                    selectedRole === r.id ? green[50] : "inherit",
                  ":hover": {
                    cursor: "pointer",
                    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
                    transform: "scale(1.02)",
                  },
                }}
              >
                <Stack sx={{ height: "auto" }}>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 18,
                      }}
                    >
                      {roles[i].display}
                    </Typography>
                    {selectedRole === r.id && (
                      <CheckCircle fontSize="medium" color="success" />
                    )}
                  </Stack>
                  <ul
                    style={{
                      paddingLeft: "16px",
                      overflowY: "auto",
                    }}
                  >
                    {roles[i]?.description.map((desc) => (
                      <li key={desc.text}>
                        <Typography
                          variant="caption"
                          color={desc.color as "primary" | "error"}
                        >
                          {desc.text}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="error" onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            disabled={
              (props.type === "add" && userInviteCode === "") ||
              selectedRole === "" ||
              (props.type === "edit" && selectedRole === "") ||
              selectedRole === props.teamMember?.Role
            }
            onClick={handleSubmit}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
