import { Button, Fade, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { useEffect } from "react";
import { HowToRegOutlined, LoginOutlined } from "@mui/icons-material";
import AuthLayoutNew from "../layouts/AuthLayout";

function LandingPage() {
  let auth = useAuthContext();
  let navigate = useNavigate();

  useEffect(() => {
    // If the user is already logged in, send to dashboard.
    if (auth.user) {
      console.info("Existing session available. Redirecting to /dashboard");
      navigate("/dashboard", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayoutNew>
      <Fade in={true}>
        <Stack
          height={"100%"}
          alignItems={"center"}
          gap={4}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            component={Link}
            to={"/login"}
            size="large"
            fullWidth
            sx={{
              minWidth: "12rem",
              backgroundColor: "var(--brand-tertiary)",
            }}
            startIcon={<LoginOutlined />}
          >
            Log In
          </Button>
          <Button
            variant="outlined"
            size="large"
            component={Link}
            fullWidth
            to={"/register"}
            startIcon={<HowToRegOutlined />}
          >
            Sign Up
          </Button>
        </Stack>
      </Fade>
    </AuthLayoutNew>
  );
}

export default LandingPage;
