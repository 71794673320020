import { SxProps } from "@mui/material";
import { DashboardFilterRequestParams } from "../features/dashboard/helpers/DashboardHelpers";

type VisibilityState = "visible" | "hidden";

function visibleIf(statementIsTrue: boolean): VisibilityState {
  if (statementIsTrue) {
    return "visible";
  } else {
    return "hidden";
  }
}

function convertBytesToMBString(
  bytes: number,
  decimalPlaces: number = 2
): string {
  return (bytes / 1000000).toFixed(decimalPlaces) + "Mb";
}

export function buildHeaderTitle(pathName: string) {
  // Break path into chunks
  // If [1] is 'admin', we're probably looking at an admin route.
  const isAdminRoute = pathName.split("/")[1] === "admin";
  const baseName = isAdminRoute
    ? pathName.split("/")[2]
    : pathName.split("/")[1];

  // Capitalise first letter of the base name.
  let capitalisedTitle = baseName.charAt(0).toUpperCase() + baseName.slice(1);

  // Replace hyphens in the string with spaces. Basically lets multi-word headers work.
  capitalisedTitle = capitalisedTitle.replaceAll("-", " ");

  // If it was an admin route, prepend 'Manage'.
  if (isAdminRoute) {
    capitalisedTitle = `Manage ${capitalisedTitle}`;

    if (capitalisedTitle === "Manage Tools") {
      // Real quality stuff here.
      capitalisedTitle = `Admin Utilities`;
    }
  }

  // If the route wasn't an admin route and was specifically 'planograms', change to 'Planogram Snapshots'.
  if (!isAdminRoute && pathName.split("/")[1] === "planograms") {
    capitalisedTitle = `Planogram Snapshots`;
  }

  return capitalisedTitle;
}

// Helper Util that provides the correct page wrapper to offset content against both opened and collapsed sidebars.
export const ResponsiveContainerStyle: SxProps = {
  marginLeft: { xs: 4 },
  marginRight: 4,
};

/** Accepts DashboardFilterRequest params and creates a workable URLSearchParam object of the common shared filters. */
export function generateMetricParams(
  _params: DashboardFilterRequestParams
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();

  // You always have teamId. hopefully.
  urlSearchParams.append("teamId", _params.teamId);

  // multi-values
  _params.state?.forEach((s) => urlSearchParams.append("state", s));
  _params.storeId?.forEach((s) => urlSearchParams.append("storeId", s));

  // single-values
  _params.country && urlSearchParams.append("country", _params.country);
  _params.bayCount &&
    urlSearchParams.append("bayCount", _params.bayCount.toString());
  _params.brand && urlSearchParams.append("brand", _params.brand);
  _params.category && urlSearchParams.append("category", _params.category);
  _params.subcategory &&
    urlSearchParams.append("subcategory", _params.subcategory);
  _params.manufacturer &&
    urlSearchParams.append("manufacturer", _params.manufacturer);
  _params.upc && urlSearchParams.append("upc", _params.upc);
  _params.fixtureType &&
    urlSearchParams.append("fixtureType", _params.fixtureType);

  return urlSearchParams;
}

export default visibleIf;
export { convertBytesToMBString };
