import { MetricsOptionsState } from "../../stores/metrics/MetricsOptionsStore";
import axiosInstance from "../axiosInstanceConfig";

const getAllMetricsFilterOptions = async (teamId: string) => {
  const params = new URLSearchParams();

  params.append("teamId", teamId);

  const response = await axiosInstance.get<MetricsOptionsState>(
    `store/GetAllMetricsFilterOptions?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting dashboard filter options. Please contact support."
    );
  }
};

export default getAllMetricsFilterOptions;
