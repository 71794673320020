import axiosInstance from "../axiosInstanceConfig";

const postAdminUploadPlanogramRevision = async (
  planogramID: string,
  data: FormData
) => {
  const params = new URLSearchParams();
  params.append("planogramId", planogramID);

  const response = await axiosInstance.post<string>(
    `planogram/AdminUploadPlanogramRevision?${params.toString()}`,
    data
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error uploading the planogram revision. Please contact support."
    );
  }
};

export default postAdminUploadPlanogramRevision;
