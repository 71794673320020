import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextInputDialog from "../../../components/TextInputDialog";
import { AddCircleOutline, FilterList } from "@mui/icons-material";
import getAdminAllProducts from "../../../api/planograms/getAdminAllProducts";
import getAdminFindLowResImages from "../../../api/planograms/getAdminFindLowResImages";
import postAdminMakeProduct from "../../../api/planograms/postAdminMakeProduct";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { ColDef } from "ag-grid-community";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { useSnackbar } from "notistack";

class DisplayProduct {
  constructor(public ProductId: string, public UPC: string) {}
}

const AdminProductsListPage = () => {
  // Util
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [products, setProducts] = useState<DisplayProduct[]>([]);
  const [addNewProduct, setAddNewProduct] = useState<boolean>(false);
  const [filterForLowRes, setFilterForLowRes] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // AG Grid State
  const [colDefs] = useState<ColDef<DisplayProduct>[]>([
    { field: "UPC", flex: 1 },
    { field: "ProductId", flex: 1 },
  ]);

  // Effects
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        const data = filterForLowRes
          ? // If in low res, use the low-res API (shocking!)
            (await getAdminFindLowResImages(null)).items
              .filter((p) => p.productId !== null)
              .map((p) => new DisplayProduct(p.productId, p.productUPC))
          : // Otherwise, use the admin-all-product endpoint (incredible!)
            (await getAdminAllProducts())
              .filter((p) => p.ProductId !== null)
              .map((p) => new DisplayProduct(p.ProductId, p.UPC));

        setProducts(data);
      } catch (err: any) {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: err.message ?? "Failed to load product list.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [filterForLowRes]);

  function openEditProductPage(productID: string) {
    navigate({
      pathname: "./edit",
      search: "?product=" + productID,
    });
  }

  function openAddProductDialog() {
    setAddNewProduct(true);
  }

  async function closeNewProductDialog(upcValue: string | null) {
    setAddNewProduct(false);

    if (!upcValue) {
      //new product dialog cancelled
      return;
    }

    let requestData = { UPC: upcValue };

    try {
      const data = await postAdminMakeProduct(requestData);
      if (data) {
        const newProductID = data.id;

        if (
          newProductID !== null &&
          newProductID !== undefined &&
          newProductID.length > 0
        ) {
          openEditProductPage(newProductID);
        }
      }
    } catch (error: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create product.",
      });

      console.log(error.message);
    }
  }

  function ToggleLowResImages() {
    setFilterForLowRes(!filterForLowRes);
  }

  function titleForFilterButton(): string {
    if (filterForLowRes) {
      return "Show All Products";
    } else {
      return "Filter by Low Res Images";
    }
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Datagrid */}
      <AgGridWrapper
        id="admin-products"
        columnDefs={colDefs}
        rowData={products}
        quickSearch
        export
        rememberPageNumber
        onRowClicked={(row: any) => openEditProductPage(row.data.ProductId)}
        loading={loading}
        plugins={
          <>
            <Button
              variant="contained"
              style={{ marginRight: 20 }}
              onClick={ToggleLowResImages}
              startIcon={<FilterList />}
            >
              {titleForFilterButton()}
            </Button>
            <Button
              variant="contained"
              onClick={openAddProductDialog}
              startIcon={<AddCircleOutline />}
            >
              Add New Product
            </Button>
          </>
        }
      />

      {/* Input Dialog */}
      <TextInputDialog
        onClose={closeNewProductDialog}
        open={addNewProduct}
        title="Create new Product"
        inputLabel="UPC"
        enterText="Create Product"
      />
    </Box>
  );
};

export default AdminProductsListPage;
