import { create } from "zustand";
import {
  CapturePositionAnnotation,
  CapturePositionWithKeys,
} from "../../models/planograms/AnnotationTypes";

export type PlanogramState = {
  bayItemsList: CapturePositionWithKeys[] | null; // list of items in the bay
  activeCapturePositionAnnotation: CapturePositionAnnotation | null;
  currentBayTotalItems: number
};

type PlanogramActions = {
  setBayItemsList: (_bayItemsList: CapturePositionWithKeys[]) => void;
  setActiveCapturePositionAnnotation: (
    _capturePosition: CapturePositionAnnotation | null
  ) => void;
  setCurrentBayTotalItems: (_totalItems: number) => void;
};

const initialState: PlanogramState = {
  bayItemsList: null,
  activeCapturePositionAnnotation: null,
  currentBayTotalItems: 0
};

export const usePlanogramStore = create<PlanogramState & PlanogramActions>(
  (set) => ({
    ...initialState,
    setBayItemsList: (_bayItemsList) => {
      set({ bayItemsList: _bayItemsList });
    },
    setActiveCapturePositionAnnotation: (_capturePosition) => {
      set({ activeCapturePositionAnnotation: _capturePosition });
    },
    setCurrentBayTotalItems: (_totalItems) => {
      set({ currentBayTotalItems: _totalItems });
    }
  })
);
