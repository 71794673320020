import { SnackbarContent } from "notistack";
import { forwardRef } from "react";
import { lightGreen } from "@mui/material/colors";
import { CheckOutlined } from "@mui/icons-material";
import { CustomSnackbarProps, SnackbarBase } from "./SnackbarBase";

const SuccessSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    return (
      <SnackbarContent ref={ref} role="alert">
        <SnackbarBase
          backgroundColour={lightGreen[50]}
          iconBackgroundColour={lightGreen[100]}
          borderColour={lightGreen[300]}
          messageColour={lightGreen[700]}
          ctaColour={lightGreen[900]}
          message={props.message}
          cta={props.cta ?? null}
          icon={<CheckOutlined color="success" />}
        />
      </SnackbarContent>
    );
  }
);

SuccessSnackbar.displayName = "SuccessSnackbar";

export default SuccessSnackbar;
