import {
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Menu,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LocationFilterModal from "./LocationFilter/LocationFilterModal";
import { FilterList, FilterListOff, MoreHoriz } from "@mui/icons-material";
import LocationFilter from "./LocationFilter/LocationFilter";
import { useStoreListStore } from "../../../stores/StoreListStore";
import CategoryFilter from "./CategoryFilter/components/CategoryFilter";
import CategoryFilterModal from "./CategoryFilter/components/CategoryFilterModal";
import BayCountFilter from "./BayCountFilter/components/BayCountFilter";
import BayCountFilterModal from "./BayCountFilter/components/BayCountFilterModal";
import DateFilter from "./DateFilter/DateFilter";
import DateFilterModal from "./DateFilter/DateFilterModal";
import { useMasterFilterStore } from "../../../stores/metrics/MasterFilterStore";
import { DashboardFilterRequestParams } from "../helpers/DashboardHelpers";
import getAllStores from "../../../api/stores/getAllStores";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import getMyStores from "../../../api/stores/getStoresList";
import FixtureTypeFilter from "./FixtureTypeFilter/components/FixtureTypeFilter";
import FixtureTypeFilterModal from "./FixtureTypeFilter/components/FixtureTypeFilterModal";
import { useLocationFilterStore } from "../../../stores/metrics/LocationFilterStore";

interface DashboardFilterProps {
  teamId: string;
  plugins?: React.ReactNode;
}

const DashboardFilter = (props: DashboardFilterProps) => {
  // Util
  const { syncing, syncedFilters, setSyncFilters, setMetricData } =
    useMasterFilterStore();
  const { roleOnTeam } = useTeamStore();

  // State
  const setStores = useStoreListStore((state) => state.setStoreList);
  const setLocationStore = useLocationFilterStore((state) => state.setLocationStore);

  // Anchors
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [categoryAnchorEl, setCategoryAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const [bayCountAnchorEl, setBayCountAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const [fixtureTypeAnchorEl, setFixtureTypeAnchorEl] = 
    useState<HTMLDivElement | null>(null);

  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLDivElement | null>(null);

  // Plugin Menu Control
  const [pluginMenuOpen, setPluginMenuOpen] = useState<boolean>(false);
  const [pluginAnchorEl, setPluginAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const fetchMyStores = async () => {
      try {
        // Call either myStores or allStores depending on role.
        if (roleOnTeam === "admin") {
          const response = await getAllStores(props.teamId);

          if (response) setStores(response);
        } else {
          const response = await getMyStores(props.teamId);
          if (response) setStores(response);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchMyStores();
  }, []);

  useEffect(() => {
    syncedFilters &&
      setMetricData(
        syncedFilters,
        roleOnTeam === "admin" ? "all" : "restricted"
      );
  }, [syncedFilters]);

  const handleLocationFilterClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCategoryFilterClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleBayCountFilterClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setBayCountAnchorEl(event.currentTarget);
  };

  const handleFixtureTypeFilterClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setFixtureTypeAnchorEl(event.currentTarget);
  };

  const handleDateFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setDateAnchorEl(event.currentTarget);
  };

  const handlePluginMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPluginAnchorEl(event.currentTarget);
    setPluginMenuOpen(true);
  };

  const handleFilterClose = (type: string) => {
    switch (type) {
      case "location":
        setAnchorEl(null);
        break;
      case "category":
        setCategoryAnchorEl(null);
        break;
      case "bayCount":
        setBayCountAnchorEl(null);
        break;
      case "fixtureType":
        setFixtureTypeAnchorEl(null);
        break;
      case "date":
        setDateAnchorEl(null);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  // Generates a context icon and control for resetting filters when custom ones are applied.
  const getFilterBarContextIcon = () => {
    // Exempt fields that won't be reset, nor trigger a reset, when changed.
    const exemptFields = ["teamId", "startDate", "endDate"];

    if (syncedFilters != null) {
      for (let key in syncedFilters) {
        // Type-safety is very fun.
        if (Object.hasOwnProperty.call(syncedFilters, key)) {
          const filterKey = key as keyof DashboardFilterRequestParams;
          if (
            syncedFilters[filterKey] !== null &&
            !exemptFields.includes(filterKey)
          ) {
            return (
              // If any non-exempt fields are applied, show a clear button.
              <Tooltip title="Reset Filters (Except Dates)">
                <IconButton
                  disabled={syncing ? true : false}
                  onClick={() => {
                    // Set
                    setSyncFilters(
                      Object.keys(syncedFilters).filter(
                        (k) => !exemptFields.includes(k)
                      ) as (keyof DashboardFilterRequestParams)[],
                      null
                    );

                    // Force reset store locations. Array filters are weird.
                    setLocationStore({value: '', label: ''});

                    // Sync
                    setMetricData(
                      syncedFilters,
                      roleOnTeam === "admin" ? "all" : "restricted"
                    );
                  }}
                >
                  <FilterListOff />
                </IconButton>
              </Tooltip>
            );
          }
        }
      }

      // Otherwise, show a disabled button.
      return (
        <IconButton disabled>
          <FilterList />
        </IconButton>
      );
    } else {
      // Otherwise, show a disabled button.
      return (
        <IconButton disabled={true}>
          <FilterList />
        </IconButton>
      );
    }
  };

  return (
    <Box
      sx={{ display: "flex", width: "100%" }}
      justifyContent={"space-between"}
    >
      <Box sx={{ display: "flex" }}>
        <Fade in={syncing} timeout={1000}>
          <CircularProgress size={"32px"} sx={{ m: 1, position: "fixed" }} />
        </Fade>
        {getFilterBarContextIcon()}

        <Stack
          direction="row"
          gap={1}
          flexWrap="wrap"
          justifyContent={"flex-start"}
        >
          <LocationFilter
            handleFilterClick={handleLocationFilterClick}
            active={Boolean(anchorEl)}
          />
          <LocationFilterModal
            anchorEl={anchorEl}
            handleClose={() => handleFilterClose("location")}
          />
          <CategoryFilter
            handleFilterClick={handleCategoryFilterClick}
            active={Boolean(categoryAnchorEl)}
          />
          <CategoryFilterModal
            anchorEl={categoryAnchorEl}
            handleClose={() => handleFilterClose("category")}
          />
          <BayCountFilter
            handleFilterClick={handleBayCountFilterClick}
            active={Boolean(bayCountAnchorEl)}
          />
          <BayCountFilterModal
            anchorEl={bayCountAnchorEl}
            handleClose={() => handleFilterClose("bayCount")}
          />
          <FixtureTypeFilter
            handleFilterClick={handleFixtureTypeFilterClick}
            active={Boolean(fixtureTypeAnchorEl)}
          />
          <FixtureTypeFilterModal
            anchorEl={fixtureTypeAnchorEl}
            handleClose={() => handleFilterClose("fixtureType")}
          />
        </Stack>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <DateFilter
          handleFilterClick={handleDateFilterClick}
          active={Boolean(dateAnchorEl)}
        />
        <DateFilterModal
          anchorEl={dateAnchorEl}
          handleClose={() => handleFilterClose("date")}
        />
        {/* Plugin Menu Control */}
        <IconButton
          onClick={handlePluginMenuClick}
          sx={{ maxHeight: "40px", my: "auto" }}
        >
          <MoreHoriz />
        </IconButton>

        {/* Plugin Menu El */}
        <Menu
          anchorEl={pluginAnchorEl}
          open={pluginMenuOpen}
          onClose={() => {
            setPluginAnchorEl(null);
            setPluginMenuOpen(false);
          }}
        >
          {props.plugins}
        </Menu>
      </Box>
    </Box>
  );
};

export default DashboardFilter;
