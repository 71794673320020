import axiosInstance from "../axiosInstanceConfig";

// Returns all products included on planograms for a given team.
const postDeleteProductEmbedding = async (embeddingId: string) => {
  const response = await axiosInstance.post<string | any>(
    `products/RemoveProductEmbedding`,
    { EmbeddingId: embeddingId }
  );

  return response.data;
};

export default postDeleteProductEmbedding;
