import { TrainingImageTypes } from "../../../features/planograms/models/CaptureAnnotation";
import { CaptureMLModel } from "../../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../../axiosInstanceConfig";

interface PutUpdateAdminTrainingImageTypes
  extends Pick<TrainingImageTypes, "ImageId"> {
  Enabled?: boolean;
  UseForTesting?: boolean;
  upc?: string;
}

export const putUpdateAdminTrainingImage = async ({
  ImageId,
  Enabled,
  UseForTesting,
  upc
}: PutUpdateAdminTrainingImageTypes) => {
  const queryParams = new URLSearchParams();
  queryParams.append("trainingImageId", ImageId);

  if (Enabled !== undefined) {
    queryParams.append("enabled", String(Enabled));
  }

  if (UseForTesting !== undefined) {
    queryParams.append("useForTesting", String(UseForTesting));
  }

  if(upc !== undefined){
    queryParams.append('upc', upc);
  }

  try {
    const response = await axiosInstance.put<TrainingImageTypes>(
      `trainingImage/AdminUpdateTrainingImage?${queryParams.toString()}`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "There was an error updating the training image data. Please contact support."
    );
  }
};
