export const roles = [
  {
    display: "Merchandiser",
    id: "merchandiser",
    description: [
      {
        text: "Access assigned stores via the UR Store app.",
        color: "primary",
      },
      {
        text: "View store level metrics within the app.",
        color: "primary",
      },
      {
        text: "Cannot access web portal & reporting.",
        color: "error",
      },
    ],
  },
  {
    display: "Reporting",
    id: "reporting",
    description: [
      {
        text: "Access the Web Portal.",
        color: "primary",
      },
      {
        text: "View and export reporting data of their team.",
        color: "primary",
      },
      {
        text: "No ability to make any changes to teams or assignments.",
        color: "error",
      },
    ],
  },
  {
    display: "Admin",
    id: "admin",
    description: [
      {
        text: "Full access to the Web Portal.",
        color: "primary",
      },
      {
        text: "Add users to Teams and assign Stores.",
        color: "primary",
      },
      {
        text: "Standard reporting tools available to the Reporting role.",
        color: "primary",
      },
    ],
  },
];
