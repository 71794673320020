import dayjs from "dayjs";
import axiosInstance from "../axiosInstanceConfig";

const getStoreExport = async (
  teamID: string,
  storeIdExport: string,
  startDate: string | Date,
  endDate: string | Date
) => {
  const formattedStartDate = dayjs(startDate).toISOString();
  const formattedEndDate = dayjs(endDate).endOf("day").toISOString();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const params = new URLSearchParams();
  params.append("teamId", teamID);
  params.append("storeId", storeIdExport);
  params.append("fromDate", formattedStartDate);
  params.append("toDate", formattedEndDate);
  params.append("userTimezone", userTimezone);

  const response = await axiosInstance.get<string>(
    `capture/ExportCaptureDataByStoreId?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the capture data. Please contact support."
    );
  }
};

export default getStoreExport;
