import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { CaptureFixtureAnnotation, CapturePositionAnnotation } from "../../../models/planograms/AnnotationTypes";
import CapturePosition from "./CapturePosition";

interface Cardinality {
  PositionCardinality: number,
  CapturesByCardinality: ProductStack[]
}

interface ProductStack {
  HFacing: number,
  VFacing: number,
  CapturesByFaces: CapturePositionAnnotation[]
}

const CaptureFixtureRowContainer = ({
  fixtureRowData,
  mainProductImage,
  captureId,
  bayIndex,
  callUpdatedData,
}: {
  fixtureRowData: CaptureFixtureAnnotation | undefined;
  mainProductImage: string;
  captureId: string | null;
  bayIndex: number;
  callUpdatedData: () => Promise<void>;
}) => {

  // function to group stackable objects. Objects can contain same SKUs
  // but different facing indexes and tray indexes. 
  // Stackable objects will be displayed as a group of objects in a single cell
  function groupStackableObjects(data: CapturePositionAnnotation[]): Cardinality[] {

    const captureByCardinality = data.reduce((group: { [key: number]: CapturePositionAnnotation[] }, item) => {
      if (!group[item.PositionCardinality]) {
        group[item.PositionCardinality] = [];
      }
      group[item.PositionCardinality].push(item);
      return group;
    }, {});

    const cardinalities: Cardinality[] = [];
    for (const [cardinality, captures] of Object.entries(captureByCardinality)) {
      const captureByFacingIndex = captures.reduce((group: { [key: string]: CapturePositionAnnotation[] }, item) => {
        const key = `${item.VFacingIndex},${item.HFacingIndex}`;
        if (!group[key]) {
          group[key] = [];
        }
        group[key].push(item);
        return group;
      }, {});

      const stacks: ProductStack[] = [];
      for (const capturesByFacing of Object.values(captureByFacingIndex)) {
        stacks.push({
          HFacing: capturesByFacing[0].HFacingIndex,
          VFacing: capturesByFacing[0].VFacingIndex,
          CapturesByFaces: capturesByFacing
        });
      }
      cardinalities.push({
        PositionCardinality: parseInt(cardinality),
        CapturesByCardinality: stacks
      });
    }
    return cardinalities;
  }

  const createEmptyCell = (key: string, smallSize: boolean) => {
    return (
      <Box
        key={key}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pl: 1,
          pb: 1,
          textAlign: "center",
        }}>
        <Box
          sx={{
            height: smallSize ? "45px" : "60px",
            width: "40px",
            border: "1px dotted " + urStorePalette.greys.default,
            borderRadius: "6px",
          }} />
      </Box>
    );
  };

  const createCaptureRow = (cardinalities: Cardinality[]) => {
    const cardinalityElements: ReactElement[] = [];
    cardinalities.forEach(cardinality => {
      let maxHFacingIndex = 0;
      let maxVFacingIndex = 0;

      cardinality.CapturesByCardinality.forEach((stack) => {
        maxVFacingIndex = Math.max(
          maxVFacingIndex,
          stack.VFacing
        );
        maxHFacingIndex = Math.max(
          maxHFacingIndex,
          stack.HFacing
        );
      });
      const rowItems: ReactElement[] = [];
      for (let indexY = maxVFacingIndex; indexY >= 0; indexY--) {
        const colItems: ReactElement[] = [];
        for (let indexX = 0; indexX <= maxHFacingIndex; indexX++) {
          const stack = cardinality.CapturesByCardinality.find(capture => capture.HFacing === indexX && capture.VFacing === indexY);
          if (stack) {
            colItems.push(
              createTray(cardinality.PositionCardinality, stack, maxVFacingIndex > 0)
            );
          } else {
            colItems.push(
              createEmptyCell(`empty-${cardinality.PositionCardinality}-row:${indexY}-col:${indexX}`, maxVFacingIndex > 0)
            );
          }
        }
        rowItems.push(
          <Box key={`${cardinality.PositionCardinality}-row:${indexY}`}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
            {colItems}
          </Box>
        );
      }
      cardinalityElements.push(
        <Box key={`${fixtureRowData?.FixtureCardinality}-cardinality:${cardinality.PositionCardinality}`}
          sx={{
            mr: 1.5
          }}>
          {rowItems}
        </Box>
      );
    });
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}>
        {cardinalityElements}
      </Box>
    );
  };

  const createTray = (cardinalityPosition: number, stack: ProductStack, multipleStack: boolean) => {
    let maxTrayIndexX = 0;
    let maxTrayIndexY = 0;

    stack.CapturesByFaces.forEach((position) => {
      maxTrayIndexX = Math.max(
        maxTrayIndexX,
        position.TrayIndexX
      );
      maxTrayIndexY = Math.max(
        maxTrayIndexY,
        position.TrayIndexY
      );
    });

    const rowItems: ReactElement[] = [];
    for (let indexY = maxTrayIndexY; indexY >= 0; indexY--) {
      const colItems: ReactElement[] = [];
      for (let indexX = 0; indexX <= maxTrayIndexX; indexX++) {
        const capture = stack.CapturesByFaces.find(capture => capture.TrayIndexX === indexX && capture.TrayIndexY === indexY);
        if (capture) {
          colItems.push(
            <CapturePosition
              key={`${cardinalityPosition}-TrayX:${indexX}-TrayY:${indexY}`}
              capturePosition={capture}
              mainProductImage={mainProductImage}
              captureId={captureId}
              bayIndex={bayIndex}
              fixtureCardinality={fixtureRowData!.FixtureCardinality}
              callUpdatedData={callUpdatedData}
              size={(multipleStack || maxTrayIndexY > 0) ? "small" : "medium"}
            />
          );
        } else {
          colItems.push(
            createEmptyCell(`empty-${cardinalityPosition}-TrayX:${indexX}-TrayY:${indexY}`, (multipleStack || maxTrayIndexY > 0))
          );
        }
      }
      rowItems.push(
        <Box
          key={`${cardinalityPosition}-TrayY:${indexY}`}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}>
          {colItems}
        </Box>
      );
    }
    return (
      <Box key={`${stack.HFacing},${stack.VFacing}-Tray-${cardinalityPosition}`}>
        {rowItems}
      </Box >
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pl: 1,
        pb: 1,
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {createCaptureRow(groupStackableObjects(fixtureRowData?.CapturePositions ?? []))}
    </Box>
  );
};

export default CaptureFixtureRowContainer;
