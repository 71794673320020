import axiosInstance from "../axiosInstanceConfig";

export interface GetUniqueRevisionResponse {
  PlanogramRevisionId: string;
  PlanogramName: string;
  PlanogramDisplayName: string | null;
  CreatedAt: Date;
}

const getUniqueRevisions = async (teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.get<GetUniqueRevisionResponse[]>(
    `planogram/UniquePlanogramRevisions?${params.toString()}`
  );

  return response.data;
};

export default getUniqueRevisions;
