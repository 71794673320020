import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { lightBlue, purple } from "@mui/material/colors";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { useSessionStore } from "../../../stores/auth/SessionStore";
import { useEffect, useState } from "react";
import { NavItem } from "../../../stores/layout/LayoutStoreTypes";
import { navItems, adminNavItems } from "./NavRoutes";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import useLayoutStore from "../../../stores/layout/LayoutStore";

function MainNav(props: any) {
  const location = useLocation();
  const selectedTeam = props.selectedTeam || null;
  const handlePageSelectionChanged = props.handlePageSelectionChanged;
  const navigate = useNavigate();

  const { user } = useSessionStore();
  const { roleOnTeam } = useTeamStore();
  const { drawerOpen } = useLayoutStore();

  const [mainNavNodes, setMainNavNodes] = useState<React.ReactNode>();
  const [adminNavNodes, setAdminNavNodes] = useState<React.ReactNode>();

  useEffect(() => {
    setMainNavNodes(generateNavItems(navItems, false));
    setAdminNavNodes(generateNavItems(adminNavItems, true));
  }, [location.pathname, roleOnTeam, drawerOpen]);

  useEffect(() => {}, [user?.token, location.pathname]);

  // TODO: duplicate func
  function isUserAdmin(): boolean {
    let acceptedAdminRoles = ["admin", "adminreadonly"];
    process.env.REACT_APP_ENV === "DEV" && acceptedAdminRoles.push("devadmin");

    const userIsAdmin =
      user?.roles?.some((el: string) => acceptedAdminRoles.includes(el)) ??
      false;

    return userIsAdmin;
  }

  function switchedToAdminPage() {
    handlePageSelectionChanged(true);
  }

  function switchedToNonAdminPage() {
    handlePageSelectionChanged(false);
  }

  function generateNavItems(navItems: NavItem[], asAdminItems: boolean) {
    // Holding array for menu items.
    let nodes: React.ReactNode[] = [];

    // If rendering as admin items, check auth first, otherwise allow rendering.
    if (asAdminItems ? isUserAdmin() : true) {
      navItems &&
        navItems
          .filter((item) => item.showInSidebar)
          .map((item) => {
            const isActive = location.pathname.startsWith(item.path);

            // Begin pushing elements to array.
            nodes.push(
              <Tooltip
                disableHoverListener={drawerOpen}
                title={item.label}
                arrow
                placement="right"
              >
                <ListItemButton
                  sx={{
                    my: 0.5,
                    mx: 2,
                    px: 2,
                    py: 1,

                    transition: "all 0.2s ease",
                    borderRadius: "6px",
                    backgroundColor: isActive
                      ? "rgba(255,255,255,0.2)"
                      : "none",
                    ":hover": {
                      backgroundColor: "rgba(255,255,255,0.2)",
                    },
                  }}
                  key={item.path}
                  onClick={() => {
                    // Nav Handling onClick
                    asAdminItems
                      ? switchedToAdminPage()
                      : switchedToNonAdminPage();
                    navigate(item.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: asAdminItems ? purple[300] : lightBlue[400],
                      mr: -2,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {drawerOpen && (
                    <ListItemText
                      sx={{
                        color: isActive
                          ? urStorePalette.greys.lightest
                          : urStorePalette.greys.light,
                        opacity: isActive ? 1 : 0.8,
                        display: { xs: "none", sm: "block" },
                      }}
                      primary={
                        <Typography
                          sx={{
                            transition: "all 0.2s ease",
                            fontWeight: isActive ? "600" : "500",
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  )}
                </ListItemButton>
              </Tooltip>
            );
          });
    }

    return nodes;
  }

  return (
    <>
      {/* Team-Scoped Nav Items, options will not display unless a team is selected. */}
      {selectedTeam !== null && <List component="nav">{mainNavNodes}</List>}

      {/* 'Admin-Required' Nav Items */}
      <List component="nav">{adminNavNodes}</List>
    </>
  );
}

export default MainNav;
