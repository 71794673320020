import { Box, Card, CardContent } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactNode } from "react";

/**
 * This doesn't do anything fancy. It's just a wrapper for simplifying the way we show cards in the comparison-dialog.
 * @returns
 */
export default function ComparisonCard(props: {
  media: ReactNode;
  content: ReactNode;
}) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: 462,
        pt: 12,
      }}
    >
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {props.media}
      </Box>
      <CardContent
        sx={{
          mb: -2,
          backgroundColor: grey[200],
          m: 2,
          borderRadius: 2,
          "&:last-child": {
            py: 2,
          },
        }}
      >
        {props.content}
      </CardContent>
    </Card>
  );
}
