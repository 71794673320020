import { Typography } from "@mui/material";
import PaperTitleContainer from "../../components/PaperTitleContainer";


function WelcomeIndex() {

  return ( 
    <PaperTitleContainer title="Planograms">
        <Typography variant="h5">Welcome to UR Store</Typography>
        <Typography variant="body1">Please select a team to access the rest of the Site.</Typography>
    </PaperTitleContainer>
  );
}

export default WelcomeIndex;