import axiosInstance from "../axiosInstanceConfig";

const postAddUsertoTeam = async (data:AddUserToTeamRequest) => {
  const response = await axiosInstance.post(
    `user/AddUserToTeam`, data
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("There was an error adding the user to the team. Please contact support.");
  }
};

export type AddUserToTeamRequest = {
  TeamId: string,
  UserId: string,
  Role: string | null,
}

export default postAddUsertoTeam;
