import React, { useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { urStorePalette } from "../../themes/urStoreTheme";
import { CloudUpload } from "@mui/icons-material";

interface DragAndDropProps {
  multiple?: boolean; // whether to allow multiple files to be uploaded
  onFileDrop: (file: File | File[]) => void; // function to handle the file once it is dropped/selected
  acceptedFileTypes: string[];
}

// This component is used to upload files by dragging and dropping them into the box
// or by clicking the box to open the file dialog

const DragAndDropUploader: React.FC<DragAndDropProps> = ({
  multiple,
  onFileDrop,
  acceptedFileTypes,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = multiple ? event.dataTransfer.files : event.dataTransfer.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  // handle file validation and call the onFileDrop function
  const handleFile = (file: File | FileList) => {
    if (file instanceof FileList) {
      let fileArray: File[] = [];
      Array.from(file).forEach((f) => {
        const fileExtension = f.name.split(".").pop()?.toLowerCase() || "";
        const lowerCaseAcceptedFileTypes = acceptedFileTypes.map((type) =>
          type.toLowerCase()
        );
        if (
          lowerCaseAcceptedFileTypes.includes("." + fileExtension) ||
          lowerCaseAcceptedFileTypes.includes(f.type.toLowerCase())
        ) {
          fileArray.push(f);
          
        } else {
          console.error("Invalid file type.");
        }
        onFileDrop(fileArray);
      });
    } else {
      const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
      const lowerCaseAcceptedFileTypes = acceptedFileTypes.map((type) =>
        type.toLowerCase()
      );
      if (
        lowerCaseAcceptedFileTypes.includes("." + fileExtension) ||
        lowerCaseAcceptedFileTypes.includes(file.type.toLowerCase())
      ) {
        onFileDrop(file);
      } else {
        console.error("Invalid file type.");
      }
    }
  };

  // Handles the click event on the box to open the file dialog
  const handleClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = multiple || false;
    fileInput.max = multiple ? "5" : "1";
    fileInput.accept = acceptedFileTypes.join(",");
    fileInput.onchange = (e) => {
      const file = multiple
        ? (e.target as HTMLInputElement).files
        : (e.target as HTMLInputElement).files?.[0];
      if (file) {
        handleFile(file);
      }
    };
    fileInput.click();
  };

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
      sx={{
        width: "100%",
        position: "relative",
        border: `2px dashed ${urStorePalette.greys.default}`,
        borderRadius: "5px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        transition: "border-color 0.3s ease",
        backgroundColor: isDragging ? urStorePalette.greys.default : "",
      }}
    >
      <CloudUpload />
      <Typography sx={{ mb: 3 }} variant="body1">
        {`Drag & drop ${multiple ? "files" : "file"} here, or click to select ${multiple ? "files" : "file"}.`}
      </Typography>
      {acceptedFileTypes.length > 0 &&
        acceptedFileTypes.map((type) => (
          <Chip sx={{ fontWeight: 700, mr: 2 }} key={type} label={type} />
        ))}
    </Box>
  );
};

export default DragAndDropUploader;
