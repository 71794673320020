import dayjs from "dayjs";
import { DashboardFilterRequestParams } from "../../features/dashboard/helpers/DashboardHelpers";
import axiosInstance from "../axiosInstanceConfig";
import { OOPAggregate } from "../../stores/metrics/MasterFilterStore";
import { generateMetricParams } from "../../helpers/generalUtilities";

const getPOSMetrics = async (
  _params: DashboardFilterRequestParams,
  _pageSize?: number // Will default to 100 or so if not provided.
) => {
  let urlQueryParams = generateMetricParams(_params);

  if (_pageSize) {
    urlQueryParams.append("pageSize", _pageSize.toString());
  }

  _params.startDate &&
    urlQueryParams.append(
      "startDate",
      dayjs(_params.startDate, "DD-MM-YYYY").toISOString()
    );
  _params.endDate &&
    urlQueryParams.append(
      "endDate",
      dayjs(_params.endDate, "DD-MM-YYYY").endOf("day").toISOString()
    );

  const response = await axiosInstance.get<OOPAggregate[]>(
    `store/TopOutOfPositionProducts?${urlQueryParams.toString()}`
  );

  return response.data;
};

export default getPOSMetrics;
