import {
  grey,
  lightBlue,
  lightGreen,
  orange,
  purple,
  red,
} from "@mui/material/colors";
import { PaletteOptions, createTheme } from "@mui/material/styles";

export interface UrStorePaletteOptions extends PaletteOptions {
  // Define custom tints that can be used for metric-related theming. (Graphs, etc)
  metric: {
    purple: string;
    blue: string;
    green: string;
    orange: string;
    red: string;
  };
  // Backdrops are 'tertiary' greys used to convey depth between layered elements.
  greys: {
    lightest: string; // Basically white, don't use #FFF!
    light: string;
    default: string;
    dark: string;
    darkest: string; // Basically black, don't use #000!
  };
}

export const urStorePalette: UrStorePaletteOptions = {
  primary: {
    main: "#090042", // Very dark branding blue.
    // Offset vals generated automatically.
  },
  secondary: {
    main: lightBlue[400],
    // Offset vals generated automatically.
  },
  background: {
    paper: grey[50],
  },
  error: {
    main: "#ed3c39",
  },
  success: {
    main: "#449d48",
  },
  warning: {
    main: "#ff9800",
  },
  metric: {
    /* 
      Tints are not automatically generated. See typedef for usage examples.
    */
    red: red[400],
    purple: purple[400],
    blue: lightBlue[400],
    green: lightGreen[400],
    orange: orange[400],
  },
  greys: {
    lightest: grey[50],
    light: grey[200],
    default: grey[400],
    dark: grey[600],
    darkest: grey[900],
  },
};

const urStoreTheme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: "var(--body-font)",
  },
  palette: urStorePalette,
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          ".Mui-disabled": {
            backgroundColor: urStorePalette.greys.light,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "none",
          letterSpacing: "0.01em",
          borderRadius: "8px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          padding: "15px 14px",
        },
        // contained: {
        //   backgroundColor: lightBlue[500],
        //   "&:hover": {
        //     backgroundColor: lightBlue[700],
        //   },
        // },
        // containedSuccess: {
        //   backgroundColor: lightGreen[400],
        //   "&:hover": {
        //     backgroundColor: lightGreen[500],
        //   },
        // },
        // containedWarning: {
        //   backgroundColor: orange[400],
        //   "&:hover": {
        //     backgroundColor: orange[500],
        //   },
        // },
        // containedError: {
        //   backgroundColor: red[400],
        //   "&:hover": {
        //     backgroundColor: red[500],
        //   },
        // },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "8px",
          border: `0.5px solid ${urStorePalette.greys.default}`,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "8px",
          border: `0.5px solid ${urStorePalette.greys.default}`,
        },
      },
    },
  },
});

export { urStoreTheme };
