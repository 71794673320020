import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { useAuthContext } from "../contexts/AuthContext";
import { LoadingButton } from "@mui/lab";
import postUpdateUserFullname from "../../../api/teams/postUpdateUserFullname";
import useTeamMemberStore from "../../../stores/teams/TeamMemberStore";
import { useTeamStore } from "../../../stores/teams/TeamStore";

interface UserProfileDialogProps {
  open: boolean;
  onClose: () => void;
}

const UserProfileDialog: React.FC<UserProfileDialogProps> = ({
  open,
  onClose,
}) => {
  // Util
  const { teamMembers, fetchTeamMembers } = useTeamMemberStore();
  const { selectedTeam } = useTeamStore();
  const auth = useAuthContext();

  // Const
  const _fullName = teamMembers.find(
    (user) => user.Email === auth?.user
  )?.Fullname;

  // State
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] =
    useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [snackContent, setSnackContent] = useState<{
    colour: "error" | "success";
    message: string;
  }>({ colour: "success", message: "Name Updated" });

  // Effects
  useEffect(() => {
    if (teamMembers.length === 0 || !_fullName) return;
    setFullName(_fullName || "");
  }, [teamMembers, _fullName]);

  useEffect(() => {
    if (open) {
      fetchTeamMembers(selectedTeam);
    }
    resetModal();
  }, [open]);

  const handleFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = await postUpdateUserFullname({
        FullName: fullName || "",
      });
      if (data) {
        // Reset
        fetchTeamMembers(selectedTeam);
        resetModal();
        onClose();
        setLoading(false);

        // Snack mgmt
        setSnackOpen(true);
        setSnackContent({ colour: "success", message: "Name Updated" });
      }
    } catch (error: any) {
      // Logging
      setLoading(false);
      console.log(error);

      // Snack mgmt
      setSnackOpen(true);
      setSnackContent({
        colour: "error",
        message: error.message,
      });
    }
  };

  const resetModal = () => {
    setIsRequestSuccessful(false);
    setFullName(_fullName || "");
  };

  return (
    <>
      {/* Feedback Snack */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert color={snackContent.colour}>{snackContent.message}</Alert>
      </Snackbar>

      {/* Dialog */}
      <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
        <DialogTitle>Update Profile</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            label="Full Name"
            value={fullName}
            onChange={handleFullnameChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            disabled={
              fullName === _fullName || fullName === "" || isRequestSuccessful
            }
            loading={loading}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserProfileDialog;
