import { Box, Grid } from "@mui/material";
import { RequestMLArchiveUtility } from "./components/RequestMLArchive";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

export const AdminToolsDashboard = () => {
  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* 
        This grid is intended to hold small cards for simple utilities an admin or developer might need. 
        If the utility you're making cannot be expressed in a simple card, it needs its own page.
        Do not try and ram big/complex utility classes in here.
      */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <RequestMLArchiveUtility />
        </Grid>
      </Grid>
    </Box>
  );
};
