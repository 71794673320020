import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import {
  MoreVert,
  OpenInBrowserOutlined,
  FileUploadOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import exportCaptureDataByCaptureId from "../../../api/planograms/exportCaptureDataByCaptureId";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";

const ActionsMenu = ({ rowData, handlePlanogramSelected }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { downloadCSV } = useCSVDownloader();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = async () => {
    const captureData = await exportCaptureDataByCaptureId(rowData.CaptureId);
    if (captureData) {
      downloadCSV({
        csvData: captureData,
        type: "planogram",
        id: rowData.CaptureId,
      });
    }
    handleClose();
  };

  const handleDownload = () => {
    window.open(
      `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}captures/${rowData.CaptureId}`,
      "_blank"
    );
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => {
          handlePlanogramSelected(rowData.CaptureId);
          handleClose();
        }}
        variant="contained"
        size="small"
        disabled={rowData.Status !== "complete"}
      >
        Open
      </Button>
      <Box>
        <IconButton
          aria-controls={open ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem
            onClick={handleExport}
            disabled={rowData.Status !== "complete"}
          >
            <FileUploadOutlined sx={{ mr: 1 }} />
            Capture Data (.csv)
          </MenuItem>
          <MenuItem onClick={handleDownload}>
            <FileDownloadOutlined sx={{ mr: 1 }} />
            Download
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default ActionsMenu;
