import { Planogram } from "../../models/planograms/PlanogramType";
import axiosInstance from "../axiosInstanceConfig";

const getAdminAllPlanograms = async () => {
  const response = await axiosInstance.get<Planogram[]>(
    `planogram/AdminAllPlanograms`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of planograms. Please contact support."
    );
  }
};

export default getAdminAllPlanograms;
