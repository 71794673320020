import { useState } from "react";
import { Box, Stack, Tooltip, Zoom, useTheme } from "@mui/material";
import ProductComparisonDialog from "./product-comparison/ProductComparison";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { CapturePositionAnnotation } from "../../../models/planograms/AnnotationTypes";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { useAuthContext } from "../../authentication/contexts/AuthContext";
import { useSessionStore } from "../../../stores/auth/SessionStore";
import { usePlanogramStore } from "../../../stores/snapshots/PlanogramStore";

const CapturePosition = ({
  capturePosition,
  mainProductImage,
  captureId,
  bayIndex,
  fixtureCardinality,
  callUpdatedData,
  size,
}: {
  capturePosition: CapturePositionAnnotation | undefined;
  mainProductImage: string;
  captureId: string | null;
  bayIndex: number;
  fixtureCardinality: number;
  callUpdatedData: () => Promise<void>;
  size?: "small" | "medium";
}) => {
  const theme = useTheme();
  const auth = useAuthContext();
  const { setActiveCapturePositionAnnotation } = usePlanogramStore();

  // Debug Options
  const { showCaptureTooltip } = useSessionStore();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const productStatus = {
    0: theme.palette.success.main, // Match
    1: theme.palette.warning.light, // Wrong
    2: theme.palette.error.main, // Out of Stock (OOS)
    3: urStorePalette.greys.dark, // unknown
  };

  /** STATES: */
  const [openCompareProductDialog, setOpenCompareProductDialog] =
    useState(false);

  const handleOpenCompareProductDialog = () => {
    setOpenCompareProductDialog(true);
    setActiveCapturePositionAnnotation(capturePosition!);
  };

  const handleCloseCompareProductDialog = () => {
    setOpenCompareProductDialog(false);
    setActiveCapturePositionAnnotation(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pl: 1,
        pb: 1,
        textAlign: "center",
      }}
    >
      <Zoom in={true} timeout={500}>
        <Tooltip
          disableHoverListener={!showCaptureTooltip}
          title={
            auth.adminRole
              ? JSON.stringify(capturePosition).replaceAll(",", ",\n")
              : ""
          }
        >
          <Stack
            sx={{
              transition: "0.2s all",
              ":hover": {
                backgroundColor: urStorePalette.greys.light,
                borderRadius: "6px",
              },
            }}
          >
            <img
              alt="Product Images"
              style={{
                cursor: "pointer",
                display: isLoaded ? "block" : "none",
                objectFit: "contain",
                borderRadius: "8px",
                padding: "2px",
              }}
              height={size === "small" ? "45px" : "60px"}
              width={"40px"}
              onClick={handleOpenCompareProductDialog}
              onLoad={() => setIsLoaded(true)}
              src={`${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${capturePosition?.ProductImageUrl}`}
            />
            {!capturePosition?.ProductImageUrl && (
              <BrokenImageIcon
                fontSize="large"
                style={{
                  cursor: "pointer",
                  color: urStorePalette.greys.default,
                  height: size === "small" ? "45px" : "60px",
                  width: "40px",
                  padding: "6px",
                }}
                onClick={handleOpenCompareProductDialog}
              />
            )}
            <Box
              sx={{
                cursor: "pointer",
                height: "5px",
                width: "88%",
                m: "0 auto",
                backgroundColor:
                  productStatus[
                    capturePosition?.Status as keyof typeof productStatus
                  ],
                borderRadius: "3px",
              }}
              onClick={handleOpenCompareProductDialog}
            />
          </Stack>
        </Tooltip>
      </Zoom>
      <ProductComparisonDialog
        openCompareProductComparisonDialog={openCompareProductDialog}
        closeCompareProductComparisonDialog={handleCloseCompareProductDialog}
        mainProductImage={mainProductImage}
        captureId={captureId!}
        bayIndex={bayIndex}
        fixtureCardinality={fixtureCardinality}
        callUpdatedData={callUpdatedData}
      />
    </Box>
  );
};

export default CapturePosition;
