import { DisplayCapture } from "../../features/planograms/hooks/usePaginatedCaptureData";
import axiosInstance from "../axiosInstanceConfig";

export interface GetPlanogramListResponse {
  items: DisplayCapture[];
  more: boolean;
}
const getPlanogramList = async (teamId: string, pageNumber: number) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);
  params.append("page", pageNumber.toString());

  const response = await axiosInstance.get<GetPlanogramListResponse>(
    `capture/MyTeamsCaptures?${params.toString()}`
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the planogram list. Please contact support."
    );
  }
};

export default getPlanogramList;
