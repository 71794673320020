import axiosInstance from "../axiosInstanceConfig";
import { GetAdminLowResImagesResponse } from "./getAdminFindLowResImages";

const getAdminFindLowResImagesForPlanogram = async (
  planogramId: string,
  continuationToken: string | null
) => {

  const params = new URLSearchParams();
  params.append("PlanogramId", planogramId);
  if (continuationToken) {
    params.append("ContinuationToken", continuationToken);
  }

  const response = await axiosInstance.post<GetAdminLowResImagesResponse>(
    `products/AdminFindLowResImagesForPlanogram?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the list of low res images. Please contact support."
    );
  }
};

export default getAdminFindLowResImagesForPlanogram;
