import { useEffect } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Popover,
} from "@mui/material";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import { useMasterFilterStore } from "../../../../../stores/metrics/MasterFilterStore";
import { useMetricsFilterOptions } from "../../../../../stores/metrics/MetricsOptionsStore";
import { FilterInputStyle } from "../../../helpers/DashboardHelpers";
import { urStorePalette } from "../../../../../themes/urStoreTheme";

interface CategoryFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

interface Category {
  [key: string]: any;
}

interface TransformedBrand {
  name: string;
}

interface TransformedManufacturer {
  name: string;
  Brand: TransformedBrand[];
}

interface TransformedSubCategory {
  name: string;
  Manufacturer: TransformedManufacturer[];
}

interface TransformedCategory {
  name: string;
  SubCategory: TransformedSubCategory[];
}

const CategoryFilterModal = (props: CategoryFilterModalProps) => {
  const category = usePlanogramFilterStore((state) => state.category);
  const subcategory = usePlanogramFilterStore((state) => state.subcategory);
  const manufacturer = usePlanogramFilterStore((state) => state.manufacturer);
  const brand = usePlanogramFilterStore((state) => state.brand);
  const setCategory = usePlanogramFilterStore((state) => state.setCategory);
  const setBrand = usePlanogramFilterStore((state) => state.setBrand);
  const setSubCategory = usePlanogramFilterStore(
    (state) => state.setSubCategory
  );
  const setManufacturer = usePlanogramFilterStore(
    (state) => state.setManufacturer
  );

  const open = Boolean(props.anchorEl);
  const id = open ? "category-filter" : undefined;

  // Master
  const { syncedFilters, setSyncFilters } = useMasterFilterStore();
  const { data } = useMetricsFilterOptions();

  // Function for transforming the data from the API into a more usable format.
  const transformData = (data: Category) => {
    const transformedData: TransformedCategory[] = [];

    // transform each category into a new object and insert subcategories
    for (const categoryKey in data.Category) {
      const category: TransformedCategory = {
        name: categoryKey,
        SubCategory: [],
      };
      // transform each subcategory into a new object and insert manufacturers
      for (const subCategoryKey in data.Category[categoryKey]["SubCategory"]) {
        const subCategory: TransformedSubCategory = {
          name: subCategoryKey,
          Manufacturer: [],
        };
        // transform each manufacturer into a new object and insert brands
        for (const manufacturerKey in data.Category[categoryKey]["SubCategory"][
          subCategoryKey
        ]["Manufacturer"]) {
          const manufacturer: TransformedManufacturer = {
            name: manufacturerKey,
            Brand: [],
          };
          // transform each brand into a new object and insert into manufacturer
          for (const brand of data.Category[categoryKey]["SubCategory"][
            subCategoryKey
          ]["Manufacturer"][manufacturerKey].Brand) {
            manufacturer.Brand.push({ name: brand });
          }
          subCategory.Manufacturer.push(manufacturer);
        }
        category.SubCategory.push(subCategory);
      }
      transformedData.push(category);
    }
    return transformedData;
  };

  const transformedFilterData = data && transformData(data);

  useEffect(() => {
    // TODO: This is a workaround. We should centralise all the filters to run off masterstore.
    setCategory(syncedFilters?.category ?? "");
    setSubCategory(syncedFilters?.subcategory ?? "");
    setManufacturer(syncedFilters?.manufacturer ?? "");
    setBrand(syncedFilters?.brand ?? "");
  }, [syncedFilters]);

  return (
    <div>
      <Popover
        sx={{ mt: 2 }}
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid gap={4} container sx={{ p: 4, width: "400px" }}>
          <FormControl fullWidth>
            <Autocomplete
              options={transformedFilterData || []}
              getOptionLabel={(option) => option.name}
              value={
                transformedFilterData?.find((c) => c.name === category) || null
              }
              onChange={(event, value) => {
                setCategory(value?.name || "");
                setSyncFilters(["category"], value?.name || "");

                setSyncFilters(["subcategory", "manufacturer", "brand"], null);
                setSubCategory("");
                setManufacturer("");
                setBrand("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              options={
                transformedFilterData?.find((c) => c.name === category)
                  ?.SubCategory || []
              }
              getOptionLabel={(option) => option.name}
              value={
                transformedFilterData
                  ?.find((c) => c.name === category)
                  ?.SubCategory.find((s) => s.name === subcategory) || null
              }
              onChange={(event, value) => {
                setSubCategory(value?.name || "");
                setSyncFilters(["subcategory"], value?.name || "");

                setSyncFilters(["manufacturer", "brand"], null);
                setManufacturer("");
                setBrand("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Category"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              disabled={!category}
            />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              options={
                transformedFilterData
                  ?.find((c) => c.name === category)
                  ?.SubCategory.find((s) => s.name === subcategory)
                  ?.Manufacturer || []
              }
              getOptionLabel={(option) => option.name}
              value={
                transformedFilterData
                  ?.find((c) => c.name === category)
                  ?.SubCategory.find((s) => s.name === subcategory)
                  ?.Manufacturer.find((m) => m.name === manufacturer) || null
              }
              onChange={(event, value) => {
                setManufacturer(value?.name || "");
                setSyncFilters(["manufacturer"], value?.name || "");

                setSyncFilters(["brand"], null);
                setBrand("");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Manufacturer"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              disabled={!subcategory}
            />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              options={
                transformedFilterData
                  ?.find((c) => c.name === category)
                  ?.SubCategory.find((s) => s.name === subcategory)
                  ?.Manufacturer.find((m) => m.name === manufacturer)?.Brand ||
                []
              }
              getOptionLabel={(option) => option.name}
              value={
                transformedFilterData
                  ?.find((c) => c.name === category)
                  ?.SubCategory.find((s) => s.name === subcategory)
                  ?.Manufacturer.find((m) => m.name === manufacturer)
                  ?.Brand.find((b) => b.name === brand) || null
              }
              onChange={(event, value) => {
                setBrand(value?.name || "");
                setSyncFilters(["brand"], value?.name || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brand"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              disabled={!manufacturer}
            />
          </FormControl>
        </Grid>
      </Popover>
    </div>
  );
};

export default CategoryFilterModal;
