import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BoundaryPage } from "./components/BoundaryPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    {/*
      If you are debugging and are having unusual first-load issues,
      you may want to try disabling
      strict mode temporarily. 
    */}
    <React.StrictMode>
      <ErrorBoundary
        fallbackRender={(fallback: FallbackProps) => (
          <BoundaryPage fallback={fallback} />
        )}
      >
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
