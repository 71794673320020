import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

const DashboardLoader = () => {
  return (
    <Box
      sx={{ marginX: 4 }}
      display="flex"
      flexDirection="column"
      alignContent="stretch"
    >
      <Typography variant="h5" mb={4}>
        <Skeleton width={200} />
      </Typography>
      <Grid container spacing={4} >
        <Grid item container xs={12} sm={12} md={12} lg={6} spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Skeleton variant="rectangular" height={"100%"} sx={{ minHeight: "300px" }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      </Grid>
    </Box >
  );
};

export default DashboardLoader;
