import { CaptureAnnotation } from "../../models/planograms/AnnotationTypes";
import axiosInstance from "../axiosInstanceConfig";

const getPlanogramCaptureAnnotations = async (
  captureId: string,
  teamId: string
) => {

  const params = new URLSearchParams();
  params.append("teamId", teamId);
  params.append("captureId", captureId);

  const response = await axiosInstance.get<CaptureAnnotation>(
    `capture/GetCaptureAnnotations?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the capture annotations. Please contact support."
    );
  }
};

export default getPlanogramCaptureAnnotations;
