import axios from "axios";
import { CognitoProvider } from "../features/authentication/contexts/CognitoProvider";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_UR_STORE_API,
});

// On API call, an accessToken is automatically attached to the request.
axiosInstance.interceptors.request.use(async (config) => {
  const token = await CognitoProvider.getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
