import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  AddCircleOutline,
  AttachFile,
  Delete,
  Update,
  CheckCircleOutline,
  Block,
} from "@mui/icons-material";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { ColDef } from "ag-grid-community";
import { useTeamStore } from "../../../../stores/teams/TeamStore";
import DragAndDropUploader from "../../../../components/DragAndDropUploader/DragAndDropUploader";
import postAdminBulkAssignPlanogramToStore, {
  BulkAssignPlanogramResponse,
} from "../../../../api/planograms/postAdminBulkAssignPlanogramToStore";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";

interface AdminBulkPlanogramAssignDialogProps {
  open: boolean;
  onClose: () => void;
}

const AdminBulkPlanogramAssignDialog = (
  props: AdminBulkPlanogramAssignDialogProps
) => {
  const { selectedTeam } = useTeamStore();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkUploadResults, setBulkUploadResults] =
    useState<BulkAssignPlanogramResponse[]>();
  const [uploadError, setUploadError] = useState<string>();

  const [colDefs] = useState<ColDef<BulkAssignPlanogramResponse>[]>([
    { field: "StoreCode", headerName: "Store Code" },
    { field: "PlanogramName", headerName: "Name" },
    { field: "PlanogramDisplayName", headerName: "Display Name" },
    {
      field: "DisabledPlanogramNames",
      headerName: "Old Planogram",
      cellRenderer: (data: any) =>
        data?.data?.OldPlanogramNames
          ? data.data.OldPlanogramNames.join(", ")
          : "",
    },
    {
      field: "ResultType",
      headerName: "Result",
      cellRenderer: (data: any) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {data?.data?.ResultType === "New" && (
            <CheckCircleOutline fontSize="small" color="success" />
          )}
          {data?.data?.ResultType === "Enabled" && (
            <Update fontSize="small" color="success" />
          )}
          {data?.data?.ResultType === "Skipped" && (
            <Block fontSize="small" color="disabled" />
          )}
          <Box sx={{ ml: 1 }}>{data?.data?.ResultType}</Box>
        </Box>
      ),
    },
  ]);

  useEffect(() => {
    setBulkUploadResults(undefined);
    setUploadError(undefined);
  }, [props.open]);

  const onSubmit = async () => {
    if (!uploadFile) return;

    const requestData = new FormData();
    requestData.append("file", uploadFile);

    try {
      setLoading(true);
      setUploadError(undefined);
      const data = await postAdminBulkAssignPlanogramToStore(
        selectedTeam,
        requestData
      );
      if (data) {
        setBulkUploadResults(data);
        enqueueSnackbar("Success", {
          variant: "success",
          cta: "Succeeded to bulk assign.",
        });
        setLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      let errorMessage = err.message;
      // Get detailed error message if the response is Bad Request
      if (err.response.status === 400) {
        const axiosErr = err as AxiosError<{ title: string; detail: string }>;
        errorMessage = axiosErr.response?.data?.detail ?? err.message;
      }
      setBulkUploadResults(undefined);
      setUploadError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={bulkUploadResults ? "md" : "sm"}
    >
      <DialogTitle>Bulk Planogram Assignment</DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginY: 2,
        }}
      >
        {!uploadFile && !bulkUploadResults && (
          <DragAndDropUploader
            onFileDrop={(file: File | File[]) => setUploadFile(file as File)}
            acceptedFileTypes={[".csv"]}
          />
        )}
        {uploadFile && !bulkUploadResults && (
          <Box mt={2} mb={3}>
            <Typography variant="body1" display="flex" alignItems="center">
              <AttachFile fontSize="small" />
              {uploadFile.name}
              <IconButton
                onClick={() => setUploadFile(null)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </Typography>
          </Box>
        )}
        {bulkUploadResults && (
          <Box sx={{ width: "100%" }}>
            <AgGridWrapper
              id="bulk-upload-result"
              rowData={bulkUploadResults}
              columnDefs={colDefs}
              height="500px"
            />
          </Box>
        )}
        {uploadError && <Alert severity="error">{uploadError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
            setUploadFile(null);
          }}
        >
          {bulkUploadResults ? "Close" : "Cancel"}
        </Button>
        {!bulkUploadResults && (
          <LoadingButton
            loading={loading}
            variant="outlined"
            disabled={uploadFile === null}
            onClick={() => onSubmit()}
            startIcon={<AddCircleOutline />}
          >
            Start Upload
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdminBulkPlanogramAssignDialog;
