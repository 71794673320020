import { Button, FormControl, Grid, Popover } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDateFilterStore } from "../../../../stores/metrics/DateRangeFilterStore";
import { useMasterFilterStore } from "../../../../stores/metrics/MasterFilterStore";
import dayjs from "dayjs";
import { FilterInputStyle } from "../../helpers/DashboardHelpers";

interface DateFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const DateFilterModal = (props: DateFilterModalProps) => {
  const { startDate, setStartDate, endDate, setEndDate, resetDates } =
    useDateFilterStore();

  const { setSyncFilters } =
    useMasterFilterStore();

  const open = Boolean(props.anchorEl);
  const id = open ? "date-filter" : undefined;

  return (
    <Popover
      sx={{ mt: 2 }}
      id={id}
      open={open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Grid gap={4} container sx={{ p: 4, width: "320px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <DesktopDatePicker
                disableFuture
                defaultValue={null}
                label="Start Date"
                format="DD/MM/YYYY"
                value={startDate ? dayjs(startDate) : null}
                onChange={(value) => {
                  if (value) {
                    setStartDate(value.toDate());
                    setSyncFilters(
                      ["startDate"],
                      dayjs(value).format("DD-MM-YYYY")
                    );
                  } else {
                    // This handles clearing.
                    setStartDate(null);
                    setSyncFilters(["startDate"], null);
                  }
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    inputProps: {
                      style: FilterInputStyle,
                    },
                  },
                  field: {
                    clearable: true,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <DesktopDatePicker
                defaultValue={null}
                minDate={startDate ? dayjs(startDate) : null}
                label="End Date"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                    helperText:
                      startDate !== null &&
                      endDate !== null &&
                      startDate > endDate &&
                      "End date must be after the start date.",
                    inputProps: {
                      style: FilterInputStyle,
                    },
                  },
                  field: {
                    clearable: true,
                  },
                }}
                value={endDate ? dayjs(endDate) : null}
                onChange={(value) => {
                  if (value) {
                    setEndDate(value.toDate());
                    setSyncFilters(
                      ["endDate"],
                      dayjs(value).format("DD-MM-YYYY")
                    );
                  } else {
                    // This handles clearing.
                    setEndDate(null);
                    setSyncFilters(["endDate"], null);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </LocalizationProvider>
        <Grid item container xs={12} md={12} lg={12}>
          <Grid item xs={6} md={6} lg={6}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                resetDates();
                setSyncFilters(["startDate", "endDate"], null);
              }}
            >
              Reset Dates
            </Button>
          </Grid>
          
        </Grid>
      </Grid>
    </Popover>
  );
};

export default DateFilterModal;
