import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { urStorePalette } from "../themes/urStoreTheme";
import { useState } from "react";
import { FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

interface BoundaryErrorProps {
  fallback: FallbackProps;
}

// This component is shown when an unrecoverable error is thrown in the portal.
export const BoundaryPage = (props: BoundaryErrorProps) => {
  // Util
  const navigate = useNavigate();

  // State
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  // Render
  return (
    <>
      {/* Snackbar */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert color="success">Copied to Clipboard.</Alert>
      </Snackbar>

      {/* Alert Card + Apology Message :( */}
      <Stack
        sx={{ minHeight: "100vh", backgroundColor: urStorePalette.greys.dark }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Card sx={{ width: 500 }} variant="outlined">
          <CardContent>
            <Typography variant="overline">URStore Portal</Typography>
            <Typography sx={{ fontSize: 22, fontWeight: 500 }}>
              Sorry, something went wrong.
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: urStorePalette.greys.dark }}
            >
              Try refreshing this page or returning to the homepage. Please contact
              support with the provided error if issue persists.
            </Typography>
            <TextField
              sx={{ mt: 2, backgroundcolor: urStorePalette.greys.lightest }}
              fullWidth
              onClick={() => {
                navigator.clipboard.writeText(props.fallback.error.stack);
                setSnackOpen(true);
              }}
              onFocus={(e) => e.target.select()}
              variant="outlined"
              label={
                <>
                  Error <b>(Click to Copy)</b>
                </>
              }
              value={props.fallback.error.stack}
              multiline={true}
              rows={5}
              InputProps={{
                style: {
                  fontSize: 12,
                  fontFamily: "monospace",
                  backgroundColor: urStorePalette.greys.lightest,
                  textDecoration: "none",
                },
              }}
            />

            <Button
              sx={{
                mt: 2,
                textDecoration: "none",
                textTransform: "capitalize",
              }}
              fullWidth
              variant="outlined"
              onClick={() => {
                navigate("/");
                window.location.reload();
              }}
            >
              Return to Homepage
            </Button>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
