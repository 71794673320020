import axiosInstance from "../axiosInstanceConfig";

const postToggleUserActivation = async (data:ToggleUserActivationRequest) => {
  const response = await axiosInstance.post<string>(
    `user/UpdateUserActivation`, data
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error("There was an error creating the team. Please contact support.");
  }
};

export type ToggleUserActivationRequest = {
  TeamId: string
  UserId: string
  Active: boolean
}

export default postToggleUserActivation;
