import { create } from "zustand";

type LocationFilterState = {
  country: string | null;
  state: string | null;
  region: string | null;
  store: {
    value: string;
    label: string;
  };
};

type LocationFilterStateActions = {
  setLocationCountry: (_arg0: string) => void;
  setLocationState: (_arg0: string) => void;
  setLocationStore: (_arg0: { value: string; label: string }) => void;
  resetLocationFilter: () => void;
};

const initialLocationFilterState: LocationFilterState = {
  country: null,
  state: null,
  region: null,
  store: {
    value: "",
    label: "",
  },
};

export const useLocationFilterStore = create<
  LocationFilterState & LocationFilterStateActions
>((set) => ({
  ...initialLocationFilterState,
  setLocationCountry: (newCountry: string) =>
    set(() => ({ country: newCountry })),
  setLocationState: (newState: string) => set(() => ({ state: newState })),
  setLocationStore: (newStore: { value: string; label: string }) =>
    set(() => ({ store: newStore })),
  resetLocationFilter: () => set(() => ({ ...initialLocationFilterState })),
}));
