import axiosInstance from "../axiosInstanceConfig";

export interface BulkAssignPlanogramResponse {
  StoreCode: string;
  PlanogramName: string;
  PlanogramDisplayName: string | null;
  ResultType: string;
  DisabledPlanogramNames: string[] | null;
}

const postAdminBulkAssignPlanogramToStore = async (
  teamId: string,
  data: FormData
) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.post(
    `planogram/AdminBulkAssignPlanogramToStore?${params.toString()}`,
    data
  );

  if (response.status === 200) {
    return response.data as BulkAssignPlanogramResponse[];
  } else if (response.status === 400) {
    // Return error message if the result is Bad Request error
    throw new Error(response.data.detail ?? response.data.title);
  } else {
    throw new Error(
      "There was an error bulk uploading to assign the planogram to the store. Please contact support."
    );
  }
};

export default postAdminBulkAssignPlanogramToStore;
