import { Box, Button, Snackbar } from "@mui/material";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useRef, useState } from "react";
import getAllProducts, { ListProduct } from "../../../api/products/getProducts";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { ProductImg } from "../components/ProductImg";
import { OpenInBrowserOutlined } from "@mui/icons-material";
import { ProductModal } from "../components/ProductModal";
import { ColDef } from "ag-grid-community";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

interface ProductListPageProps {
  selectedTeam: string;
}

interface ListProductColumn extends ListProduct {
  Image?: null;
  Actions?: null;
}

export const ProductListPage = (props: ProductListPageProps) => {
  // Util
  const { selectedTeam, roleOnTeam } = useTeamStore();

  // State
  const [colDef] = useState<ColDef<ListProductColumn>[]>([
    {
      field: "Image",
      autoHeight: true,
      width: 120,
      resizable: false,
      cellRenderer: (row: any) => {
        const rs: ListProduct = row.data;

        return (
          <ProductImg
            productId={rs.ProductId}
            revisionId={rs.RevisionId}
            defaultSize={36}
            growSize={108}
          />
        );
      },
    },
    { field: "Name", flex: 2 },
    { field: "SKUCode", flex: 1 },
    { field: "UPC", flex: 1 },
    {
      field: "Actions",
      width: 120,
      cellRenderer: (row: any) => {
        return (
          <Button
            disabled={roleOnTeam === "reporting"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<OpenInBrowserOutlined />}
            onClick={() => {
              setModalListProduct(row.data);
              setModalOpen(true);
            }}
          >
            Open
          </Button>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ListProduct[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalListProduct, setModalListProduct] = useState<ListProduct | null>(
    null
  );

  // Effects
  useEffect(() => {
    async function fetchProducts() {
      setLoading(true);

      const data = await getAllProducts(selectedTeam);

      setLoading(false);

      if (data !== false) {
        setProducts(data);
      } else {
        setProducts([]);
      }
    }

    !modalOpen && fetchProducts();
  }, [selectedTeam, modalOpen]);

  // Render
  return (
    <>
      {/* Modal */}
      <ProductModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        listProduct={modalListProduct}
      />

      {/* Page */}
      <Box sx={ResponsiveContainerStyle}>
        <AgGridWrapper
          id="products"
          columnDefs={colDef}
          rowData={products}
          loading={loading}
          rememberPageNumber
          quickSearch
          export
        />
      </Box>
    </>
  );
};
